/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppstoreAddOutlined as AppstoreAddOutlinedIcon } from '@ant-design/icons';
import { Button, Collapse, Modal, Result, Spin } from 'antd';
import React from 'react';
import {
	getCompaniesOfOrgByOrganizationId,
	getLocationsOfOrgByOrganizationId,
} from '../../../../../../data/api/orgRegistryServerRequests';
import AddLocationForm from '../../forms/AddLocationForm/AddLocationForm';
import LocationTable from './LocationTable';

const { Panel } = Collapse;

export default class ManageLocations extends React.Component {
	constructor(props) {
		super(props);
		const currentOrganizationId = sessionStorage.getItem('orgID');

		this.state = {
			isCompanyAndLocationDataFetching: false,
			isCompanyAndLocationDataFetchingError: '',
			isAddLocationModalVisible: false,
			addLocationModalFormMode: 'ADD', // Available Options --> ADD / EDIT. (Define <AddLocationForm/> is opened in Add mode or Update mode.)

			currentOrgLocationsByCompany: [],
			clickedItemDetails: {}, // This is get updated when some specific company/location action is clicked. For ex. when clicked on 'Add New Location' in specific company that company/location details will be set here. So we can pass it into model and so on.
			currentOrgFullLocationCodeList: [],

			currentOrganizationId: currentOrganizationId,
		};

		this.toggleAddNewLocationModal = this.toggleAddNewLocationModal.bind(this);
		this.setClickedItemDetails = this.setClickedItemDetails.bind(this);
	}

	// Helper utility to parse and mix location details with it's appropriate company.
	parseCurrentOrgLocationsByCompany(currentOrgCompanies, currentOrgLocations) {
		const currentOrgLocationsByCompany = currentOrgCompanies.reduce(
			(acc, currentCom) => {
				const { remainingLocationList } = acc;

				// Getting all locations that belong to this iteration's company.
				const currentComLocations = remainingLocationList.filter((loc) => {
					return loc.CompanyID === currentCom.CompanyID;
				});

				// Removing already extracted above locations so next iterations will have less items to go through.
				const remainingLocations = remainingLocationList.filter((loc) => {
					return loc.CompanyID !== currentCom.CompanyID;
				});

				// Setting up current iteration's extracted/formatted data into certain pattern.
				const result = {
					...currentCom,
					locationList: currentComLocations,
				};

				acc.remainingLocationList = remainingLocations;
				acc.finalResult.push(result);

				return acc;
			},
			{ remainingLocationList: [...currentOrgLocations], finalResult: [] },
		).finalResult;

		return currentOrgLocationsByCompany;
	}

	async getCompanyAndLocationDetails() {
		try {
			const { currentOrganizationId } = this.state;

			this.setState({
				isCompanyAndLocationDataFetching: true,
				isCompanyAndLocationDataFetchingError: '',
			});

			// Getting Companies of Currently Selected Organization on dashboard-top.
			const currentOrgCompanyList = await getCompaniesOfOrgByOrganizationId({
				OrganizationID: currentOrganizationId,
			});

			// Getting Locations of Currently Selected Organization on dashboard-top.
			const currentOrgLocationList = await getLocationsOfOrgByOrganizationId({
				OrganizationID: currentOrganizationId,
			});

			// Parsing fetched Locations "By Company".
			const currentOrgLocationsByCompany = this.parseCurrentOrgLocationsByCompany(
				currentOrgCompanyList,
				currentOrgLocationList,
			);

			// Extracting All "LocationCode" used in current organization. This is used when <AddNewLocation> LocationCode generation functionality..
			const currentOrgFullLocationCodeList = currentOrgLocationList.map(
				(loc) => loc.LocationCode,
			);

			this.setState({
				currentOrgLocationsByCompany: currentOrgLocationsByCompany,
				currentOrgFullLocationCodeList: [
					...new Set(currentOrgFullLocationCodeList),
				],
				isCompanyAndLocationDataFetching: false,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			this.setState({
				isCompanyAndLocationDataFetching: false,
				isCompanyAndLocationDataFetchingError: errMsg,
			});
		}
	}

	toggleAddNewLocationModal = (data = {}, cb = () => {}) => {
		const { formMode = 'ADD' } = data;

		const { isAddLocationModalVisible } = this.state;
		this.setState(
			{
				isAddLocationModalVisible: !isAddLocationModalVisible,
				addLocationModalFormMode: formMode,
			},
			cb,
		);
	};

	setClickedItemDetails(data = {}, cb = () => {}) {
		const { selectedCompanyDetails = {}, selectedLocationDetails = {} } = data;

		this.setState(
			{
				// To match with <AddLocationForm/> component's expected pattern.
				clickedItemDetails: {
					CompanyDetails: selectedCompanyDetails,
					LocationDetails: selectedLocationDetails,
				},
			},
			cb,
		);
	}

	async componentDidMount() {
		this.getCompanyAndLocationDetails();
	}

	render() {
		const {
			isAddLocationModalVisible,
			addLocationModalFormMode,
			isCompanyAndLocationDataFetching,
			isCompanyAndLocationDataFetchingError,

			currentOrgLocationsByCompany,
			currentOrgFullLocationCodeList,
			clickedItemDetails,
		} = this.state;

		const isAnyMainActionsRunning = isCompanyAndLocationDataFetching;
		const isAnyMainActionsError = isCompanyAndLocationDataFetchingError;

		if (isAnyMainActionsError) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.getCompanyAndLocationDetails()}>
								Try Again
							</Button>,
						]}
					/>
				</div>
			);
		}

		return (
			<div className='ManageLocations GC-UTL-fullFlexHeight'>
				<Modal
					title={`${
						addLocationModalFormMode === 'EDIT' ? 'Update' : 'Add New'
					} Location`}
					width={800}
					footer={null}
					visible={isAddLocationModalVisible}
					onCancel={() => {
						this.setClickedItemDetails({}, () => {
							this.toggleAddNewLocationModal({
								formMode: 'ADD',
							});
						});
					}}>
					<AddLocationForm
						shouldResetForm={!isAddLocationModalVisible} // Used to clear form values when modal closing.
						shouldPrefillForm={
							addLocationModalFormMode === 'EDIT' ? true : false // Used to pre-fill Form with existing values, if applicable.
						}
						formMode={addLocationModalFormMode}
						companyData={clickedItemDetails}
						existingLocationCodesInOrg={currentOrgFullLocationCodeList}
						onDone={async () => {
							this.setClickedItemDetails({}, () => {
								this.toggleAddNewLocationModal();
							});

							// After adding a location we are re-fetching company details to show updated details on UI.
							await this.getCompanyAndLocationDetails();
						}}
					/>
				</Modal>

				{isAnyMainActionsRunning && (
					<div className='GC-UTL-flexSuperCenter'>
						<Spin />
					</div>
				)}

				{!isAnyMainActionsRunning && (
					<Collapse defaultActiveKey={['0']}>
						{currentOrgLocationsByCompany.map((com, index) => {
							return (
								<Panel header={com.Name} key={index}>
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<Button
											type='primary'
											icon={
												<AppstoreAddOutlinedIcon style={{ fontSize: '20px' }} />
											}
											onClick={() => {
												sessionStorage.setItem('CompanyID', com.CompanyID); // Because  <AddNewLocationForm/> currently get CompanyID from sessionStorage.
												this.setClickedItemDetails(
													{
														selectedCompanyDetails: com,
													},
													() => {
														this.toggleAddNewLocationModal({
															formMode: 'ADD',
														});
													},
												);
											}}>
											Add New Location
										</Button>
									</div>

									<br />

									<LocationTable
										companyDetails={com}
										locationList={com.locationList}
										onLocationEditClicked={(data = {}) => {
											const { selectedLocationDetails } = data;

											this.setClickedItemDetails(
												{
													selectedCompanyDetails: com,
													selectedLocationDetails: selectedLocationDetails,
												},
												() => {
													this.toggleAddNewLocationModal({
														formMode: 'EDIT',
													});
												},
											);
										}}
									/>
								</Panel>
							);
						})}
					</Collapse>
				)}
			</div>
		);
	}
}
