import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Link, Button } from "@material-ui/core";
import { Row, Col, Form, Progress, BP } from "bootstrap-4-react";
import { Field, reduxForm } from "redux-form";
import { Alert } from "@material-ui/lab";
import { renderTextBox, renderCheckBox, RenderPassword, renderHidden, encrypt } from "./../../lib/global/helpers";
import { orgRequest } from "../../data/api/OrgApi"
import { change } from 'redux-form'
import addNotification from "react-push-notification";
import { renderEmail } from 'postonents';
import VerificationEmail from '../mod/emails/templates/VerificationEmail';
import _ from "lodash";
import jwt from 'jsonwebtoken';
import { Modal } from 'antd';
import { decrypt } from "../../lib/global/helpers.js";
import { PageHeader } from 'antd';

const formName = "RegisterForm";
let appID = "";
const styles = (theme) => ({
    form: {
        [theme.breakpoints.only("xs")]: {
            padding: "20px 40px !important"
        }
    }
});
class Register extends Component {

    state = {
        strengthValue: 0,
        strengthClass: "",
        finishButton: false,
        errorMessage: "",
        errorEmail: "",
        submitState: "Register Now",
        submitDisable: false,
        emailSent: false,
        emailAddress: "",
        appid: "",
        verificationCodeAttempts: 0
    };

    constructor(props) {
        super(props);
        appID = this.props.match.params.appid;


    }

    componentDidMount() {

        // When ':appid' value is not passed through URL Route Params. We redirect to login page with "?registrationSelect=true" so user can select a app to start registration.
        if (!appID) {
            this.props.history.push('/login/auth?registrationSelect=true');
            Modal.error({
                title: 'Please select an application',
                content: 'Please select an application to register',
                onOk: () => {},
            });
        }

        this.props.dispatch(change(formName, "AppID", appID));

        this.setState({ appid: appID });


    }

    onFinish = values => {
        let applicationID = this.state.appid;

        if (values.VerificationCode == JSON.parse(decrypt(decodeURIComponent(sessionStorage.getItem('verificationtoken')))).DigitCode) {
            sessionStorage.setItem('regeligible', true);
            Modal.success({
                title: 'Verified successfully',
                content: 'One more step to complete your profile',
                onOk() { window.location.href = "/login/verify/" + sessionStorage.getItem('verificationtoken') },
            });
        }
        else {
            let verificationCodeAttempts = this.state.verificationCodeAttempts;
			verificationCodeAttempts = verificationCodeAttempts + 1; // Increasing Attempt.

			if (verificationCodeAttempts > 2) {
				Modal.error({
					title:
						'The email verification code is invalid. You have failed all attempts',
					content:
						'Did you recently request an account without verifying your email address? If your email address has not been verified, you may need to create a new account. If you are having trouble, see Account Help.',
					onOk: () => {
						window.location.href =
							'/login/register/' + applicationID;
					},
				});
			} else {
				Modal.error({
					title: `The email verification code is invalid. (Attempt ${verificationCodeAttempts})`,
					content:
						'Please, carefully re-check your email for verification code and try again.',
					onOk: () => {},
				});
			}

			this.setState({
				verificationCodeAttempts: verificationCodeAttempts,
			});
        }

    };


    handleSelectApp = (type) => {
            if (type === 'login') {
                this.props.history.push('/login/auth/?genericLogin=true');
                sessionStorage.setItem('appName', '');
            } else if (type == 'register') {
                Modal.error({
                    title: 'Please select an application',
                    content: 'Please select an application to ' + type,
                    onOk: () => {
                        this.props.history.push(
                            '/login/auth?registrationSelect=true'
                        );
                        sessionStorage.setItem('appName', '');
                    },
                });
            }
    }

    onSubmit = (values) => {
        this.setState({ submitDisable: true });
        this.setState({ emailAddress: values.Email });
        var self = this;
        this.setState({ errorEmail: "" })
        this.setState({ submitState: "Submitting..." });
        let applicationID = this.state.appid;

        orgRequest("finduser", { email: values.Email })
            .then((response) => {
                if (response.data.Status == true) {
                    let error = "This email is already assiciated with an account! please login";
                    self.setState({ errorEmail: error })
                    addNotification({
                        title: "Error",
                        message: error,
                        native: true,
                    });
                    let properties = this.props;
                    setTimeout(function () {
                        properties.history.push("/login/auth/" + applicationID + "?email=" + values.Email);
                    }, 2000);

                }
                else {
                    let date = new Date();
                    values.ExpireDate = date.setHours(date.getHours() + 24, date.getMinutes(), 0, 0);
                    let DigitCode = _.random(100000, 999999)
                    values.DigitCode = DigitCode;
                    let token = encrypt(JSON.stringify(values));
                    sessionStorage.setItem('verificationtoken', token);
                //   console.log(DigitCode)
                    const data = {
                        verificationToken: token,
                        DigitCode: DigitCode,
                        email: values.Email
                    }
                    const emailBody = renderEmail(VerificationEmail, { lang: 'en', data });

                    this.setState({ submitState: "Sending Email..." });
                    //ecoEmail.SendEmail()

                    async function postData() {

                        const timestamp = Math.floor(Date.now() / 1000) + 1000;
                        var payload = {
                            "aud": process.env.REACT_APP_Mail_Audience,
                            "exp": timestamp,
                            "iss": process.env.REACT_APP_Mail_Issuer
                        };

                        var signOptions = {
                            "algorithm": "RS256"
                        };

                        var privateKey = process.env.REACT_APP_Mail_PrivateKey.replace(/\\n/g, '\n');
                        var token = jwt.sign(payload, privateKey, signOptions);
                      //  console.log(token)

                        const ecoEmail = {
                            "Channel": "Email",
                            "ServiceOrigin": "Org Registration",
                            "EmailType": "Standard",
                            "TemplateName": "",
                            "ToName": "",
                            "To": values.Email,
                            "FromName": process.env.REACT_APP_Mail_SenderName,
                            "From": process.env.REACT_APP_Mail_SenderMail,
                            "Subject": "Please verify your email address",
                            "PlainTextContent": "Hello, We're happy you signed up for WITMEG. You just registered with the following email: "+values.Email+". Your WITMEG verification code is "+DigitCode,
                            "HTMLContent": emailBody,
                            "MailResponseCode": "0",
                            "MessageReceivedAt": new Date()
                        };

                        const response = await fetch(process.env.REACT_APP_Mail_API + 'v1/email/add', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ` + token,
                            },
                            body: JSON.stringify(ecoEmail)
                        })

                        return response.json();
                    }

                    postData()
                        .then(data => {

                            //  console.log(data)
                            if (data.Status) {

                                addNotification({
                                    title: "Success",
                                    message: "Please check your inbox",
                                    native: true,
                                });
                                this.setState({ submitState: "Email Sent" });


                                Modal.success({
                                    title: 'Please verify your account',
                                    content: 'Verification email has been sent to ' + values.Email,
                                    onOk() { },
                                });

                                this.setState({ emailSent: true });

                            }
                            else {
                                this.setState({ submitState: "Register Now" });
                                this.setState({ submitDisable: false });
                            }
                        });
                }
            })
            .catch((error) => { console.log(error) });
    };

    emailChange = (event) => {
        this.setState({ errorEmail: "" })
    }

    onPasswordChange = (event) => {
        let password = event.target.value;
        let strength = 0;
        let strengthValue = 0;
        let strengthClassList = ["danger", "danger", "warning", "light", "info", "success"];
        let strengthClass = strengthClassList[0];

        if (password.match(/[a-z]+/)) strength += 1;
        if (password.match(/[A-Z]+/)) strength += 1;
        if (password.match(/[0-9]+/)) strength += 1;
        if (password.match(/[$@#&!]+/)) strength += 1;
        if (password.length >= 8) strength += 1;
        strengthValue = strength * 20;
        strengthClass = strengthClassList[strength];

        this.setState({ strengthValue: strengthValue, strengthClass: strengthClass });

        if (strengthValue == 100) this.setState({ finishButton: true });
        else this.setState({ finishButton: false });

    }


    render() {



        const { classes, handleSubmit, pristine, submitting, invalid } = this.props;
        return (
            <Grid>
                <div className="site-page-header-ghost-wrapper">
    <PageHeader
      ghost={false}
      avatar={{ src: '/inc/img/witmeg.png' }}
      title=""
      href=""
      subTitle="All-In-One Dashboard"
      extra={[
        <Button key="3" onClick={this.handleSelectApp.bind(null, 'login')}>Login</Button>,
        <Button key="2" onClick={this.handleSelectApp.bind(null, 'register')}>Register</Button>,

      ]}
    >

    </PageHeader>
  </div>
                <Grid className="all-wrapper menu-side with-pattern">
                    <Grid className="auth-box-w wider">

                        {!this.state.emailSent &&
                            <Grid>
                                <Grid className="logo-w">
                                    <Link href="/">
                                        <img alt="" src="inc/img/logo-big.png" />
                                    </Link>
                                </Grid>
                                <h4 className="auth-header">Register</h4>
                                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className={classes.form}>
                                    <Field
                                        name="AppID"
                                        type="hidden"
                                        required="required"
                                        id="txtAppID"
                                        component={renderHidden}
                                    />
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="FirstName"
                                                type="text"
                                                required="required"
                                                id="txtFirstName"
                                                label="First Name"
                                                placeholder="Enter First Name"
                                                component={renderTextBox}
                                                value=""
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="LastName"
                                                type="text"
                                                required="required"
                                                id="txtLastName"
                                                label="Last Name"
                                                placeholder="Enter Last Name"
                                                component={renderTextBox}
                                                value=""
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="Email"
                                                type="email"
                                                required="required"
                                                id="txtEmail"
                                                label="Email Address"
                                                placeholder="Enter Email Address"
                                                component={renderTextBox}
                                                value={this.state.Email}
                                                onChange={this.emailChange}
                                            />
                                            {this.state.errorEmail &&
                                                (
                                                    <BP text="danger"><small>{this.state.errorEmail}</small></BP >
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="Password"
                                                type="password"
                                                required="required"
                                                id="txtPassword"
                                                label="Password"
                                                placeholder="Enter password"
                                                smalltext="To conform with our strict security policy, you are required to use a sufficiently strong password
                                                that has a minimum 7 characters, that includes an uppercase letter, lowercase letter, a number and a special character"
                                                onChange={this.onPasswordChange}
                                                component={RenderPassword}
                                                autocomplete="current-password"
                                            />
                                        </Col>
                                        <Col col="sm-12">
                                            <React.Fragment>
                                                <Progress mb="2">
                                                    <Progress.Bar min="0" max="100" now={this.state.strengthValue} bg={this.state.strengthClass} />
                                                </Progress>
                                            </React.Fragment>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="confirm"
                                                id="chkConfirm"
                                                type="checkbox"
                                                required="required"
                                                label="I have read and understood the Terms & Conditions and Privacy Policy."
                                                component={renderCheckBox}
                                            />
                                        </Col>
                                        <Col col="sm-12" className="text-center">
                                            <small>
                                                <Link href="tearms">Terms &amp; Conditions</Link>{" | "}
                                                <Link href="privacy">Privacy Policy</Link>
                                            </small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12" mt={2}>
                                            <Button disabled={submitting || !this.state.finishButton || pristine || invalid || this.state.submitDisable} variant="contained" color="primary" type="submit" className="w-100">
                                                {this.state.submitState}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row mt={2}>
                                        <Col col="sm-6">
                                            <Link href={"/login/auth/" + this.state.appid} color="primary"><small>{"I already have an account"}</small></Link>
                                        </Col>
                                    </Row>


                                    {this.state.errorMessage &&
                                        (
                                            <Row mt={2}>
                                                <Col col="sm-12">
                                                    <Alert severity="error">{this.state.errorMessage}</Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Form>
                            </Grid>
                        }

                        {this.state.emailSent &&
                            <Grid>
                                <Grid>
                                    <Grid className="logo-w">
                                        <Link href="/">
                                            <img alt="" src="inc/img/logo-big.png" />
                                        </Link>
                                    </Grid>
                                    <h4 className="auth-header">Verification</h4>
                                    <Form onSubmit={handleSubmit(this.onFinish.bind(this))} className={classes.form}>

                                        <Row>
                                            <Col col="sm-12">
                                                <Field
                                                    name="VerificationCode"
                                                    type="text"
                                                    required="required"
                                                    id="txtVerificationCode"
                                                    label="Verification Code"
                                                    placeholder="Enter Verification Code"
                                                    component={renderTextBox}
                                                    value=""
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col col="sm-12" mt={2}>
                                                <Button variant="contained" color="primary" type="submit" className="w-100">
                                                    Verify Now
                                            </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12" mt={2}>
                                            We have sent an email with verification code. To proceed registration please type the verification code in above box.
                                        </Col>
                                        </Row>

                                    </Form>
                                </Grid>


                            </Grid>
                        }
                    </Grid>
                </Grid>

            </Grid >
        );
    }
}


const validate = values => {
    const warnings = {};
    if (!values.firstName) { warnings.firstName = "First Name is Required"; }
    if (!values.lastName) { warnings.lastName = "Last Name is Required"; }
    if (!values.email) { warnings.email = "Email is Required"; }
    if (!values.password) { warnings.password = "Password is Required"; }
    if (!values.confirm) { warnings.confirm = "Please agree to continue"; }
    return warnings;
};

export default reduxForm({
    enableReinitialize: true,
    form: formName,
    validate,
    /*initialValues: { firstName: 'Lankitha', lastName: 'Gallage', email: 'lankitha@ecologital.com', password: 'Lankitha@123', confirm: true }*/
})(withStyles(styles)(Register));
