import { Button, Grid, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Modal, PageHeader, message } from 'antd';
import { Col, Form, Row } from 'bootstrap-4-react';
import { renderEmail } from 'postonents';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { sendEmail } from '../../data/api/emailRegistryServerRequests';
import { generatePasswordResetLink } from '../../data/api/orgRegistryServerRequests';
import { errorLogger } from '../../lib/utilities/errorHandlers';
import PasswordResetLinkEmail from '../mod/emails/templates/PasswordResetLinkEmail';
import { renderTextBox } from './../../lib/global/helpers';

const styles = (theme) => ({
	form: {
		[theme.breakpoints.only('xs')]: {
			padding: '20px 40px !important',
		},
	},
});

const formName = 'forgotPassword';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			buttonText: 'Send Reset Link',
		};
	}

	handleSelectApp = (type) => {
		if (type === 'login') {
			this.props.history.push('/login/auth/?genericLogin=true');
		} else if (type === 'register') {
			Modal.error({
				title: 'Please select an application',
				content: 'Please select an application to ' + type,
				onOk: () => {
					this.props.history.push('/login/auth?registrationSelect=true');
					sessionStorage.setItem('appName', '');
				},
			});
		}
	};

	async sendPasswordResetLinkEmail(emailData = {}) {
		try {
			const { UserEmail, resetLink } = emailData;

			const emailGenData = {
				userEmail: UserEmail,
				resetLink: resetLink,
			};

			const emailContentAsHTML = renderEmail(PasswordResetLinkEmail, {
				data: emailGenData,
			});

			return sendEmail({
				ToName: emailGenData.userEmail,
				To: emailGenData.userEmail,
				FromName: 'WitMeg',
				From: 'admin@witmeg.com',
				Subject: 'WitMeg Account - Password Reset Link',
				HTMLContent: emailContentAsHTML,
			});
		} catch (error) {
			errorLogger(error);
			throw error; // Re-throwing error to be catch in 'onSubmit()' handler.
		}
	}

	async onSubmit(values) {
		try {
			this.setState({ buttonText: 'Please Wait...' });

			// Generating reset link for given email. If user don't exist for this email throw an error.
			const resetLink = await generatePasswordResetLink({
				UserEmail: values.UserEmail,
			});

			await this.sendPasswordResetLinkEmail({
				UserEmail: values.UserEmail,
				resetLink: resetLink,
			});

			message.info('Password Reset Link Sent.');

			this.props.history.push('/login/auth/?genericLogin=true');
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Some Error Occurred. Please Try Again.';

			this.setState({ errorMessage: errMsg });
			this.setState({ buttonText: 'Send Reset Link' });
		}
	}

	render() {
		const { classes, handleSubmit, pristine, submitting, invalid } = this.props;
		return (
			<Grid>
				<div className='site-page-header-ghost-wrapper'>
					<PageHeader
						ghost={false}
						avatar={{ src: '/inc/img/witmeg.png' }}
						title=''
						subTitle='All-In-One Dashboard'
						extra={[
							<Button
								key='3'
								onClick={this.handleSelectApp.bind(null, 'login')}>
								Login
							</Button>,

							<Button
								key='2'
								onClick={this.handleSelectApp.bind(null, 'register')}>
								Register
							</Button>,
						]}></PageHeader>
				</div>

				<Grid className={'all-wrapper menu-side with-pattern'}>
					<Grid className={'auth-box-w'}>
						<Grid>
							<Grid className='logo-w'>
								<Link href='/'>
									<img alt='' src='inc/img/logo-big.png' />
								</Link>
							</Grid>

							<h4 className='auth-header'>Forgot Password</h4>

							<Form
								onSubmit={handleSubmit(this.onSubmit.bind(this))}
								className={classes.form}>
								<p>
									Please enter your account's email address below. We will send
									you a Password Reset Link to it.
								</p>

								<Row>
									<Col col='sm-12'>
										<Field
											name='UserEmail'
											type='email'
											required='required'
											id='txtUserEmail'
											label='Email'
											placeholder='Enter UserEmail'
											component={renderTextBox}
										/>
									</Col>
								</Row>

								<Row>
									<Col col='sm-12'>
										<Button
											disabled={submitting || pristine || invalid}
											variant='contained'
											color='primary'
											type='submit'
											className='w-100'>
											{this.state.buttonText}
										</Button>
									</Col>
								</Row>

								{this.state.errorMessage && (
									<Row mt={2}>
										<Col col='sm-12'>
											<Alert severity='error'>{this.state.errorMessage}</Alert>
										</Col>
									</Row>
								)}

								<Row mt={2}>
									<Col col='sm-6'>
										<Link
											href={'/login/auth/?genericLogin=true'}
											color='primary'>
											<small>{'Go Back To Login'}</small>
										</Link>
									</Col>
								</Row>
							</Form>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const validate = (values) => {
	const warnings = {};
	if (!values.UserEmail) {
		warnings.username = 'User Email is Required.';
	}

	return warnings;
};

export default reduxForm({
	initialValues: {},
	enableReinitialize: true,
	form: formName,
	validate: validate,
})(withStyles(styles)(ForgotPassword));
