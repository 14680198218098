import {
	SelectOutlined as SelectOutlinedIcon,
	ThunderboltOutlined as ThunderboltOutlinedIcon,
	UserOutlined as UserOutlinedIcon,
} from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React, { Component } from 'react';
export default class UsersSummary extends Component {
	render() {
		const {
			currentUserAccountDetails,
			dashboardSliceActions,
			isCurrentlySelectedOrganizationAdmin,
		} = this.props;
		const {
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;

		return (
			<div className='AccountDetailsSummary'>
				<Card
					title='Welcome'
					bordered={false}
					actions={[
						<div>
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									setCurrentDashboardPageIdAction('AccountDetails');
								}}>
								Account Details
							</Button>

							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									setCurrentDashboardPageIdAction('AccountDetails');
								}}>
								Update Account Details
							</Button>
						</div>,
					]}>
					<Row>
						<Col span={24}>
							<h3>Hi {currentUserAccountDetails.FirstName},</h3>
						</Col>

						<Col span={24}>
							<div>
								{isCurrentlySelectedOrganizationAdmin ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<UserOutlinedIcon />
										<ThunderboltOutlinedIcon />
										<span>&nbsp;Admin of The Organization</span>
									</div>
								) : (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<UserOutlinedIcon />
										<span>&nbsp;Member of The Organization</span>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}
