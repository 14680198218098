import { validate } from "./.property";

class Address {
    constructor(params = null) {
        if (params == null) {
            this.AddressType = "";
            this.AddressLine1 = "";
            this.AddressLine2 = "";
            this.City = "";
            this.StateProvinceRegion = "";
            this.PostZipCode = "";
            this.Country = "";
            this.Phone = "";
            this.Fax = "";
            this.AEmail = "";
            this.CreatedDate = new Date();
            this.LastModifiedDate = new Date();
        } else this.set(params);
    }

    set(params) {
        this.AddressType = validate(params, "AddressType", "");
        this.AddressLine1 = validate(params, "AddressLine1", "");
        this.AddressLine2 = validate(params, "AddressLine2", "");
        this.City = validate(params, "City", "");
        this.StateProvinceRegion = validate(params, "UsStateProvinceRegionerID", "");
        this.PostZipCode = validate(params, "PostZipCode", "");
        this.Country = validate(params, "Country", "");
        this.Phone = validate(params, "Phone", "");
        this.Fax = validate(params, "Fax", "");
        this.Email = validate(params, "AEmail", "");
        this.CreatedDate = validate(params, "CreatedDate", new Date());
        this.LastModifiedDate = validate(params, "LastModifiedDate", new Date());
    }
}

export default Address;