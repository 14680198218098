import axios from "axios";
import { getJWT } from "../global/helpers";
const https = require('https');

export function axiosCall(method, url, config, data, token) {

    config['Content-Type'] = 'application/json';

    if (token != undefined) config["Authorization"] = 'Bearer ' + token;

    return axios({
        method: method,
        url: url,
        headers: config,
        data: data,
    });
}