import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import "./css/style.css";
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Popover,  List, Avatar } from 'antd';
import imggraph from './images/chart.png';
import imgreport from './images/download.svg';
import {
    PlusCircleOutlined,
  } from '@ant-design/icons';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
});

 
 
export default class AddRemoveLayout extends React.PureComponent {
    static defaultProps = {
        className: "layout",
        cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
        rowHeight: 100
    };

    constructor(props) {
        super(props);

        this.state = {
            items: [0].map(function (i, key, list) {
                return {
                    i: i.toString(),
                    x: i * 2,
                    y: 0,
                    w: 2,
                    h: 2,
                    add: i === (list.length - 1)
                };
            }),
            newCounter: 0
        };

        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
    }

    createElement(el) {
        const removeStyle = {
            position: "absolute",
            right: "2px",
            top: 0,
            cursor: "pointer"
        };
        const i = el.add ? "+" : el.i;
        return (
            <div key={i} data-grid={el}>
                {el.add ? (
                    <span
                        className="add text"
                    >
                        <Grid item xs={12} textAlign="center">

                            <MyButton href={'/report/invoice/' + sessionStorage.getItem('appid')}>Click here to view</MyButton>

                        </Grid>
                    </span>
                ) : (
                        <span className="text">{i}</span>
                    )}

                <button id="x" onClick={this.onRemoveItem.bind(this, i)}>
                    X
        </button>

            </div>
        );
    }

    onAddItem() {
        /*eslint no-console: 0*/
        console.log("adding", "n" + this.state.newCounter);
        this.setState({
            // Add a new item. It must have a unique key!
            items: this.state.items.concat({
                i: "n" + this.state.newCounter,
                x: (this.state.items.length * 2) % (this.state.cols || 12),
                y: Infinity, // puts it at the bottom
                w: 2,
                h: 2
            }),
            // Increment the counter to ensure key is always unique.
            newCounter: this.state.newCounter + 1
        });
    }

    // We're using the cols coming back from this to calculate where to add new items.
    onBreakpointChange(breakpoint, cols) {
        this.setState({
            breakpoint: breakpoint,
            cols: cols
        });
    }

    onLayoutChange(layout) {
        //  this.props.onLayoutChange(layout);
        // this.setState({ layout: layout });
    }

    onRemoveItem(i) {
        console.log("removing", i);
        this.setState({ items: _.reject(this.state.items, { i: i }) });
    }

    render() {
        return (


            <div>
<Popover placement="bottom" trigger="focus"  content={ <List itemLayout="horizontal"> 
      <List.Item>
        <List.Item.Meta
          onClick={this.onAddItem}
          avatar={<Avatar src={imggraph} />}
          title={<a >Chart</a>}
          description="Create chart view of the data"
        />
        
      </List.Item>
      <List.Item>
   
         <List.Item.Meta
          avatar={<Avatar src={imgreport} />}
          title={<a >Tabular report</a>}
          description="Create tabular view of the data"
        />
      </List.Item>
      </List>} >
        
      
<Button
        variant="outlined"
        color="primary"
        size="small"
        className=""
        startIcon={<PlusCircleOutlined />}
      >
        Add widget
      </Button>
      </Popover>   
 
               
                <ResponsiveReactGridLayout
                    onLayoutChange={this.onLayoutChange}
                    onBreakpointChange={this.onBreakpointChange}
                    {...this.props}
                >
                    {_.map(this.state.items, el => this.createElement(el))}
                </ResponsiveReactGridLayout>
            </div>
        );
    }
}