import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetailsByUserId as getUserDetailsByUserIdAPI } from '../../../../data/api/orgRegistryServerRequests';
import sliceConstants from './userSliceConstants';

export const dummyFn = createAsyncThunk(
	`${sliceConstants.name}/dummyFn`,
	async (options = {}, thunkAPI) => {
		try {
			const value = await Promise.resolve(options.dummyValue);
			return value;
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const getCurrentlyLoggedUserDetails = createAsyncThunk(
	`${sliceConstants.name}/getCurrentlyLoggedUserDetails`,
	async (options = {}, thunkAPI) => {
		try {
			const userDetails = await getUserDetailsByUserIdAPI({
				UserID: sessionStorage.getItem('userID'),
			});

			return userDetails;
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	dummyFn,
	getCurrentlyLoggedUserDetails,
};

export default extraActions;
