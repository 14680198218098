import { HomeOutlined } from '@ant-design/icons';
import {
	Button,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { ShoppingCartOutlined as ShoppingCartIcon } from '@material-ui/icons';
import { Breadcrumb, message, Modal, PageHeader, Result, Spin } from 'antd';
import React from 'react';
import ReactSelect from 'react-select';
import { getAllAppTypeDetailsOnPlatform } from '../../../../data/api/appRegistryServerRequests';
import {
	addAppToOrganization,
	getAppsOfOrgByOrganizationId,
	getCompaniesOfOrgByOrganizationId,
	getUserOrgDetailsByUserId,
	updateUserOrgDetailsByUserRegId,
} from '../../../../data/api/orgRegistryServerRequests';
import { cssHideIf } from '../../../../lib/utilities/cssHelpers';
import './PurchaseAppsSection.css';

export default class PurchaseAppsSection extends React.Component {
	constructor(props) {
		super(props);

		const currentUserId = sessionStorage.getItem('userID'); // Logged UserId
		const currentlySelectedOrgId = sessionStorage.getItem('orgID'); // Selected Organization in Dashboard Top.

		this.state = {
			isInitialDataFetching: false,
			isInitialDataFetchingError: '',
			isAppPurchaseUpdating: false,

			currentUserId: currentUserId,
			currentlySelectedOrgId: currentlySelectedOrgId,

			currentOrgDetails: {},
			currentOrgCompanyList: [],
			currentOrgPurchasedAppList: [],
			selectedCompany: '',
		};
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	async fetchNecessaryInitialPageData() {
		try {
			this.setState({
				isInitialDataFetching: true,
				isInitialDataFetchingError: '',
			});

			const { currentUserId, currentlySelectedOrgId } = this.state;

			// Requesting this way to achieve parallel fetching.
			const allRequestResponses = await Promise.all([
				// Fetch every Organization's details that user part of. (But we only get currently selected org details)
				getUserOrgDetailsByUserId({
					UserID: currentUserId,
				}),

				// Fetch default details of all the apps available in our platform.
				getAllAppTypeDetailsOnPlatform(),

				// Fetch all company details list for currently selected organization.
				// This is needed because currently "getUserOrgDetailsByUserId()" result don't contain Company names, only ids. But from this API we can get name details.
				getCompaniesOfOrgByOrganizationId({
					OrganizationID: currentlySelectedOrgId,
				}),

				// Fetch all app list purchased for current organization. Later from this list we can determine which apps are specifically purchased for certain company.
				getAppsOfOrgByOrganizationId({
					OrganizationID: currentlySelectedOrgId,
				}),
			]);

			// Extracting Data From Responses.
			const userAllOrgDetails = allRequestResponses[0];
			const currentOrgDetails = userAllOrgDetails.find(
				(org) => org.OrganisationID === currentlySelectedOrgId,
			);

			const allAppTypeDetails = allRequestResponses[1].sort((a, b) =>
				a.Name.localeCompare(b.Name),
			);

			const currentOrgCompanyList = allRequestResponses[2];

			const currentOrgPurchasedAppList = allRequestResponses[3];

			// If theres only one company available for current organization, we select it by default for users convenience. Otherwise leave it empty to prompt user select himself.
			const selectedCompany =
				currentOrgCompanyList.length === 1
					? {
							value: currentOrgCompanyList[0].CompanyID,
							label: currentOrgCompanyList[0].Name,
					  }
					: '';

			this.setState({
				isInitialDataFetching: false,

				currentOrgDetails: currentOrgDetails,
				allAppTypeDetails: allAppTypeDetails,
				currentOrgCompanyList: currentOrgCompanyList,
				currentOrgPurchasedAppList: currentOrgPurchasedAppList,

				selectedCompany: selectedCompany,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			this.setState({
				isInitialDataFetching: false,
				isInitialDataFetchingError: errMsg,
			});
		}
	}

	handleAppSelect = async (AppLicenseID, ApplicationID) => {
		try {
			const {
				currentUserId,
				currentlySelectedOrgId,
				currentOrgPurchasedAppList,
				selectedCompany,
				currentOrgDetails,
			} = this.state;

			this.setState({ isAppPurchaseUpdating: true });

			// If selected app already purchased for current company we abort the purchase process.
			var isAppAlreadyPurchasedForCurrentCompany = currentOrgPurchasedAppList.some(
				(app) =>
					app.AppLicenseID === AppLicenseID &&
					app.CompanyID === selectedCompany.value,
			);

			if (isAppAlreadyPurchasedForCurrentCompany) {
				Modal.error({
					title: 'You already purchased this app.',
					content: 'Please choose different app to purchase.',
					onOk: () => {},
				});

				return;
			}

			// NOTE : Adding App To Company/Organization is two step process. First we add app to specific company. If that success then we update userOrgDetails with new AppLicence.
			const hideMsg = message.loading(
				{ content: 'App Purchasing in Progress...' },
				0,
			);

			// **************** Step 1 - Adding Purchased App to Organization/Company **************
			await addAppToOrganization({
				OrganizationID: currentlySelectedOrgId,
				InstalledUser: currentUserId,
				ApplicationID: ApplicationID,
				CompanyID: selectedCompany.value,
			});

			// **************** Step 2 - Adding Purchased App to UserOrgDetails **************

			// NOTE : updateUserOrgDetailsByUserRegId() body must match with 'getUserOrgDetailsByUserId()' response pattern.
			const updateDetails = {
				...currentOrgDetails,
			};

			// NOTE : Currently it seems, in UserOrgDetails (getUserOrgDetailsByUserId() Response) "AppLicence" section details is
			//  			only for Apps that he purchased for any company. Its Not categorized for individual company.
			// 			  So since user can purchase same app for multiple company, in here we check if purchased app is already in UserOrgDetails records.
			//        If thats the case, we avoid it adding again.
			// 				We are doing it like this because 'UserOrgDetails' data is used in other section of app and they don't seem to be expecting duplicates of same app.
			// 				Change this behaviour as app progress, if needed.
			const currentAppLicenceesOfUser = updateDetails.AppLicenses;
			const isPurchasedAppAlreadyAvailableInUserDetails = currentAppLicenceesOfUser.some(
				(App) => App.AppLicenseID === AppLicenseID,
			);

			if (!isPurchasedAppAlreadyAvailableInUserDetails) {
				// Adding Purchased App Details to User OrgData Set.
				updateDetails.AppLicenses.push({
					AppLicenseID: AppLicenseID,
					Status: 'Active',
				});

				await updateUserOrgDetailsByUserRegId(updateDetails);
			}

			Modal.success({
				title: 'Apps successfully purchased.',
				content: 'Thank you for purchasing the app.',
				onOk: () => {},
			});
			hideMsg();

			// Refetching all base details so UI will have updated details.
			await this.fetchNecessaryInitialPageData();
			this.setState({ isAppPurchaseUpdating: false });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			Modal.error({
				title: 'App purchasing failed.',
				content: errMsg,
				onOk: () => {},
			});

			this.setState({
				isAppPurchaseUpdating: false,
			});
		}
	};

	render() {
		const {
			isInitialDataFetching,
			isInitialDataFetchingError,
			isAppPurchaseUpdating,

			selectedCompany,
			currentOrgPurchasedAppList,
			currentOrgCompanyList,
			allAppTypeDetails,
		} = this.state;

		const isAnyMainActionsRunning = isInitialDataFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning && isInitialDataFetchingError;
		const isCompanySelected = Boolean(selectedCompany);

		return (
			<div className='PurchaseAppsSection GC-UTL-fullFlexHeight'>
				<div className='PurchaseAppsSection__header'>
					<Breadcrumb>
						<Breadcrumb.Item>
							<HomeOutlined />
						</Breadcrumb.Item>

						<Breadcrumb.Item>Purchase Apps</Breadcrumb.Item>
					</Breadcrumb>

					<PageHeader
						title='Purchase Apps'
						subTitle='Purchase Any Additional Apps From Us.'
					/>
				</div>

				<div className='PurchaseAppsSection__body'>
					<div>
						{/* Used to indicate main actions like fetching in componentDidMount.  */}
						{isAnyMainActionsRunning && (
							<div
								className={`PurchaseAppsSection__body__mainLoader GC-UTL-flexSuperCenter`}>
								<Spin />
							</div>
						)}

						{/* When error occurred */}
						{isAnyMainActionsError && (
							<div className='PurchaseAppsSection__body__mainError GC-UTL-flexSuperCenter'>
								<Result
									status='error'
									subTitle={isAnyMainActionsError}
									extra={[
										<Button
											variant='contained'
											onClick={() => this.fetchNecessaryInitialPageData()}>
											Try Again
										</Button>,
									]}
								/>
							</div>
						)}

						{/* All below are only displayed when all necessary details are done fetching and no errors occurred  */}
						<div
							className={`PurchaseAppsSection__body__mainData ${cssHideIf(
								isAnyMainActionsRunning || isAnyMainActionsError,
							)}`}>
							<ReactSelect
								placeholder='Select a Company'
								options={currentOrgCompanyList.map((com) => {
									return {
										value: com.CompanyID,
										label: com.Name,
									};
								})}
								value={selectedCompany}
								onChange={(selectedOption) => {
									this.setState({
										selectedCompany: selectedOption,
									});
								}}
							/>

							{/* When no Company is selected. */}
							{!isCompanySelected && (
								<div className='PurchaseAppsSection__body__mainData__noSelection'>
									<Result subTitle='Please select a Company to get started.' />
								</div>
							)}

							{/* When company is selected */}
							{isCompanySelected && (
								<div className='PurchaseAppsSection__body__mainData__appList'>
									<div>Please select an app to purchase from the list</div>

									<List>
										{/* // Rendering all App types available in platform and marking already purchased apps for selected company. */}
										{allAppTypeDetails.map((app, i) => {
											// Checking this app type is already purchased on selected company.
											const isAlreadyPurchasedItem = currentOrgPurchasedAppList.some(
												(purchasedApp) => {
													return (
														purchasedApp.AppLicenseID === app.AppLicenseID &&
														purchasedApp.CompanyID === selectedCompany.value
													);
												},
											);

											return (
												<ListItem key={i}>
													<ListItemAvatar>
														<img
															src={
																app.AppImage !== ''
																	? app.AppImage
																	: '/inc/img/notfound.png'
															}
															border='0'
															width='50px'
															alt=''
														/>
													</ListItemAvatar>

													<ListItemText
														primary={app.Name}
														secondary={<small>{app.AboutApp}</small>}
													/>

													<ListItemSecondaryAction>
														{isAlreadyPurchasedItem ? (
															<div className='PurchaseAppsSection__body__mainData__appList____alreadyPurchasedLabel'>
																Already Purchased
															</div>
														) : (
															<Button
																className='PurchaseAppsSection__body__mainData__appList____purchasedButton'
																variant='outlined'
																size='medium'
																edge='end'
																disabled={isAppPurchaseUpdating}
																endIcon={<ShoppingCartIcon />}
																onClick={() => {
																	this.handleAppSelect(
																		app.AppLicenseID,
																		app.ApplicationID,
																	);
																}}>
																Purchase Now
															</Button>
														)}
													</ListItemSecondaryAction>
												</ListItem>
											);
										})}
									</List>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
