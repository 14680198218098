/* eslint-disable no-template-curly-in-string */
import { Button, Form, Input, message, Modal } from 'antd';
import React from 'react';
import {
	loginUser,
	updateUserPassword,
} from '../../../../../data/api/orgRegistryServerRequests';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

const formValidateMessages = {
	required: '${label} is required.',
};

export default class UserPasswordUpdateForm extends React.Component {
	formRef = React.createRef();

	// Updating User Password.
	async handleFormSubmit(values) {
		const {
			onDoneFn = () => {}, // If parent component passes this, parent component can use this to do something after user being updated.
		} = this.props;

		try {
			const { userDetails } = this.props;

			const updateProgressMsgId = 'updateProgress';
			const hideMsg = message.loading(
				{ content: 'Validating User...', key: updateProgressMsgId },
				0,
			);

			// Making sure, User who trying to update password is actually the real user. If not this will re-throw an error.
			await loginUser({
				UserName: userDetails.Email,
				Password: values.CurrentPassword,
			}).catch((error) => {
				if (error.message.includes('Password does not match')) {
					error.customErrMsg = 'The current password is invalid.';
				}

				throw error;
			});

			// NOTE : Came to here mean inputted current password is correct and user is authenticated. So we will start updating user's password with new password.
			message.loading(
				{ content: 'Updating Password...', key: updateProgressMsgId },
				0,
			);

			const reqBody = {
				UserEmail: userDetails.Email,
				Password: values.NewPassword,
			};
			await updateUserPassword(reqBody);

			hideMsg();
			onDoneFn({ isSuccess: true, formValues: values });

			Modal.success({
				title: 'Password Updated',
				content: 'Your password has been successfully updated.',
				onOk() {},
			});

			return;
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Sorry, Some error occurred while updating the Password.';

			onDoneFn({
				isSuccess: false,
				formValues: values,
			});

			Modal.error({
				title: 'Password Not Updated',
				content: errMsg,
				onOk: () => {},
			});
		}
	}

	componentDidUpdate(prevProps) {
		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				this.formRef.current && this.formRef.current.resetFields();
			}
		}
	}

	render() {
		return (
			<div className='UserPasswordUpdateForm'>
				<Form
					{...formItemLayout}
					name='UserPasswordUpdateForm__Form'
					onFinish={(formValues) => this.handleFormSubmit(formValues)}
					validateMessages={formValidateMessages}
					scrollToFirstError
					ref={this.formRef}>
					<Form.Item
						name='CurrentPassword'
						label='Current Password'
						rules={[
							{
								required: true,
							},
						]}>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name='NewPassword'
						label='New Password'
						rules={[
							{
								required: true,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('CurrentPassword') !== value) {
										return Promise.resolve();
									}

									return Promise.reject(
										'Your new password cannot be same as your current password.',
									);
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name='ConfirmPassword'
						label='Confirm Password'
						rules={[
							{
								required: true,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('NewPassword') === value) {
										return Promise.resolve();
									}

									return Promise.reject(
										'The new two passwords that you entered do not match.',
									);
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Update
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	}
}
