import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'manageUsersSection';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* setManageUsersSectionCurrentTabId() *******************************
		currentManageUsersSectionTabId: '1',
	},
};

export default sliceConstants;
