import React from 'react';

// Helper component to render children "only if" condition is true.
export function RenderIf(props) {
	const { condition = false } = props;

	if (condition) {
		return props.children;
	} else {
		return null;
	}
}

// Helper component to wrap components like <Row>, <Col>
export function Container(props) {
	return <div {...props}>{props.children}</div>;
}
