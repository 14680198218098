import { EditFilled as EditFilledIcon } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React from 'react';

export default class LocationTable extends React.Component {
	getTableData() {
		const {
			companyDetails = {},
			locationList = [],
			onLocationEditClicked = () => {},
		} = this.props;

		// NOTE : When 'dataIndex' is passed Table automatically show that key's value.
		// 				If this not passed whole row data values get passed and we have to manually extract necessary data from it in 'render' key prop.
		// 				See 'https://ant.design/components/table/#' for more info.
		const locationTableColumns = [
			{
				title: 'Location Name',
				dataIndex: 'LocationName',
				key: 'LocationName',
				align: 'center',
				width: '175px',
			},
			{
				title: 'Location Code',
				dataIndex: 'LocationCode',
				key: 'LocationCode',
				width: '175px',
				align: 'center',
			},
			{
				title: 'Location Status',
				dataIndex: 'LocationStatus',
				key: 'LocationStatus',
				width: '175px',
				align: 'center',
				render: (locationStatus) => {
					if (locationStatus === 1) {
						return <div style={{ color: 'green' }}>Active</div>;
					} else {
						return <div style={{ color: 'red' }}>Inactive</div>;
					}
				},
				style: {
					minWidth: '100px',
				},
			},
			{
				title: () => <div style={{ textAlign: 'center' }}>Addresses</div>,
				dataIndex: 'Addresses',
				key: 'Addresses',
				ellipsis: true,
				render: (addresses) => {
					return addresses.map((address) => {
						return (
							<div>
								<div>
									<b>{address.AddressType}</b>
								</div>
								<div>
									{address.AddressLine1}, {address.AddressLine2}, {address.City}
									, {address.Country}
								</div>
								<div>{address.Email}</div>
								<div>{address.Phone}</div>
								<br />
							</div>
						);
					});
				},
			},
			{
				title: '',
				key: 'myActions',
				width: '50px',
				render: (rowValues) => {
					const {
						selectedCompanyDetails,
						selectedLocationDetails,
					} = rowValues.myActions;

					return (
						<Button
							icon={<EditFilledIcon />}
							title='Edit'
							onClick={() => {
								// This parent component's fn, Open the LocationForm which is pre-filled with this location details. Then user can update this location's details.
								onLocationEditClicked({ selectedLocationDetails });
							}}
							style={{
								background: '#264653',
								borderColor: '#264653',
								color: 'white',
							}}>
							EDIT
						</Button>
					);
				},
			},
		];

		// Defining each row's column data.
		const locationTableRows = locationList.map((loc, index) => {
			return {
				key: index,
				LocationName: loc.Name,
				LocationCode: loc.LocationCode,
				LocationStatus: loc.Status,
				Addresses: loc.Addresses,
				myActions: {
					selectedCompanyDetails: companyDetails,
					selectedLocationDetails: loc,
				},
			};
		});

		return {
			locationTableColumns,
			locationTableRows,
		};
	}

	render() {
		const { locationList = [] } = this.props;

		const { locationTableColumns, locationTableRows } = this.getTableData();

		return (
			<Table
				size='small'
				bordered={true}
				pagination={
					locationList.length > 3
						? {
								pageSize: 3,
						  }
						: false
				}
				columns={locationTableColumns}
				dataSource={locationTableRows}
				scroll={{ x: true }}
				style={{
					maxWidth: '900px',
					margin: '0 auto',
				}}
			/>
		);
	}
}
