import { Config as GlobalConfigValues } from '../global/Config';

const WITMEG__ORGREGISTRYSERVER___BASEURL = GlobalConfigValues.OrgRegistryApi;
const WITMEG__APPREGISTRYSERVER___BASEURL = GlobalConfigValues.AppRegistryApi;
const WITMEG__EMAILREGISTRYSERVER___BASEURL =
	GlobalConfigValues.EmailRegistryApi;

const apiEndpoints = {
	// ******************************************* Org Registry Server *******************************************
	orgRegistry: {
		getUserDetailsByUserId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user`, // ---> GET /user/:USERID
		checkUserAlreadyExist: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/find`, // ---> POST
		getUserIdByEmail: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/getuseridbymail`, // ---> POST
		addUser: `${WITMEG__ORGREGISTRYSERVER___BASEURL}v1/user/add`, // ---> POST
		updateUserDetails: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/update`, // ---> PUT /user/update/:USERID
		updateUserPassword: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/app/secretupdate`, // ---> POST
		loginUser: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/login`, // ---> POST
		generatePasswordResetLink: `${WITMEG__ORGREGISTRYSERVER___BASEURL}user/global/resetlink`, // ---> POST

		getUserOrgDetailsByUserId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}registereduser/user`, // ---> GET /registereduser/user/:USERID
		getOrganizationUserOrgDetailsByOrgId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}registeredusers`, // ---> GET /registeredusers/:ORGID
		getFullUserOrgDetailsByUserId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}registereduser/userdetail`, // ---> GET /registereduser/userdetail/:USERID.
		updateUserOrgDetailsByUserRegId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}registereduser/update`, // ---> PUT /registereduser/update/:USERREGISTRATIONID
		addUserOrgDetailsSet: `${WITMEG__ORGREGISTRYSERVER___BASEURL}registereduser/add`, // ---> POST

		addUserRole: `${WITMEG__ORGREGISTRYSERVER___BASEURL}adduserrole`, // ---> POST

		getOrganizationByOrgId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}organisation/id`, // ---> GET /organisation/id/:ORGANIZATIONID
		addOrganization: `${WITMEG__ORGREGISTRYSERVER___BASEURL}v1/organisation/add`, // ---> POST
		updateOrganizationDetails: `${WITMEG__ORGREGISTRYSERVER___BASEURL}organisation/update`, // ---> PUT  /organisation/update/:ORGANIZATIONID

		getCompanyByCompanyId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}company`, // ---> GET /company/:COMPANYID
		getCompaniesOfOrgByOrganizationId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}companies`, // ---> GET /companies/:ORGANIZATIONID
		addCompanyToOrganization: `${WITMEG__ORGREGISTRYSERVER___BASEURL}company/add`, // ---> POST

		getLocationByLocationId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}location`, // ---> GET /location/:LOCATIONID
		getLocationsOfComByCompanyId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}location/company`, // ---> GET /location/company/:COMPANYID
		getLocationsOfOrgByOrganizationId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}locations`, // ---> GET /locations/:ORGANIZATIONID
		addLocationToCompany: `${WITMEG__ORGREGISTRYSERVER___BASEURL}location/add`, // ---> POST
		updateLocationOfCompany: `${WITMEG__ORGREGISTRYSERVER___BASEURL}location/update`, // ---> PUT /location/update/:LocationID

		getAppsOfOrgByOrganizationId: `${WITMEG__ORGREGISTRYSERVER___BASEURL}orgapps`, // ---> GET /orgapps/:ORGANIZATIONID
		addAppToOrganization: `${WITMEG__ORGREGISTRYSERVER___BASEURL}orgapp/add`, // ---> POST
	},

	// *******************************************  App Registry Server *******************************************
	appRegistry: {
		getAllAppTypeDetailsOnPlatform: `${WITMEG__APPREGISTRYSERVER___BASEURL}v1/myapp`, // ---> POST
	},

	// *******************************************  Email Registry Server *******************************************
	emailRegistry: {
		sendEmail: `${WITMEG__EMAILREGISTRYSERVER___BASEURL}v1/email/add`, // ---> POST
	},
};

export default apiEndpoints;
