import { Button, Col, Result, Row, Spin } from 'antd';
import React, { Component } from 'react';
import { getAllAppTypeDetailsOnPlatform } from '../../../../data/api/appRegistryServerRequests';
import {
	getAppsOfOrgByOrganizationId,
	getCompaniesOfOrgByOrganizationId,
	getOrganizationByOrgId,
	getOrganizationUserOrgDetailsByOrgId,
	getUserDetailsByUserId,
	getUserOrgDetailsByUserId,
} from '../../../../data/api/orgRegistryServerRequests';
import './DashboardHomeSection.css';
import AccountDetailsSummary from './subSections/AccountDetailsSummary';
import OrganizationSummary from './subSections/OrganizationSummary';
import PurchasedAppsSummary from './subSections/PurchasedAppsSummary';
import ReportsSummary from './subSections/ReportsSummary';
import UsersSummary from './subSections/UsersSummary';

export default class DashboardHomeSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOrganizationSummaryCardRelatedDataFetching: false,
			isOrganizationSummaryCardRelatedDataFetchingError: '',
			currentOrgBasicDetails: {},
			currentOrgCompanyList: [],

			isAppsSummaryCardRelatedDataFetching: false,
			isAppsSummaryCardRelatedDataFetchingError: '',
			purchasedAppsByCompanyWithExtendedData: {},
			currentOrgUserOrgDetails: {},

			isUsersSummaryCardRelatedDataFetching: false,
			isUsersSummaryCardRelatedDataFetchingError: '',
			allUsersOfCurrentOrgWithExtendedData: [],

			isAccountSummaryCardRelatedDataFetching: false,
			isAccountSummaryCardRelatedDataFetchingError: '',
			currentUserAccountDetails: {},
		};
	}

	async fetchAppsSummaryCardRelatedData() {
		try {
			this.setState({
				isAppsSummaryCardRelatedDataFetching: true,
				isAppsSummaryCardRelatedDataFetchingError: '',
			});

			const currentUserId = sessionStorage.getItem('userID'); // Logged UserId
			const currentlySelectedOrgId = sessionStorage.getItem('orgID'); // Selected Organization in Dashboard Top.

			// Requesting this way to achieve parallel fetching.
			const allRequestResponses = await Promise.all([
				// Fetch every Organization's details that user part of. (But we only get currently selected org details)
				getUserOrgDetailsByUserId({
					UserID: currentUserId,
				}),

				// Fetch default details of all the apps available in our platform.
				getAllAppTypeDetailsOnPlatform(),

				// Fetch all company details list for currently selected organization.
				// This is needed because currently "getUserOrgDetailsByUserId()" result don't contain Company names, only ids. But from this API we can get name details.
				getCompaniesOfOrgByOrganizationId({
					OrganizationID: currentlySelectedOrgId,
				}),

				// Fetch all app list purchased for current organization. Later from this list we can determine which apps are specifically purchased for certain company.
				getAppsOfOrgByOrganizationId({
					OrganizationID: currentlySelectedOrgId,
				}),
			]);

			// Extracting Data From Responses.
			const userAllOrgDetails = allRequestResponses[0];
			const currentOrgDetails = userAllOrgDetails.find(
				(org) => org.OrganisationID === currentlySelectedOrgId,
			);

			const allAppTypeDetails = allRequestResponses[1];

			const currentOrgCompanyList = allRequestResponses[2];

			const currentOrgPurchasedAppList = allRequestResponses[3];

			// Categorizing each purchased app into its specific company and appending more 'App & Company Details'.
			const currentOrgPurchasedAppListByCompanyName = currentOrgPurchasedAppList.reduce(
				(acc, currentApp) => {
					// Getting current iteration app's Company Name.
					const companyId = currentApp.CompanyID;
					const companyDetails =
						currentOrgCompanyList.find((com) => com.CompanyID === companyId) ||
						{};
					const companyName = companyDetails.Name;

					// Getting current iteration app's more generic app details.
					const genericAppDetails = allAppTypeDetails.find(
						(app) => app.AppLicenseID === currentApp.AppLicenseID,
					);

					// NOTE : As Details coming from backend for some TEST users are not stable. (Like some of required keys are missing or empty). So below fix is used to avoid errors from those kinds of things.
					if (!companyName || !genericAppDetails) {
						return acc;
					}

					// Getting current iteration's app related company section in accumulator if available.
					const companyAppList = acc[companyName] || [];

					companyAppList.push({
						...currentApp,
						CompanyDetails: companyDetails, // Appending "Company Details" to App Details.
						GenericAppDetails: genericAppDetails, // Appending more "Generic App Details" to App Details. (Include things like App Image URL.)
					});

					// Making apps grouped by category name and sorting them.
					acc[companyName] = companyAppList.sort((a, b) =>
						a.AppName.localeCompare(b.AppName),
					);

					return acc;
				},
				{},
			);

			this.setState({
				isAppsSummaryCardRelatedDataFetching: false,
				isAppsSummaryCardRelatedDataFetchingError: '',

				purchasedAppsByCompanyWithExtendedData: currentOrgPurchasedAppListByCompanyName,
				currentOrgUserOrgDetails: currentOrgDetails,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			this.setState({
				isAppsSummaryCardRelatedDataFetching: false,
				isAppsSummaryCardRelatedDataFetchingError: errMsg,
			});
		}
	}

	async fetchOrganizationSummaryCardRelatedData() {
		try {
			this.setState({
				isOrganizationSummaryCardRelatedDataFetching: true,
				isOrganizationSummaryCardRelatedDataFetchingError: '',
			});

			const currentlySelectedOrgId = sessionStorage.getItem('orgID');

			const currentOrgBasicDetails = await getOrganizationByOrgId({
				OrganizationID: currentlySelectedOrgId,
			});

			const currentOrgCompanyList = await getCompaniesOfOrgByOrganizationId({
				OrganizationID: currentlySelectedOrgId,
			});

			const sortedCurrentOrgCompanyList = currentOrgCompanyList.sort((a, b) =>
				a.Name.localeCompare(b.Name),
			);

			this.setState({
				isOrganizationSummaryCardRelatedDataFetching: false,
				currentOrgBasicDetails,
				currentOrgCompanyList: sortedCurrentOrgCompanyList,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Error occurred while fetching initial data.';

			this.setState({
				isOrganizationSummaryCardRelatedDataFetching: false,
				isOrganizationSummaryCardRelatedDataFetchingError: errMsg,
			});
		}
	}

	async fetchUsersSummaryCardRelatedData() {
		try {
			this.setState({
				isUsersSummaryCardRelatedDataFetching: true,
				isUsersSummaryCardRelatedDataFetchingError: '',
			});

			const currentlySelectedOrgId = sessionStorage.getItem('orgID');

			// Get Every User that belongs to selected organization.(This return 'UserOrgDetails' for each of the users.)
			const allUsersOfCurrentOrg = await getOrganizationUserOrgDetailsByOrgId({
				OrgID: currentlySelectedOrgId,
			});

			// Getting normal user details for every user in 'allUsersOfCurrentOrg' list.
			// NOTE : 'Promise.allSettled' needed because for some  user ids 'getUserDetailsByUserId' failed unexpectedly.
			const userDetailsForEachUser = await Promise.allSettled(
				allUsersOfCurrentOrg.map((user) => {
					return getUserDetailsByUserId({ UserID: user.UserID });
				}),
			).then((allResponses) => {
				// Cleaning out 'Promise.allSettled' responses.
				return (
					allResponses
						// .filter((r) => r.status !== 'rejected') // For only returning successful values. Temporary disabled to match indexes.
						.map((r) => r.value || {})
				);
			});

			const allUsersOfCurrentOrgWithExtendedData = allUsersOfCurrentOrg.map(
				(user, index) => {
					return {
						...user,
						___moreUserDetails: userDetailsForEachUser[index], // Appending more user details.
					};
				},
			);

			const sortedAllUsersOfCurrentOrgWithExtendedData = allUsersOfCurrentOrgWithExtendedData.sort(
				(a, b) => {
					a = a.___moreUserDetails.FirstName || '';
					b = b.___moreUserDetails.FirstName || '';

					return a.localeCompare(b);
				},
			);

			this.setState({
				isUsersSummaryCardRelatedDataFetching: false,
				allUsersOfCurrentOrgWithExtendedData: sortedAllUsersOfCurrentOrgWithExtendedData,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Error occurred while fetching initial data.';

			this.setState({
				isUsersSummaryCardRelatedDataFetching: false,
				isUsersSummaryCardRelatedDataFetchingError: errMsg,
			});
		}
	}

	async fetchAccountSummaryCardRelatedData() {
		try {
			this.setState({
				isAccountSummaryCardRelatedDataFetching: true,
				isAccountSummaryCardRelatedDataFetchingError: '',
			});

			const currentUserId = sessionStorage.getItem('userID'); // Logged UserId
			const currentUserAccountDetails = await getUserDetailsByUserId({
				UserID: currentUserId,
			});

			this.setState({
				isAccountSummaryCardRelatedDataFetching: false,
				currentUserAccountDetails: currentUserAccountDetails,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Error occurred while fetching initial data.';

			this.setState({
				isAccountSummaryCardRelatedDataFetching: false,
				isAccountSummaryCardRelatedDataError: errMsg,
			});
		}
	}

	async fetchNecessaryInitialData() {
		this.fetchOrganizationSummaryCardRelatedData();
		this.fetchAppsSummaryCardRelatedData();
		this.fetchUsersSummaryCardRelatedData();
		this.fetchAccountSummaryCardRelatedData();
	}

	componentDidMount() {
		this.fetchNecessaryInitialData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// When selected organization changed we re-fetch the data.
		if (
			this.props.dashboardSliceState.currentlySelectedOrgIndex !==
			prevProps.dashboardSliceState.currentlySelectedOrgIndex
		) {
			this.fetchNecessaryInitialData();
		}
	}

	render() {
		const {
			isOrganizationSummaryCardRelatedDataFetching,
			isOrganizationSummaryCardRelatedDataFetchingError,
			currentOrgBasicDetails,
			currentOrgCompanyList,

			isAppsSummaryCardRelatedDataFetching,
			isAppsSummaryCardRelatedDataFetchingError,
			purchasedAppsByCompanyWithExtendedData,
			currentOrgUserOrgDetails,

			isUsersSummaryCardRelatedDataFetching,
			isUsersSummaryCardRelatedDataFetchingError,
			allUsersOfCurrentOrgWithExtendedData,

			isAccountSummaryCardRelatedDataFetching,
			isAccountSummaryCardRelatedDataError,
			currentUserAccountDetails,
		} = this.state;

		const isAnyMainActionsRunning =
			isOrganizationSummaryCardRelatedDataFetching ||
			isAppsSummaryCardRelatedDataFetching ||
			isUsersSummaryCardRelatedDataFetching ||
			isAccountSummaryCardRelatedDataFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning &&
			(isOrganizationSummaryCardRelatedDataFetchingError ||
				isAppsSummaryCardRelatedDataFetchingError ||
				isUsersSummaryCardRelatedDataFetchingError ||
				isAccountSummaryCardRelatedDataError);

		if (isAnyMainActionsRunning) {
			return (
				<div className='DashboardHomeSection GC-UTL-flexSuperCenter'>
					<Spin />
				</div>
			);
		}

		if (isAnyMainActionsError) {
			return (
				<div className='DashboardHomeSection GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.fetchNecessaryInitialData()}>
								Try Again
							</Button>,
						]}
					/>
				</div>
			);
		}

		return (
			<div className='DashboardHomeSection site-card-border-less-wrapper'>
				<Row gutter={[20, 20]}>
					<Col sm={24}>
						<AccountDetailsSummary
							{...this.props}
							currentUserAccountDetails={currentUserAccountDetails}
						/>
					</Col>

					<Col sm={24} md={12} lg={12} xl={8}>
						<OrganizationSummary
							{...this.props}
							currentOrgBasicDetails={currentOrgBasicDetails}
							currentOrgCompanyList={currentOrgCompanyList}
						/>
					</Col>

					<Col sm={24} md={12} lg={12} xl={8}>
						<PurchasedAppsSummary
							{...this.props}
							purchasedAppsByCompanyWithExtendedData={
								purchasedAppsByCompanyWithExtendedData
							}
							currentUserOrgDetails={currentOrgUserOrgDetails}
						/>
					</Col>

					<Col sm={24} md={12} lg={12} xl={8}>
						<UsersSummary
							{...this.props}
							allUsersOfCurrentOrgWithExtendedData={
								allUsersOfCurrentOrgWithExtendedData
							}
						/>
					</Col>

					<Col sm={24} md={12} lg={12} xl={24}>
						<ReportsSummary />
					</Col>
				</Row>

				<Row></Row>
			</div>
		);
	}
}
