import React, { Component } from 'react';
import {Route } from "react-router-dom";
import 'antd/dist/antd.css';
import "./css/index.css";
import CDashboard from "./Dashboard";
import Welcome from "./Welcome";
import InvoiceReport from "./InvoiceReport";
import { Popover, Modal, Layout, Menu, Form, Input, Radio, Mentions } from 'antd';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Dashboard from '@material-ui/icons/Dashboard';
import ShareIcon from '@material-ui/icons/Share';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import DraftsIcon from '@material-ui/icons/Drafts';
import JWToken from "../../com/mod/JWToken";
import {
  MailOutlined, 
  AppstoreOutlined, 
  FolderOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  PlusCircleOutlined,
  HomeOutlined,
  FileSearchOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
const { Option } = Mentions;
const { Header, Sider, Content } = Layout;
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const { SubMenu } = Menu;

  function handleClick(e) {
    console.log(e)
  if(e.key=='invoice'){
      window.location.href="/report/invoice/"+ sessionStorage.getItem('appid')
  }
}
function onChange(value) {
   
    console.log('Change:', value);
  }
  
  function onSelect(option) {
    console.log('select', option);
  }


export default class ReportIndex extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        collapsed: false,
        visible: false,
        pvisible: false,
        mvisible:false,
        value: 1
      };
    
    toggle = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
        window.location.href="/report/dashboard"
      };
      handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
      };
      onChange = e => {
        console.log('radio checked', e.target.value);
        if(e.target.value==1){
            document.getElementById("privatemsg").innerHTML="Only visible to you";
       

        }else{
            
            document.getElementById("privatemsg").innerHTML="Please select the users to share with";
           
            

        }
        
        this.setState({
          value: e.target.value,
        });
      };
      componentDidMount() {
         
    }

      

      handleVisibleChange = pvisible => {
        this.setState({ pvisible });
      };
      selectDash = (e) =>    {
  
        console.log(e)
      if(e=='dashboard'){
        
        this.setState({
            pvisible: false,
          });
          this.showModal()

      }
    }
    render() {
        return (
            <div>
<Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo"></div>
          
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
             <a href="/report"><b>Main Workspace</b></a>
            </Menu.Item>

            <Menu.Item key="2" icon={<PlusCircleOutlined />}>
           
            <Popover content={
            <>
           <List component="nav"  aria-label="Dashboard">
        <ListItem onClick={() => this.selectDash("dashboard")} button>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText key="sub2" primary="New Blank Board" />


        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary="Drafts" />
        </ListItem>
      </List>
      <Divider />
      <List component="nav" aria-label="Folders">
        <ListItemLink href="#simple-list">
          <ListItemText primary="New Folder" />
        </ListItemLink>
      </List>
            </>
            } placement="rightTop" 
            trigger="click"
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.pvisible}
            >
       Add
    </Popover> 
            </Menu.Item>
            <Menu.Item key="5" icon={<DashboardOutlined />}>
            <Popover content={
            <>
         
         
         <Menu onClick={handleClick} style={{ width: '100%' }} mode="inline">
   
    <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Dashboard 1">
      <Menu.Item key="invoice">Invoice</Menu.Item>
      <Menu.Item key="6">Sales</Menu.Item>
      
    </SubMenu>
    <SubMenu key="sub4" icon={<FolderOutlined />} title="My Folder">
      <SubMenu key="ssub1" icon={<FolderOutlined />} title="My Dashboard">
      <Menu.Item key="9">Invoice</Menu.Item>
      <Menu.Item key="10">Sales</Menu.Item>
      </SubMenu>
      <Menu.Item key="11">Another Dashboard</Menu.Item>
    </SubMenu>
      
    
  </Menu>

  

            </>
            } placement="rightTop" trigger="click">
       Dashboards
    </Popover>  
            </Menu.Item>
            <Menu.Item key="3" icon={<FileSearchOutlined />}>
              Search
            </Menu.Item>
            <Menu.Item key="4" icon={<UserOutlined />}>
              Users
            </Menu.Item>
          
          
          </Menu>
        
        
         
         
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            <JWToken>
                    <Route path="/report/invoice/:appid" render={(props) => <InvoiceReport {...props} />}></Route>
                    <Route exact path="/report/" component={Welcome}></Route>
                    <Route exact path="/report/dashboard/" component={CDashboard}></Route>
               </JWToken>

               <Modal
     
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Create Board"
        >
          <h2>Create board</h2>
         
          <Form.Item
     
        name="boardname"
        rules={[{ required: true, message: 'Please input board name!' }]}
      >
       <Input placeholder="New Blank Board" defaultValue="New Blank Board" />
      </Form.Item>
       
      <Form.Item
     
     name="accesstype"
     rules={[{ required: true, message: 'Please select access type!' }]}
   >
    <Radio.Group onChange={this.onChange} value={this.state.value}>
        <Radio value={1}><FingerprintIcon /> Private</Radio>
        <Radio value={2}><ShareIcon />Shareable</Radio>
      </Radio.Group>
     <p><span id="privatemsg">Only visible to you</span></p> 
     <Mentions
    style={{ width: '100%'}}
    id="users"
    onChange={onChange}
    onSelect={onSelect}
    visible={this.state.mvisible}
    defaultValue=""
  >
    <Option value="Suren">Suren</Option>
    <Option value="Shen">Shen</Option>
    <Option value="Tha">Tha</Option>
    <Option value="Lankitha">Lankitha</Option>
    <Option value="Shen">Nithya</Option>
    <Option value="Tha">Sandeepani</Option>
  </Mentions>
   </Form.Item>
   
  

        </Modal>
          </Content>
         <div style={{'text-align': 'center'}}>©2020 Created by Ecologital</div>
        </Layout>
       
      </Layout>
              

            </div>
        )
    }
}
