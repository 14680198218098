import React, { Component } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import Login from "./Login";
import Verify from "./Verify";
import Forgot from "./Forgot";
import Register from "./Register";
import Logout from "./Logout";

import JWToken from "../../com/mod/JWToken";

export default class LoginIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <JWToken>
                    <Route path="/login/auth">
                        <Switch>
                            <Route path="/login/auth/:appid" render={(props) => <Login {...props} />}></Route>
                            <Route path="/login/auth" render={(props) => <Login {...props} />}></Route>
                        </Switch>
                    </Route>

                    <Route path="/login/register" exact render={(props) => <Register {...props} />}></Route>
                    <Route path="/login/register/:appid" render={(props) => <Register {...props} />}></Route>
                    <Route path="/login/verify/:token" render={(props) => <Verify {...props} />} />

                    <Route exact path="/login/forgot" component={Forgot}></Route>
                    <Route exact path="/login/logout" component={Logout}></Route>
                </JWToken>
            </div>
        )
    }
}
