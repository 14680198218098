/* eslint-disable no-template-curly-in-string */

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import {
	addLocationToCompany,
	updateLocationOfCompany,
} from '../../../../../../data/api/orgRegistryServerRequests';
import { countryList } from '../../../../../../lib/utilities/helperValues';
import { generateLocationCode } from '../../../../../../lib/utilities/mix/generateLocationCode';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
};

const validateMessages = {
	required: '${label} is required.',
	types: {
		email: '${label} is not a valid email.',
	},
};

export default class AddLocationForm extends Component {
	formRef = React.createRef();

	async onFormSubmit(values) {
		const { formMode = 'ADD', companyData = {} } = this.props;
		const { LocationDetails = {} } = companyData;

		const isEditMode = formMode === 'EDIT' ? true : false;

		try {
			if (values.sights === undefined) {
				Modal.error({
					title: 'Address Required',
					content: 'Please add at least one address for the location.',
					onOk: () => {},
				});
				return;
			}
			const reqBody = {
				Name: values.location.Name,
				LocationCode: values.location.Code,
				OrganisationID: sessionStorage.getItem('orgID'),
				Logo: '',
				CompanyID: sessionStorage.getItem('CompanyID'),
				CreatedBy: sessionStorage.getItem('userID'),
				ModifiedBy: sessionStorage.getItem('userID'),
				InvoiceSequence: values.location.InvoiceSequence,
				InvoicePrefix: values.location.InvoicePrefix,
				IsNegativeStockEnabled:
					values.location.IsNegativeStockEnabled === true ? 1 : 0,
				BinLocations: [],
				Addresses: values.sights,
			};

			const hideMsg = message.loading(
				{ content: isEditMode ? 'Updating...' : 'Adding...' },
				0,
			);

			if (isEditMode) {
				await updateLocationOfCompany({
					...LocationDetails, // Existing Values
					...reqBody, // Updated Field Values
					// Below are re-configure to abovid unnecessary overrides from above.
					OrganisationID: LocationDetails.OrganisationID,
					CompanyID: LocationDetails.CompanyID,
					CreatedBy: LocationDetails.CreatedBy,
				});
			} else {
				await addLocationToCompany(reqBody);
			}

			hideMsg();
			Modal.success({
				title: `Location ${isEditMode ? 'Updated' : 'Added'}`,
				content: `Location details has been successfully ${
					isEditMode ? 'updated.' : 'added.'
				}`,
				onOk: () => {},
			});
		} catch (error) {
			Modal.error({
				title: `Location Not ${isEditMode ? 'Updated' : 'Added'}`,
				content: `Sorry, Some error occurred while ${
					isEditMode ? 'updating' : 'adding'
				} your location.`,
				onOk: () => {},
			});
		}

		// This passed from parent and used to close this modal and re-fetch details so UI will be updated.
		this.props.onDone();
	}

	onLocationNameChangeGenerateLocationCode(e) {
		const { companyData, existingLocationCodesInOrg } = this.props;

		const companyName = companyData.CompanyDetails.Name;

		const locationCode = generateLocationCode({
			locationName: e.target.value,
			companyName: companyName,
			existingLocationCodes: existingLocationCodesInOrg,
		});

		// Updating 'Location Code' Field.
		this.formRef.current.setFieldsValue({
			location: {
				Code: locationCode,
			},
		});

		// Re-Forcing validations with updated data.
		this.formRef.current.validateFields();
	}

	// Helper utility to fill form inputs with some existing values.
	prefillForm() {
		const { companyData = {} } = this.props;
		const { LocationDetails = {} } = companyData;

		this.formRef.current &&
			this.formRef.current.setFieldsValue({
				location: {
					Name: LocationDetails.Name,
					Code: LocationDetails.LocationCode,
					InvoiceSequence: LocationDetails.InvoiceSequence,
					InvoicePrefix: LocationDetails.InvoicePrefix,
					IsNegativeStockEnabled: LocationDetails.IsNegativeStockEnabled,
				},
				sights: LocationDetails.Addresses,
			});
	}

	componentDidUpdate(prevProps) {
		const { shouldPrefillForm = false } = this.props;

		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				this.formRef.current && this.formRef.current.resetFields();
			}
		}

		// Used in situation like, When user need to update existing details.
		if (prevProps.shouldPrefillForm !== this.props.shouldPrefillForm) {
			if (shouldPrefillForm) {
				this.prefillForm();
			}
		}
	}

	componentDidMount() {
		const { shouldPrefillForm = false } = this.props;

		// Used in situation like, When user need to update existing details.
		if (shouldPrefillForm) {
			this.prefillForm();
		}
	}

	render() {
		const {
			existingLocationCodesInOrg,
			formMode = 'ADD',
			companyData = {},
		} = this.props;
		const { LocationDetails = {} } = companyData;

		const isEditMode = formMode === 'EDIT' ? true : false;

		return (
			<div>
				<Form
					{...layout}
					name='nest-messages'
					onFinish={(formValues) => this.onFormSubmit(formValues)}
					validateMessages={validateMessages}
					ref={this.formRef}>
					<Form.Item
						name={['location', 'Name']}
						label='Location Name'
						rules={[{ required: true }]}>
						<Input
							onChange={(e) => {
								this.onLocationNameChangeGenerateLocationCode(e);
							}}
						/>
					</Form.Item>

					<Form.Item
						name={['location', 'Code']}
						label='Location Code'
						rules={[
							{ required: true },
							{
								len: 3,
								message: 'Location Code must be only 3 characters long.',
							},
							{
								whitespace: true,
								message: 'Cannot have whitespace in Location Code.',
							},
							{
								pattern: new RegExp(/^[A-Z]+$/i),
								message: 'Only alphabetic letters are valid for Location Code.',
							},
							({ getFieldValue }) => {
								return {
									validator: (_, value) => {
										// If form is in "Edit" mode and 'LocationCode' input field value equal to already existing value, we need to allow it.
										// Otherwise user won't be able to update with same location code.
										if (isEditMode && LocationDetails.LocationCode === value) {
											return Promise.resolve();
										}

										const isLocationCodeAlreadyExist = existingLocationCodesInOrg.some(
											(existingLocCode) =>
												existingLocCode.toUpperCase() === value.toUpperCase(),
										);

										if (!value || !isLocationCodeAlreadyExist) {
											return Promise.resolve();
										}

										return Promise.reject('This Location Code already exist.');
									},
								};
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name={['location', 'InvoiceSequence']}
						label='Invoice Sequence'>
						<Input />
					</Form.Item>

					<Form.Item
						name={['location', 'InvoicePrefix']}
						label='Invoice Prefix'>
						<Input />
					</Form.Item>

					<Form.Item
						name={['location', 'IsNegativeStockEnabled']}
						label='Is Negative Stock Enabled'
						valuePropName='checked'>
						<Checkbox>Yes</Checkbox>
					</Form.Item>

					<Form.List name='sights'>
						{(fields, { add, remove }) => (
							<>
								{fields.map((field) => (
									<div key={field.key}>
										<span>
											<b>Address {field.key + 1}</b>
										</span>
										<Form.Item
											{...field}
											label='Address Type'
											name={[field.name, 'AddressType']}
											fieldKey={[field.fieldKey, 'AddressType']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Address Line 1'
											name={[field.name, 'AddressLine1']}
											fieldKey={[field.fieldKey, 'AddressLine1']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Address Line 2'
											name={[field.name, 'AddressLine2']}
											fieldKey={[field.fieldKey, 'AddressLine2']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='City'
											name={[field.name, 'City']}
											fieldKey={[field.fieldKey, 'City']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='State/Province/Region'
											name={[field.name, 'StateProvinceRegion']}
											fieldKey={[field.fieldKey, 'StateProvinceRegion']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='PostCode'
											name={[field.name, 'PostZipCode']}
											fieldKey={[field.fieldKey, 'PostZipCode']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Country'
											name={[field.name, 'Country']}
											fieldKey={[field.fieldKey, 'Country']}
											rules={[
												{
													required: true,
												},
											]}>
											<Select placeholder='Select a country' allowClear>
												<option />
												{countryList.map((countryName, index) => {
													return (
														<option value={countryName} key={index}>
															{countryName}
														</option>
													);
												})}
											</Select>
										</Form.Item>

										<Form.Item
											{...field}
											label='Email'
											name={[field.name, 'Email']}
											fieldKey={[field.fieldKey, 'Email']}
											rules={[
												{
													type: 'email',
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Phone Number'
											name={[field.name, 'Phone']}
											fieldKey={[field.fieldKey, 'Phone']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Fax Number'
											name={[field.name, 'Fax']}
											fieldKey={[field.fieldKey, 'Fax']}>
											<Input />
										</Form.Item>

										<span className='float-right'>
											{' '}
											<MinusCircleOutlined
												onClick={() => remove(field.name)}
											/>{' '}
											Remove address
										</span>
									</div>
								))}

								<Form.Item>
									<Button
										type='dashed'
										onClick={() => add()}
										block
										icon={<PlusOutlined />}>
										Add More Address
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Add
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	}
}
