import { axiosCall } from "../../lib/api/apiCall";
import { Config } from "../../lib/global/Config";
import { getJWT, generateOrgRegJwt } from "../../lib/global/helpers";
import Cookies from "js-cookie";

export function orgRequest(api, data = {}) {
    let method = "post";
    let url = "";
    let config = {};
    let orgjwt = Cookies.get("OrgToken");

    let useAccessToken = false;
    let useJWToken = false;

    switch (api) {

        /***users***********************************************************************/
        case "login":
            method = "post";
            url = "user/login";
            data = { UserName: data.Username, Password: data.Password };

            useJWToken = true;
            break;

        case "adduser":
            data.UserID = undefined;
            data.IsActive = undefined;
            data.IsVerified = undefined;

            method = "post";
            url = "v1/user/add";
            data = data;

            useJWToken = true;
            break;

        case "finduser":
            method = "post";
            url = "user/find";
            data = { "email": data.email };

            useJWToken = true;
            break;

        case "getuser":
            method = "get";
            url = "user/" + data.userid;
            data = {};

            useAccessToken = true;
            break;

        /***organization***************************************************************/
        case "retrievebyname":
            method = "get";
            url = "organisation/" + data.Name;
            data = {};

            useJWToken = true;
            break;

        case "retrievebyid":
            method = "get";
            url = "organisation/id/" + data.id;
            data = {};

            useJWToken = true;
            break;

        case "addorg":
            method = "post";
            url = "v1/organisation/add";
            data = data;

            useAccessToken = true;
            break;

        default:
            break;
    }

    let token = undefined;

    if (useAccessToken) {
        let JWT = getJWT();
        if (JWT.token != null) {
            let tk = generateOrgRegJwt(JWT.token);
            token = tk.token;
        }
    } else if (useJWToken) token = orgjwt;

    console.log(orgjwt);

    url = Config.OrgRegistryApi + url;
    console.log(JSON.stringify(data));

    return axiosCall(method, url, config, data, token);
}
