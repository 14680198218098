import {
	EditFilled as EditFilledIcon,
	HomeOutlined,
	SecurityScanOutlined as SecurityScanOutlinedIcon,
} from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
	Breadcrumb,
	Button,
	Col,
	Modal,
	PageHeader,
	Result,
	Row,
	Spin,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { getUserDetailsByUserId } from '../../../../data/api/orgRegistryServerRequests';
import { actions as userSliceActions } from '../../../../lib/redux/slices/userSlice/userSlice';
import AccountDetailsUpdateForm from './forms/AccountDetailsUpdateForm';
import UserPasswordUpdateForm from './forms/UserPasswordUpdateForm';
import AccountDetailsDisplay from './subSections/AccountDetailsDisplay';

class AccountDetailsSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isAccountDetailsUpdateModalVisible: false,
			isUserPasswordUpdateModalVisible: false,

			isUserDetailsFetching: false,
			isUserDetailsFetchingError: '',
			userDetails: {},
		};
	}

	async getUserDetails() {
		try {
			this.setState({
				isUserDetailsFetching: true,
				isUserDetailsFetchingError: '',
			});

			const currentlyLoggedUserId = sessionStorage.getItem('userID');

			const userDetails = await getUserDetailsByUserId({
				UserID: currentlyLoggedUserId,
			});

			this.setState({
				userDetails,
				isUserDetailsFetching: false,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Error Occurred While Fetching User Details.';

			this.setState({
				isUserDetailsFetching: false,
				isUserDetailsFetchingError: errMsg,
			});
		}
	}

	componentDidMount() {
		this.getUserDetails();
	}

	render() {
		const {
			isAccountDetailsUpdateModalVisible,
			isUserPasswordUpdateModalVisible,

			isUserDetailsFetching,
			isUserDetailsFetchingError,
			userDetails,
		} = this.state;

		const { userSliceActions } = this.props;
		const { getCurrentlyLoggedUserDetails } = userSliceActions;

		const isAnyMainActionsRunning = isUserDetailsFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning && isUserDetailsFetchingError;

		return (
			<div className='AccountDetailsSection GC-UTL-fullFlexHeight'>
				<div className='AccountDetailsSection__Modals'>
					<Modal
						title='Update User Details'
						width={800}
						footer={null}
						visible={isAccountDetailsUpdateModalVisible}
						onCancel={() => {
							this.setState({ isAccountDetailsUpdateModalVisible: false });
						}}>
						<AccountDetailsUpdateForm
							shouldResetForm={!isAccountDetailsUpdateModalVisible} // Used to clear form values when modal closing.
							userDetails={userDetails}
							onDoneFn={async () => {
								this.setState({
									isAccountDetailsUpdateModalVisible: false,
								});

								// Re-Fetching user details to show updated values in UI.
								this.getUserDetails(); // For this components state updates.
								// TODO: Need to use this redux level data on this component's everywhere, instead of managing local state.
								getCurrentlyLoggedUserDetails(); // This update state on redux level.
							}}
						/>
					</Modal>

					<Modal
						title='Update User Password'
						width={800}
						footer={null}
						visible={isUserPasswordUpdateModalVisible}
						onCancel={() => {
							this.setState({ isUserPasswordUpdateModalVisible: false });
						}}>
						<UserPasswordUpdateForm
							shouldResetForm={!isUserPasswordUpdateModalVisible} // Used to clear form values when modal closing.
							userDetails={userDetails}
							onDoneFn={async () => {
								this.setState({
									isUserPasswordUpdateModalVisible: false,
								});
							}}
						/>
					</Modal>
				</div>

				<Breadcrumb>
					<Breadcrumb.Item href=''>
						<HomeOutlined />
					</Breadcrumb.Item>
					<Breadcrumb.Item>Account Details</Breadcrumb.Item>
				</Breadcrumb>

				<PageHeader
					title='Account Details'
					subTitle='Update Your Account Details'
				/>

				{/* Used to indicate main actions like fetching in componentDidMount.  */}
				{isAnyMainActionsRunning && (
					<div className='GC-UTL-flexSuperCenter'>
						<Spin />
					</div>
				)}

				{/* When error occurred */}
				{isAnyMainActionsError && (
					<div className='GC-UTL-flexSuperCenter'>
						<Result
							status='error'
							subTitle={isAnyMainActionsError}
							extra={[
								<Button
									variant='contained'
									onClick={() => this.getUserDetails()}>
									Try Again
								</Button>,
							]}
						/>
					</div>
				)}

				{/* All below are only displayed when all necessary details are done fetching and no errors occurred  */}
				{!isAnyMainActionsRunning && !isAnyMainActionsError && (
					<>
						<Row justify='end'>
							<Col>
								<Button
									type='primary'
									icon={<EditFilledIcon />}
									onClick={() => {
										this.setState({
											isAccountDetailsUpdateModalVisible: true,
										});
									}}>
									Edit Details
								</Button>
								<Button
									type='primary'
									style={{ background: '#eb2f96', borderColor: '#eb2f96' }}
									icon={<SecurityScanOutlinedIcon />}
									onClick={() => {
										this.setState({
											isUserPasswordUpdateModalVisible: true,
										});
									}}>
									Change Password
								</Button>
							</Col>
						</Row>

						<AccountDetailsDisplay userDetails={userDetails} />
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSliceState: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundUserSliceActions = bindActionCreators(userSliceActions, dispatch);

	return {
		userSliceActions: boundUserSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountDetailsSection);
