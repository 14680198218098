import { SelectOutlined as SelectOutlinedIcon } from '@ant-design/icons';
import { Button, Card, Col, Row, Statistic, Tag } from 'antd';
import React, { Component } from 'react';
import './PurchasedAppsSummary.css';

export default class OrganizationSummary extends Component {
	render() {
		const {
			purchasedAppsByCompanyWithExtendedData,
			currentUserOrgDetails,
			dashboardSliceActions,
		} = this.props;

		const {
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;

		const noOfPlatformAppsAvailableForLoggedUser = currentUserOrgDetails.AppLicenses
			? currentUserOrgDetails.AppLicenses.length
			: 0;

		return (
			<div className='PurchasedAppsSummary'>
				<Card
					title='Apps Summary'
					bordered={false}
					actions={[
						<div>
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									setCurrentDashboardPageIdAction('YourApps');
								}}>
								Your Apps
							</Button>
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									setCurrentDashboardPageIdAction('PurchaseApps');
								}}>
								Purchase Apps
							</Button>
						</div>,
					]}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							<Statistic
								title='Your Apps'
								value={noOfPlatformAppsAvailableForLoggedUser}
							/>
						</Col>

						<Col span={24}>
							<div style={{ color: 'gray' }}>Apps By Company</div>

							<div className='PurchasedAppsSummary__appListContainer'>
								{Object.entries(purchasedAppsByCompanyWithExtendedData)
									.sort((a, b) => {
										const [companyNameA, appListA] = a;
										const [companyNameB, appListB] = b;

										return companyNameA.localeCompare(companyNameB);
									})
									.map((entry) => {
										const [companyName, appList] = entry;
										return (
											<div className='PurchasedAppsSummary__appListContainer__byCompanySection'>
												<div>
													<Tag style={{ marginBottom: '5px' }} color='geekblue'>
														{companyName}
													</Tag>
												</div>

												<div>
													{appList.map((app) => (
														<img
															src={app.GenericAppDetails.AppImage}
															alt=''
															title={app.AppName}
														/>
													))}
												</div>
											</div>
										);
									})}
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}
