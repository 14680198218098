
import React, { Component } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import Tearms from "./docs/Tearms";
import Privacy from "./docs/Privacy";
import AppList from "./docs/AppList";
export default class WebIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path="/" component={AppList}></Route>
                <Route exact path="/tearms" component={Tearms}></Route>
                <Route exact path="/privacy" component={Privacy}></Route>
            </div>
        )
    }
}