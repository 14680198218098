import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import React, { Component } from 'react';

export default class ReportsSummary extends Component {
	render() {
		return (
			<div className='ReportsSummary'>
				<Card title='Reports Summary' bordered={false}>
					<Row>
						<Col span={12}>
							<Statistic
								title='Active'
								value={11.28}
								precision={2}
								valueStyle={{ color: '#3f8600' }}
								prefix={<ArrowUpOutlined />}
								suffix='%'
							/>{' '}
						</Col>

						<Col span={12}>
							<Statistic
								title='Idle'
								value={9.3}
								precision={2}
								valueStyle={{ color: '#cf1322' }}
								prefix={<ArrowDownOutlined />}
								suffix='%'
							/>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}
