import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getOrganizationByOrgId as getOrganizationByOrgIdAPI,
	getUserOrgDetailsByUserId as getUserOrgDetailsByUserIdAPI,
} from '../../../../../data/api/orgRegistryServerRequests';
import sliceConstants from './dashboardSliceConstants';

// Getting Currently Logged User's Organizations, Companies, Apps, Etc.. he is part of.
// Also we nested fetch more details about user's each organization details and append those details as well.
export const getUserOrgDetailsListWithExtendedData = createAsyncThunk(
	`${sliceConstants.name}/getUserOrgDetailsListWithExtendedData`,
	async (options = {}, thunkAPI) => {
		try {
			// Getting basics of Organizations, Companies, Apps, Etc.. user is part of.
			const userOrgDetailsList = await getUserOrgDetailsByUserIdAPI({
				UserID: sessionStorage.getItem('userID'),
			});

			// Fetching more details (Ex. Like Org Name) of each organization he is part of and appending that data as well.
			const userOrgDetailsListWithExtendedData = await Promise.all(
				userOrgDetailsList.map(async (orgDetailSet) => {
					return await getOrganizationByOrgIdAPI({
						OrganizationID: orgDetailSet.OrganisationID,
					}).then((moreOrgDetails) => {
						return {
							...orgDetailSet,
							OrgDetails: moreOrgDetails, // Appending more OrgDetails to UserOrgDetails.
						};
					});
				}),
			);

			const sortedUserOrgDetailsListWithExtendedData = userOrgDetailsListWithExtendedData.sort(
				(a, b) => a.OrgDetails.Name.localeCompare(b.OrgDetails.Name),
			);

			// NOTE: This session value seems to be used by most of existing components to get currently selected organization. So we leave it be.
			sessionStorage.setItem(
				'orgID',
				sortedUserOrgDetailsListWithExtendedData[0].OrganisationID,
			);

			return sortedUserOrgDetailsListWithExtendedData;
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	getUserOrgDetailsListWithExtendedData,
};

export default extraActions;
