/* eslint-disable no-template-curly-in-string */

import { PlusCircleOutlined } from '@ant-design/icons';
import {
	Button,
	Collapse,
	Form,
	Input,
	message,
	Modal,
	Result,
	Select,
	Spin,
} from 'antd';
import { renderEmail } from 'postonents';
import React, { Component } from 'react';
import { sendEmail } from '../../../../../data/api/emailRegistryServerRequests';
import {
	addUserOrgDetailsSet,
	addUserRole,
	checkUserAlreadyExist,
	getOrganizationByOrgId,
	getUserDetailsByUserId,
	getUserIdByEmail,
	getUserOrgDetailsByUserId,
	updateUserOrgDetailsByUserRegId,
} from '../../../../../data/api/orgRegistryServerRequests';
import { errorLogger } from '../../../../../lib/utilities/errorHandlers';
import { RenderIf } from '../../../../../lib/utilities/reactHelpers';
import AppAssigningNotifyEmail from '../../../../mod/emails/templates/assignUserToApps/AppAssigningNotifyEmail';
import CreatedOrgUserWelcomeEmail from '../../../../mod/emails/templates/assignUserToApps/CreatedOrgUserWelcomeEmail';
import AddNewUserForm from '../forms/AddNewUserForm';

const { Panel } = Collapse;
const { Option } = Select;

const formValidateMessages = {
	required: '${label} is required.',
};

export default class AssignUserToApps extends Component {
	constructor(props) {
		super(props);
		const currentOrganizationId = sessionStorage.getItem('orgID');

		this.state = {
			showAddNewUserModal: false,

			assigneeUserEmail: '', // Email that user enter in initial step. We use this to find UserId, UserDetails, UserOrgDetails, Etc... and Finally add him to certain Locations/Apps.
			assigneeUserId: '', // Will be fetched using 'assigneeUserEmail' as input.
			assigneeUserOrgDetails: '', // Specific UserOrgDetails for Current Organization-->User.

			isAssigneeDetailsFetching: false,
			isAssigneeDetailsFetchingError: '',
			isAssigneeAppDetailsUpdating: false,
			isAssigneeAppDetailsUpdatingError: '',

			currentOrganizationId: currentOrganizationId,
		};

		this.handleAssigneeUserEmailInputChange = this.handleAssigneeUserEmailInputChange.bind(
			this,
		);
		this.getAssigneeUserDetails = this.getAssigneeUserDetails.bind(this);
	}

	handleAssigneeUserEmailInputChange(e) {
		this.setState({
			assigneeUserEmail: e.target.value,
		});
	}

	getCurrentlyLoggedUserDetails() {
		const allUsersOfCurrentOrg = this.props.users;

		const currentLoggedUserId = sessionStorage.getItem('userID');

		const currentlyLoggedUserDetails =
			allUsersOfCurrentOrg.find((userData) => {
				return userData.UserID === currentLoggedUserId;
			}) || {};

		return currentlyLoggedUserDetails;
	}

	checkCurrentLoggedUserIsAdminOfOrg() {
		const currentlyLoggedUserDetails = this.getCurrentlyLoggedUserDetails();

		const isAdminForCurrentOrganization =
			currentlyLoggedUserDetails.IsOrganizationAdmin === true;

		return isAdminForCurrentOrganization;
	}

	// When OrgAdmin create a new user, this is used send that user a welcome email.
	async sendCreatedOrgUserWelcomeEmail(emailData = {}) {
		try {
			const {
				formValues = {}, // This is form values passed form AddNewUserForm which user inputted.
			} = emailData;

			const currentlyLoggedUserDetails = this.getCurrentlyLoggedUserDetails()
				.UserDetails;

			const emailGenData = {
				fullName: `${formValues.firstname} ${formValues.lastname}`,
				username: formValues.email,
				password: formValues.password,
				loginURL: `${window.location.origin}/login/auth/?genericLogin=true`,
				orgAdminName: `${currentlyLoggedUserDetails.FirstName} ${currentlyLoggedUserDetails.LastName}`,
				orgAdminEmail: currentlyLoggedUserDetails.Email,
			};

			const emailContentAsHTML = renderEmail(CreatedOrgUserWelcomeEmail, {
				data: emailGenData,
			});

			return sendEmail({
				ToName: emailGenData.fullName,
				To: emailGenData.username,
				FromName: 'WitMeg',
				From: 'admin@witmeg.com',
				Subject: 'A WitMeg Account Is Created For You',
				HTMLContent: emailContentAsHTML,
			});
		} catch (error) {
			errorLogger(error);
		}
	}

	// When OrgAdmin assign app to user, this is used send that user a notify email about it.
	async sendAppAssigningNotifyEmail(emailData = {}) {
		try {
			const {
				assigneeUserEmail,
				assigneeUserId,
				currentOrganizationId,
			} = this.state;

			const { assignedApps, assignedLocations, assignedCompany } = emailData;

			// Getting User Details which Apps were assigned.
			const assigneeUserDetails = await getUserDetailsByUserId({
				UserID: assigneeUserId,
			});

			// Getting Organization Details which Apps were assigned.
			const currentOrganizationDetails = await getOrganizationByOrgId({
				OrganizationID: currentOrganizationId,
			});

			// Generating App & Location Name list which is displayed in email.
			const assignedAppNameList = assignedApps.map((app) => app.AppName);
			const assignedLocationNameList = assignedLocations.map(
				(location) => location.Name,
			);

			const emailGenData = {
				assignedAppNameList,
				assignedLocationNameList,
				assignedCompany: assignedCompany,
				assignedOrganization: currentOrganizationDetails.Name,
				fullName: `${assigneeUserDetails.FirstName} ${assigneeUserDetails.LastName}`,
			};

			const emailContentAsHTML = renderEmail(AppAssigningNotifyEmail, {
				data: emailGenData,
			});

			return await sendEmail({
				ToName: emailGenData.fullName,
				To: assigneeUserEmail,
				FromName: 'WitMeg',
				From: 'admin@witmeg.com',
				Subject: 'WitMeg - Apps Are Assigned For You.',
				HTMLContent: emailContentAsHTML,
			});
		} catch (error) {
			errorLogger(error);
		}
	}

	// Check inputted email already exist. According to that fetch further necessary details OR prompt to create new user.
	async getAssigneeUserDetails() {
		try {
			const { currentOrganizationId, assigneeUserEmail } = this.state;

			if (!assigneeUserEmail) {
				return;
			}

			// Starting Loader Animations.
			this.setState({
				isAssigneeDetailsFetching: true,
				isAssigneeDetailsFetchingError: '',
			});

			const isUserAlreadyExist = await checkUserAlreadyExist({
				Email: assigneeUserEmail,
			});

			// User available on the database. Fetch his UserOrgDetails.
			if (isUserAlreadyExist) {
				const userId = await getUserIdByEmail({
					Email: this.state.assigneeUserEmail,
				});

				// Get all UserOrgDetails available for this user.
				const allUserOrgDetails = await getUserOrgDetailsByUserId({
					UserID: userId,
				});

				// Extracting only currently selected Organization's UserOrgDetails.
				const currentOrg__userOrgDetails =
					allUserOrgDetails.find((orgData) => {
						return orgData.OrganisationID === currentOrganizationId;
					}) || {};

				this.setState({
					assigneeUserId: userId,
					assigneeUserOrgDetails: currentOrg__userOrgDetails,
					isAssigneeDetailsFetching: false,
				});
			}
			// User not available on the Database. Prompt user if new user need to be added.
			else {
				Modal.confirm({
					title: 'User Not Available',
					content:
						'A user with this email is not available. Do you want to create a new user?',
					onOk: () => {
						this.setState({
							showAddNewUserModal: true,
							isAssigneeDetailsFetching: false,
						});
					},
					onCancel: () => {
						this.setState({
							isAssigneeDetailsFetching: false,
						});
					},
				});
			}
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			message.error('Error occurred while checking user.');

			this.setState({
				isAssigneeDetailsFetching: false,
				isAssigneeDetailsFetchingError: errMsg,
			});
		}
	}

	// Assigning/Updating selected Apps/Location data of assignee.
	async updateAssigneeAppDetails(formValues, currentCompanyDetails) {
		try {
			const {
				refetchParentCompInitialData,
				apps: allAppsOfCurrentOrg,
			} = this.props;
			let {
				Apps: selectedAppIds = [],
				Locations: selectedLocationIds = [],
			} = formValues;

			const {
				assigneeUserId,
				assigneeUserOrgDetails,
				currentOrganizationId,
			} = this.state;

			this.setState({
				isAssigneeAppDetailsUpdating: true,
				isAssigneeAppDetailsUpdatingError: '',
			});

			const hideAssigningLoaderMsg = message.info('Assigning', 0);

			// Get full App Details for each selected app.
			// NOTE : What we receive in "selectedAppIds" is "AppLicenseID" (Generic Universal Platform App ID). Ex. [2, 5, 6]
			const selectedApps = selectedAppIds.map((AppLicenseID) => {
				return allAppsOfCurrentOrg.find(
					(app) =>
						app.AppLicenseID === AppLicenseID &&
						app.CompanyID === currentCompanyDetails.CompanyID,
				);
			});

			// Get full Location Details for each selected Location.
			// NOTE : What we receive in "selectedLocationIds" is "LocationID" only.
			const selectedLocations = selectedLocationIds.map((LocationID) => {
				return currentCompanyDetails.LocationDetails.find(
					(location) => location.LocationID === LocationID,
				);
			});

			// Checking existing UserOrgDetails available for the user we are trying to assign.
			const userRegistrationId = assigneeUserOrgDetails.UserRegistrationID;
			const isExistingUserOrgDetailsAvailable = Boolean(userRegistrationId);

			// **** CASE 1 : Existing UserOrgDetails for Current Organization NOT Available. (In Assignee UserOrgDetails Set) So we are adding one here.
			if (!isExistingUserOrgDetailsAvailable) {
				// NOTE 1 : Since existing UserOrgDetails not available. We have to create new UserOrgDetails Set. But for that we need a "UserRoleID". So we are creating one here.
				// NOTE 2 : Should this UserRole generation done for each app selected?? Currently It doesn't seems so. 🤔
				const OrgAppID = selectedApps[0].OrgAppID;
				const UserRoleID = await addUserRole({
					OrganisationID: currentOrganizationId,
					Name: 'Standard',
					OrgAppID: OrgAppID,
					PermissionSetnValue: [
						{ Name: 'Sales', Value: 1 },
						{ Name: 'Refund', Value: 0 },
					],
				});

				// Adding new UserOrgDetails Set.
				// Notable things added here are "UserRoleIDs, Companies, Companies-->Locations, AppLicence"
				await addUserOrgDetailsSet({
					OrganisationID: currentOrganizationId,
					UserID: assigneeUserId,
					UserRoleIDs: [UserRoleID],
					Companies: [
						{
							CompanyID: currentCompanyDetails.CompanyID,
							IsDefaultCompany: true,
							DefaultLocationID: selectedLocationIds[0],
							LocationIDs: selectedLocationIds, // All Selected LocationIDs
						},
					],
					AppLicenses: selectedApps.map((app) => {
						// Adding AppLicence for Each Selected Apps.
						return {
							AppLicenseID: app.AppLicenseID,
							Status: 'Active',
						};
					}),
				});
			}
			// **** CASE 2 : Existing UserOrgDetails for Current Organization Available. (In Assignee UserOrgDetails Set). So we only need to update it.
			else {
				const updateDetails = {
					// Setting up base keys. Later more values added for these keys as necessary.
					...assigneeUserOrgDetails,
					UserRegistrationID: userRegistrationId,
					Companies: [...assigneeUserOrgDetails.Companies],
					AppLicenses: [...assigneeUserOrgDetails.AppLicenses],
				};

				// Generating App Licence List (For each selected app) that match existing structure.
				const selectedAppLicencees = selectedApps.map((app) => {
					return {
						AppLicenseID: app.AppLicenseID,
						Status: 'Active',
					};
				});

				// Appending Licence if it already not available in Assignee UserOrgDetails.
				selectedAppLicencees.forEach((appLicence) => {
					const isAppLicenceAlreadyAvailable = updateDetails.AppLicenses.some(
						(app) => app.AppLicenseID === appLicence.AppLicenseID,
					);

					if (!isAppLicenceAlreadyAvailable) {
						updateDetails.AppLicenses.push(appLicence);
					}
				});

				// Checking entry for "Current Selected Company" already available in Assignee UserOrgDetails.
				// If available we can append data into it. Other wise we have to create entire entry.
				const existingCompanyDataIndex = updateDetails.Companies.findIndex(
					(com) => com.CompanyID === currentCompanyDetails.CompanyID,
				);

				// ********* SUB CASE 1 : Entry for "Current Selected Company" already available.
				if (existingCompanyDataIndex >= 0) {
					// Create full LocationIds List. (Existing + Newly Selected)
					const locationIDs = [
						...updateDetails.Companies[existingCompanyDataIndex].LocationIDs,
						...formValues.Locations,
					];

					// Appending new LocationIds to appropriate company key.
					updateDetails.Companies[existingCompanyDataIndex] = {
						...updateDetails.Companies[existingCompanyDataIndex],
						LocationIDs: [...new Set(locationIDs)], // Notice, We are removing duplicates.
					};
				}
				// ********* SUB CASE 2 : Entry for "Current Selected Company" already NOT available.
				else {
					// Creating and pushing new company section (With selected locations) since its not available.
					updateDetails.Companies.push({
						CompanyID: currentCompanyDetails.CompanyID,
						IsDefaultCompany: true,
						DefaultLocationID: selectedLocationIds[0],
						LocationIDs: [...selectedLocationIds],
					});
				}

				// Finally Updating Details with Server. (Assigning Apps/Locations)
				await updateUserOrgDetailsByUserRegId(updateDetails);
			}

			// When updating UserOrgDetails finished, This is used to refetch all data in parent component. (Which we rely on this component). So updated details shown in all components.
			await refetchParentCompInitialData();

			// Sending Email to Assigned User notifying he is assigned to these apps in this company.
			await this.sendAppAssigningNotifyEmail({
				assignedApps: selectedApps,
				assignedLocations: selectedLocations,
				assignedCompany: currentCompanyDetails.Name,
			});

			hideAssigningLoaderMsg();

			Modal.info({
				title: 'App Assigned',
				content: 'Selected Apps are successfully assigned to the user.',
			});

			// Resetting state, So user will go to initial first view.
			this.setState({
				assigneeUserEmail: '',
				assigneeUserOrgDetails: '',
				isAssigneeAppDetailsUpdating: false,
			});
		} catch (error) {
			Modal.error({
				title: 'App Assigned Failed',
				content: 'Some error occurred while assigning apps.',
			});

			this.setState({
				assigneeUserEmail: '',
				assigneeUserOrgDetails: '',
				isAssigneeAppDetailsUpdating: false,
				isAssigneeAppDetailsUpdatingError: error,
			});
		}
	}

	render() {
		const {
			loaded: isUserCompanyDetailsFetched,
			aloaded: isOrgAppDetailsFetched,
			companies: currentOrgCompanies,
			apps: currentOrgApps,
		} = this.props;
		const {
			assigneeUserEmail,
			assigneeUserOrgDetails,

			isAssigneeDetailsFetching,
			isAssigneeDetailsFetchingError,
			isAssigneeAppDetailsUpdating,

			showAddNewUserModal,
		} = this.state;

		const isAnyMainActionsRunning =
			!isUserCompanyDetailsFetched ||
			!isOrgAppDetailsFetched ||
			isAssigneeDetailsFetching ||
			isAssigneeAppDetailsUpdating;
		const isAnyMainActionsError = isAssigneeDetailsFetchingError;
		const isInitialDataFetched = Boolean(assigneeUserOrgDetails);

		// NOTE : Only the "Organization Admin" of current Organization can assign users to it.
		const isCurrentlyLoggedUserAdminOfOrg = this.checkCurrentLoggedUserIsAdminOfOrg();

		if (isAnyMainActionsRunning) {
			return (
				<div className='AssignUserToApps GC-UTL-flexSuperCenter'>
					<Spin />
				</div>
			);
		}

		if (isAnyMainActionsError) {
			return (
				<div className='AssignUserToApps GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() =>
									this.setState({
										isAssigneeDetailsFetchingError: '',
									})
								}>
								Cancel
							</Button>,
						]}
					/>
				</div>
			);
		}

		return (
			<div className='AssignUserToApps GC-UTL-fullFlexHeight'>
				<Modal
					visible={showAddNewUserModal}
					footer={null}
					onCancel={() => {
						this.setState({ showAddNewUserModal: false });
					}}>
					<h1>Add New User</h1>

					<AddNewUserForm
						formsPreFillData={{
							email: assigneeUserEmail,
						}}
						shouldResetForm={!showAddNewUserModal} // Used to clear form values when modal closing.
						onDoneFn={async (valuesFromAddNewUserModal) => {
							const {
								Email,
								isSuccess,
								formValues,
							} = valuesFromAddNewUserModal;

							// If User creation success close this modal and show AssignApps View.
							this.setState(
								{
									showAddNewUserModal: false,
									assigneeUserEmail: Email,
								},
								() => {
									if (isSuccess) {
										this.getAssigneeUserDetails();
									}
								},
							);

							await this.sendCreatedOrgUserWelcomeEmail({
								formValues,
							});
						}}
					/>
				</Modal>

				{/* When "Currently Logged User" is NOT the admin of current organization. */}
				{!isCurrentlyLoggedUserAdminOfOrg && (
					<div className='GC-UTL-flexSuperCenter'>
						<Result
							status='warning'
							subTitle="Sorry. You are not the Admin of this organization. Therefore don't have necessary access to assign apps to this organization."
						/>
					</div>
				)}

				{/* Below are only rendered if "Currently Logged User" is the admin of current organization. */}
				<RenderIf
					condition={isCurrentlyLoggedUserAdminOfOrg}
					className='GC-UTL-fullFlexHeight'>
					{/* When UserEmail is not entered & it's UserDetails are not fetched, We show FindUser View.
						So user can enter email he wishes to assign apps and processd. */}
					{!isInitialDataFetched && (
						<div className='GC-UTL-flexSuperCenter'>
							<Result
								subTitle='Please enter the existing User Email or create new user to assign Apps.'
								extra={
									<div>
										<Form
											validateMessages={formValidateMessages}
											onFinish={() => {
												this.getAssigneeUserDetails();
											}}>
											<Form.Item
												required
												name='assigneeUserEmail'
												value={assigneeUserEmail}
												rules={[
													{
														required: true,
														message: 'User Email is Required.',
													},
												]}
												style={{
													margin: '0 auto',
													maxWidth: '500px',
													paddingBottom: '15px',
												}}
												onChange={this.handleAssigneeUserEmailInputChange}>
												<Input type='email' placeholder='User Email' />
											</Form.Item>

											<Form.Item>
												<Button type='primary' htmlType='submit'>
													FIND USER
												</Button>
											</Form.Item>

											<Form.Item>
												<Button
													onClick={() => {
														this.setState({
															showAddNewUserModal: true,
														});
													}}>
													<PlusCircleOutlined />
													<span>ADD NEW USER</span>
												</Button>
											</Form.Item>
										</Form>
									</div>
								}
							/>
						</div>
					)}

					{/* When UserEmail is Entered and It's details are fetched. Show AssignApps View. So user can assign apps to this user.*/}
					{!isAnyMainActionsRunning && isInitialDataFetched && (
						<div>
							<div>
								<span>
									Please select the Locations and Apps you want to assign for{' '}
									<strong>{assigneeUserEmail}</strong> in relevant Company
									Section.
								</span>

								<Button
									onClick={() => {
										this.setState({
											assigneeUserEmail: '',
											assigneeUserOrgDetails: '',
										});
									}}>
									Cancel
								</Button>
							</div>

							<br />

							<Collapse defaultActiveKey={['0']}>
								{currentOrgCompanies.map((company, index) => {
									return (
										<Panel
											header={
												company.IsDefaultCompany
													? company.CompanyDetails.Name + ' - Default company'
													: company.CompanyDetails.Name
											}
											key={index}>
											<Form
												name='register'
												scrollToFirstError
												validateMessages={formValidateMessages}
												onFinish={(values) =>
													this.updateAssigneeAppDetails(values, company)
												}
												style={{
													paddingBottom: '20px',
												}}>
												<Form.Item
													name='Locations'
													label='Locations'
													required
													rules={[
														{
															required: true,
														},
													]}>
													<Select
														mode='tags'
														placeholder='Select Locations to Add'>
														{company.LocationDetails.map((location) => (
															<Option key={location.LocationID}>
																{location.Name}
															</Option>
														))}
													</Select>
												</Form.Item>

												<Form.Item
													name='Apps'
													label='Apps'
													rules={[
														{
															required: true,
														},
													]}>
													<Select mode='tags' placeholder='Select Apps To Add'>
														{currentOrgApps
															.filter(
																(app) => app.CompanyID === company.CompanyID,
															)
															.map((app) => {
																return (
																	<Option key={app.AppLicenseID}>
																		{app.AppName}
																	</Option>
																);
															})}
													</Select>
												</Form.Item>

												<Form.Item>
													<Button type='primary' htmlType='submit'>
														Assign Apps
													</Button>
												</Form.Item>
											</Form>
										</Panel>
									);
								})}
							</Collapse>
						</div>
					)}
				</RenderIf>
			</div>
		);
	}
}
