import {
	ClockCircleOutlined as ClockCircleOutlinedIcon,
	HomeOutlined,
} from '@ant-design/icons';
import { Breadcrumb, PageHeader } from 'antd';
import React from 'react';

export default class ReportsSection extends React.Component {
	render() {
		return (
			<div className='ReportsSection GC-UTL-fullFlexHeight'>
				<Breadcrumb>
					<Breadcrumb.Item href=''>
						<HomeOutlined />
					</Breadcrumb.Item>
					<Breadcrumb.Item>Reports</Breadcrumb.Item>
				</Breadcrumb>

				<PageHeader
					className='site-page-header'
					title='Reports'
					subTitle='Find Your Detailed Reports Here.'
				/>

				<div
					className='GC-UTL-flexSuperCenter'
					style={{
						fontSize: '30px',
						textAlign: 'center',
						color: 'gray',
					}}>
					<div>
						<div>
							<ClockCircleOutlinedIcon />
						</div>
						<div>Coming Soon</div>
					</div>
				</div>
			</div>
		);
	}
}
