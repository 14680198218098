import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid,Link, Button, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from "@material-ui/core";
import { Settings as SettingsIcon, Delete as DeleteIcon, DragHandle as DragHandleIcon } from "@material-ui/icons";
import { Row, Col, Card, Form } from "bootstrap-4-react";
import { Alert } from "@material-ui/lab";
import Cookies from 'js-cookie';
import { Field, reduxForm, change } from "redux-form";
import { orgRequest } from "../../data/api/OrgApi"
import { getJWT } from './../../lib/global/helpers';
import { renderTextBox, renderCheckBox, RenderPassword, renderHidden } from "./../../lib/global/helpers";
import Paper from '@material-ui/core/Paper';
import { PageHeader, Modal } from 'antd';


const jwt = require("jsonwebtoken");

const styles = theme => ({
    form: {
        [theme.breakpoints.only("xs")]: {
            padding: "20px 40px !important"
        }
    }
});
let appjwt = Cookies.get("AppToken");
let formName = "loginVerification";
let appID = "";

class Login extends Component {
    state = {
        errorMessage: "",
        appLoaded: false,
        appSelectType:"",
        appid: "",
        apps: [],
        buttonText: "Login now"
    };

    constructor(props) {
        super(props);
        appID = this.props.match.params.appid;
        const params = new URLSearchParams(props.location.search);
        this.email = params.get('email');
    }

    static getDerivedStateFromProps(props, state){
        // Setting 'appSelectType' through depending on some query parameters. (If passed)
        // This is mostly used to handle cases where top most 'Login' and 'Register' buttons behaviors available in both <Login> and <Register> Component.
        const params = new URLSearchParams(props.location.search);
        const isGenericLogin = Boolean(params.get('genericLogin'));
        const isRegistrationSelect = Boolean(params.get('registrationSelect'));
        let preAppSelectTypeValue = state.appSelectType;
        let preAppLoadedValue = state.appLoaded;

        if (isGenericLogin) {
            preAppSelectTypeValue = 'login';
            preAppLoadedValue = true; // So LoginForm is rendered.
        }
        if (isRegistrationSelect) {
            preAppSelectTypeValue = 'register';
            preAppLoadedValue = false; // So AppList is rendered.
        }

        return {
            appSelectType:preAppSelectTypeValue,
            appLoaded: preAppLoadedValue,
        }
    }

    componentDidMount() {
        // console.log(appjwt)
        const jwt = getJWT();
        let invalid = false;
        this.setState({ appid: appID });
        if (appID == undefined) {

            const requestOptions2 = {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + appjwt,
                }
            };

            fetch(process.env.REACT_APP_AppRegistryApi+'v1/myapp', requestOptions2)
                .then(response => response.json())
                .then(data => this.onSetData(data) )
                .catch((error) => {
                    window.location.href="/login/auth"
                }) ;
        }
        else {
            this.setState({ appLoaded: true });
            if (this.email != null) this.props.dispatch(change(formName, "Username", this.email));
            this.props.dispatch(change(formName, "AppID", this.state.appid));
        }

    }

    onSetData = (result) => {
        // console.log('xxx')
        this.setState({ apps: result.Result })
    }
    handleAppSelect = (value, appname) => {
        sessionStorage.setItem('appName', appname);

    if(this.state.appSelectType=='register'){
        this.props.history.push("/login/register/" + value);
    }else{
        this.props.history.push("/login/auth/" + value);
    }
        this.setState({ appLoaded: true, appid: value });
    };

    handleSelectApp = (type) => {
            if (type === 'login') {
                this.props.history.push('/login/auth/?genericLogin=true');
            } else if (type  === 'register') {
                Modal.error({
                    title: 'Please select an application',
                    content: 'Please select an application to ' + type,
                    onOk: () => {
                        this.props.history.push(
                            '/login/auth?registrationSelect=true'
                        );
                        sessionStorage.setItem('appName', '');
                    },
                });
            }
    }

    onSubmit = (values) => {
        let userID = "";
        let userResponse = undefined;
        let user = undefined;
        var accessDec = undefined;
        var refreshDec = undefined;

        this.setState({ buttonText: "Please wait..." });

        orgRequest("login", values)
            .then(response => {
                if (response.data.Status)
                    userResponse = response.data.Result;
                else throw new Error("Authantication Failed");
            })
            .then(() => {
                try {

                    accessDec = jwt.decode(userResponse.AccessToken);
                    refreshDec = jwt.decode(userResponse.RefreshToken);
                    userID = accessDec.userid;
                    Cookies.set('infinity', userResponse.AccessToken, { expires: new Date(accessDec.exp * 1000) });
                    Cookies.set('embose', userResponse.RefreshToken, { expires: new Date(refreshDec.exp * 1000) });
                    sessionStorage.setItem('appid', this.state.appid);
                    sessionStorage.setItem('loggedin', true);
                    sessionStorage.setItem('userID', userID);
                    //console.log(userResponse)


                    setTimeout(() => {
                        window.location.href="/dashboard/" + sessionStorage.getItem('appid')
                      }, 100);

                } catch (err) {
                    window.location.href="/login/auth"
                }
            })

            .catch((error) => {
                this.props.dispatch(this.props.reset('loginVerification'));
                this.setState({ errorMessage: "Authantication Failed" });
                this.setState({ buttonText: "Login now" });
            });
    }


    render() {
        const { classes, handleSubmit, pristine, submitting, invalid } = this.props;
        return (

            <Grid>

<div className="site-page-header-ghost-wrapper">
    <PageHeader
      ghost={false}
      avatar={{ src: '/inc/img/witmeg.png' }}
      title=""
      subTitle="All-In-One Dashboard"
      extra={[
        <Button key="3" onClick={this.handleSelectApp.bind(null, 'login')}>Login</Button>,
        <Button key="2" onClick={this.handleSelectApp.bind(null, 'register')}>Register</Button>,

      ]}
    >

    </PageHeader>
  </div>
                <Grid className={this.state.appLoaded == true ? 'all-wrapper menu-side with-pattern' : ''}>
                    <Grid className={this.state.appLoaded == true ? 'auth-box-w' : ''}>


                        {this.state.appLoaded &&
                            <Grid>
                                <Grid className="logo-w">
                                    <Link href="/">
                                        <img alt="" src="inc/img/logo-big.png" />
                                    </Link>
                                </Grid>
                                <h4 className="auth-header">Login { this.state.appSelectType === 'login' ?  '' : 'to ' + sessionStorage.getItem('appName')}</h4>
                                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className={classes.form}>
                                    <Field
                                        name="AppID"
                                        type="hidden"
                                        required="required"
                                        id="txtAppID"
                                        component={renderHidden}
                                    />
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="Username"
                                                type="text"
                                                required="required"
                                                id="txtUsername"
                                                label="Username"
                                                placeholder="Enter Username"
                                                component={renderTextBox}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="Password"
                                                type="text"
                                                required="required"
                                                id="txtPassword"
                                                label="Password"
                                                placeholder="Enter Password"
                                                component={RenderPassword}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Field
                                                name="remember"
                                                id="chkRememberMe"
                                                label="Remember me next time"
                                                component={renderCheckBox}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col="sm-12">
                                            <Button disabled={submitting || pristine || invalid} variant="contained" color="primary" type="submit" className="w-100">
                                                {this.state.buttonText}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row mt={2}>
                                        <Col col="sm-6">
                                            <Link href={"/login/forgot/" } color="primary">
                                                <small>{"Forgot password?"}</small>
                                            </Link>
                                        </Col>
                                        <Col col="sm-6" className="text-right">
                                            <Link href={this.state.appSelectType==='login' ? '/login/register/' : "/login/register/" + this.state.appid} color="primary">
                                                <small>{"I'm a new user"}</small>
                                            </Link>
                                        </Col>
                                    </Row>

                                    {this.state.errorMessage &&
                                        (
                                            <Row mt={2}>
                                                <Col col="sm-12">
                                                    <Alert severity="error">{this.state.errorMessage}</Alert>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Form>
                            </Grid>
                        }

                        {!this.state.appLoaded &&
                            <Grid className="p-4">
                                   {
                                    <>



                                        <div className={classes.root}>
                                            <Grid container spacing={3}>

                                                {this.state.apps.map((e, i) => (
                                                    <>
                                                        <Grid item xs={3} key={i}>
                                                            <Paper className={classes.paper}>
                                                                <ListItem key={i}>
                                                                    <IconButton edge="end" className="MuiIcon" aria-label="delete" onClick={this.handleAppSelect.bind(null, e.ApplicationID, e.Name)}>
                                                                        <img src={e.AppImage != '' ? e.AppImage : '/inc/img/notfound.png'} border="0" />

                                                                    </IconButton>

                                                                    <ListItemText primary={e.Name} secondary={<small>{e.AboutApp}</small>} />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete" onClick={this.handleAppSelect.bind(null, e.ApplicationID, e.Name)}>
                                                                            <DragHandleIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>

                                                            </Paper>
                                                        </Grid>

                                                    </>
                                                ))}

                                            </Grid>
                                        </div>


                                    </>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid >
        );
    }
}

const validate = values => {
    const warnings = {};
    if (!values.username) { warnings.username = "Username is Required"; }
    if (!values.password) { warnings.password = "Password is Required"; }
    return warnings;
};

export default reduxForm({
    initialValues: { type: "login", },
    enableReinitialize: true,
    form: formName,
    validate: validate
})(withStyles(styles)(Login));
