import { axiosCall } from "../../lib/api/apiCall";
import { Config } from "../../lib/global/Config";
import { getJWT, generateAppRegJwt } from "../../lib/global/helpers";
import Cookies from "js-cookie";

export function appRequest(api, data = {}) {
    let method = "post";
    let url = "";
    let config = {};
    let appjwt = Cookies.get("AppToken");

    let useAccessToken = false;
    let useJWToken = false;

    switch (api) {
        case "retrieveAll":
            method = "get";
            url = "v1/apps";
            data = {};
            useJWToken = true;
            break;

            
        case "retrieveAllPost":
            method = "POST";
            url = "v1/myapp";
            data = {};

            useJWToken = true;
            break;

        case "retrieve":
            method = "get";
            url = "v1/app/" + data.id;
            data = {};

            useJWToken = true;
            break;
        default:
            break;
    }

    let token = undefined;

    if (useAccessToken) {
        let JWT = getJWT();
        if (JWT.token != null) {
            let tk = generateAppRegJwt(JWT.token);
            token = tk.token;
        }
    } else if (useJWToken) token = appjwt;


    url = Config.AppRegistryApi + url;

    return axiosCall(method, url, config, data, token);
}
