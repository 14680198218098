import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, PageHeader, Tabs } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import './ManageUsersSection.css';
import AssignUserToApps from './subSections/AssignUserToApps';
import UsersList from './subSections/UsersList';

const { TabPane } = Tabs;
const jwt = require('jsonwebtoken');
let usertoken = Cookies.get('infinity');

class ManageUsersSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			aloaded: false,
			loaded: false,
			users: [],
			companies: [],
			apps: [],
		};
	}

	GetUserDetails = async (UserDetails) => {
		const expireTime =
			new Date().getTime() + process.env.REACT_APP_OrgjwtExp * 1000;

		var payload = {
			aud: process.env.REACT_APP_OrgjwtAud,
			exp: expireTime,
			iss: process.env.REACT_APP_OrgjwtIss,
			usertoken: usertoken,
		};

		var signOptions = {
			algorithm: 'RS256',
		};

		var privateKey = process.env.REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n');
		var token = jwt.sign(payload, privateKey, signOptions);

		const result = [];

		const requestOptions2 = {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ` + token,
			},
		};

		console.log(UserDetails);

		return await fetch(
			process.env.REACT_APP_OrgRegistryApi + 'user/' + UserDetails.UserID,
			requestOptions2,
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				result.push({ ...UserDetails, UserDetails: data.Result });
				return result[0];
			})
			.catch((error) => {
				//window.location.href="/login/logout"
			});
	};
	changeTab = (activeKey) => {
		const { manageUsersSectionSliceActions } = this.props;
		const {
			setManageUsersSectionCurrentTabId: setManageUsersSectionCurrentTabIdAction,
		} = manageUsersSectionSliceActions;

		setManageUsersSectionCurrentTabIdAction(activeKey);
	};

	GetLocationDetails = async (CompanyDetails, companyD) => {
		//console.log('CompanyDetails')
		//console.log(companyD)
		//console.log('CompanyDetails')

		const expireTime =
			new Date().getTime() + process.env.REACT_APP_OrgjwtExp * 1000;
		var payload = {
			aud: process.env.REACT_APP_OrgjwtAud,
			exp: expireTime,
			iss: process.env.REACT_APP_OrgjwtIss,
			usertoken: usertoken,
		};

		var signOptions = {
			algorithm: 'RS256',
		};

		var privateKey = process.env.REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n');
		var token = jwt.sign(payload, privateKey, signOptions);

		const result = [];

		const requestOptions2 = {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ` + token,
			},
		};

		return await fetch(
			process.env.REACT_APP_OrgRegistryApi +
				'location/company/' +
				companyD.Result.CompanyID,
			requestOptions2,
		)
			.then((response) => response.json())
			.then(async (data) => {
				//  console.log(data)
				result.push(...data.Result);
				return result;
			})
			.catch((error) => {
				//window.location.href="/login/logout"
			});
	};

	GetCompanyDetails = async (CompanyDetails) => {
		const expireTime =
			new Date().getTime() + process.env.REACT_APP_OrgjwtExp * 1000;
		var payload = {
			aud: process.env.REACT_APP_OrgjwtAud,
			exp: expireTime,
			iss: process.env.REACT_APP_OrgjwtIss,
			usertoken: usertoken,
		};

		var signOptions = {
			algorithm: 'RS256',
		};

		var privateKey = process.env.REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n');
		var token = jwt.sign(payload, privateKey, signOptions);

		const result = [];
		const result1 = [];

		const requestOptions2 = {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ` + token,
			},
		};

		//   console.log('CompanyDetails')
		//  console.log(CompanyDetails)
		//  console.log('CompanyDetails')

		return await fetch(
			process.env.REACT_APP_OrgRegistryApi +
				'company/' +
				CompanyDetails.CompanyID,
			requestOptions2,
		)
			.then((response) => response.json())
			.then(async (data) => {
				//  console.log('data')
				//  console.log(data)
				//  console.log('data')

				result1.push(data.Result[0]);

				const listofcompanies = await Promise.all(
					result1.map(
						async (CompanyDetails) =>
							await this.GetLocationDetails(CompanyDetails, data),
					),
				);

				result.push({
					...CompanyDetails,
					CompanyDetails: data.Result,
					LocationDetails: listofcompanies[0],
				});

				// console.log('result[0]')
				//  console.log(result[0])
				//  console.log('result[0]')
				//this.setState({ offerproducts: listofproducts, ploaded: true })

				// this.setState({ offerproducts: listofproducts, ploaded: true })

				return result[0];
			});
	};

	async fetchInitialData() {
		const expireTime =
			new Date().getTime() + process.env.REACT_APP_OrgjwtExp * 1000;

		var payload = {
			aud: process.env.REACT_APP_OrgjwtAud,
			exp: expireTime,
			iss: process.env.REACT_APP_OrgjwtIss,
			usertoken: usertoken,
		};

		var signOptions = {
			algorithm: 'RS256',
		};

		var privateKey = process.env.REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n');
		var token = jwt.sign(payload, privateKey, signOptions);

		const requestOptions = {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ` + token,
			},
		};
		//console.log(data)
		fetch(
			process.env.REACT_APP_OrgRegistryApi +
				'registeredusers/' +
				sessionStorage.getItem('orgID'),
			requestOptions,
		)
			.then((oresponse) => oresponse.json())
			.then(async (odata) => {
				console.log(odata);
				const users = await Promise.all(
					odata.Result.map(
						async (UserID) => await this.GetUserDetails(UserID, odata.Result),
					),
				);

				this.setState({ users: users });

				console.log(users);
			});

		const requestOptions2 = {
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ` + token,
			},
		};

		// console.log(requestOptions2)

		fetch(
			process.env.REACT_APP_OrgRegistryApi +
				'companies/' +
				sessionStorage.getItem('orgID'),
			requestOptions2,
		)
			.then((orgresponse) => orgresponse.json())
			.then(async (dataorg) => {
				//  console.log('dataorg')
				// console.log(dataorg)
				// console.log('dataorg')
				const listofcompanies = await Promise.all(
					dataorg.Result.map(
						async (CompanyID) => await this.GetCompanyDetails(CompanyID),
					),
				);

				//   console.log('listofcompanies')
				console.log(listofcompanies);
				//   console.log('listofcompanies')
				this.setState({ companies: listofcompanies, loaded: true });
			});

		fetch(
			process.env.REACT_APP_OrgRegistryApi +
				'orgapps/' +
				sessionStorage.getItem('orgID'),
			requestOptions2,
		)
			.then((response3) => response3.json())
			.then((data3) => {
				console.log('data3');
				console.log(data3);
				console.log('data3');
				this.setState({ apps: data3.Result, aloaded: true });
			});
	}

	async componentDidMount() {
		this.fetchInitialData();
	}

	render() {
		const {
			manageUsersSectionSliceState,
			manageUsersSectionSliceActions,
		} = this.props;
		const { currentManageUsersSectionTabId } = manageUsersSectionSliceState;

		return (
			<div className='ManageUsersSection GC-UTL-fullFlexHeight'>
				<Breadcrumb>
					<Breadcrumb.Item href=''>
						<HomeOutlined />
					</Breadcrumb.Item>
					<Breadcrumb.Item>Manage Users</Breadcrumb.Item>
				</Breadcrumb>

				<PageHeader
					className='site-page-header'
					title='Manage Users'
					subTitle='Manage Your Organization Users Here.'
				/>

				<div className='ManageUsersSection__tabSet'>
					<Tabs
						activeKey={currentManageUsersSectionTabId}
						onChange={this.changeTab}>
						<TabPane tab='Users' key='1'>
							<UsersList
								loaded={this.state.loaded}
								users={this.state.users}
								manageUsersSectionSliceActions={manageUsersSectionSliceActions}
							/>
						</TabPane>

						<TabPane tab='Assign Apps' key='2'>
							<AssignUserToApps
								loaded={this.state.loaded}
								aloaded={this.state.aloaded}
								companies={this.state.companies}
								apps={this.state.apps}
								users={this.state.users}
								// Temporary Solution - For now, This is used to refetch all data in here, once child component did some relevant update.
								// So updated details shown in all components.
								refetchParentCompInitialData={() => this.fetchInitialData()}
								manageUsersSectionSliceActions={manageUsersSectionSliceActions}
							/>
						</TabPane>

						{/* <TabPane tab='Add New User' key='3'>
								<AddNewUserForm />
							</TabPane> */}
					</Tabs>
				</div>
			</div>
		);
	}
}

export default ManageUsersSection;
