/* eslint-disable no-template-curly-in-string */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import React from 'react';
import { updateOrganizationDetails } from '../../../../../../data/api/orgRegistryServerRequests';
import { countryList } from '../../../../../../lib/utilities/helperValues';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: { span: 24 },
};

const formValidateMessages = {
	required: '${label} is required.',
	types: {
		email: '${label} is not a valid email.',
	},
};

export default class EditOrganizationDetailsForm extends React.Component {
	formRef = React.createRef();

	async handleFormSubmit(values) {
		const { currentlySelectedOrgDetails } = this.props;

		const {
			onDoneFn = () => {}, // If parent component passes this, parent component can use this to do something after user being updated.
		} = this.props;

		try {
			if (values.Addresses === undefined) {
				Modal.error({
					title: 'Address Required',
					content: 'Please add at least one address for the Organization.',
					onOk: () => {},
				});
				return;
			}

			const reqBody = {
				...currentlySelectedOrgDetails, // Existing Details
				Name: values.OrganizationName,
				Addresses: values.Addresses,
			};

			const hideMsg = message.loading(
				{ content: 'Updating Organization...' },
				0,
			);

			await updateOrganizationDetails(reqBody);

			hideMsg();
			onDoneFn({ isSuccess: true, formValues: values });

			Modal.success({
				title: 'Organization Updated',
				content: 'Organization details has been successfully updated.',
				onOk() {},
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Sorry, Some error occurred while updating the Organization.';

			onDoneFn({
				isSuccess: false,
				formValues: values,
			});

			Modal.error({
				title: 'Organization Not Updated',
				content: errMsg,
				onOk: () => {},
			});
		}
	}

	componentDidUpdate(prevProps) {
		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				this.formRef.current && this.formRef.current.resetFields();
			}
		}
	}

	render() {
		const { currentlySelectedOrgDetails } = this.props;

		const formInitialValues = {
			OrganizationName: currentlySelectedOrgDetails.Name,
			Addresses: currentlySelectedOrgDetails.Addresses || [],
		};

		return (
			<div className='EditOrganizationDetailsForm'>
				<Form
					{...formItemLayout}
					name='EditOrganizationDetailsForm__Form'
					onFinish={(formValues) => this.handleFormSubmit(formValues)}
					initialValues={formInitialValues}
					validateMessages={formValidateMessages}
					scrollToFirstError
					ref={this.formRef}>
					<Form.Item
						name='OrganizationName'
						label='Organization Name'
						initialValue={currentlySelectedOrgDetails.Name}
						rules={[
							{
								required: true,
							},
						]}>
						<Input />
					</Form.Item>

					<Form.List name='Addresses'>
						{(fields, { add, remove }) => (
							<>
								{fields.map((field, index) => {
									return (
										<div key={field.key + index}>
											<div>
												<span>
													<b>Address {field.key + 1}</b>
												</span>

												<span
													className='float-right'
													onClick={() => remove(field.name)}
													style={{
														cursor: 'pointer',
													}}>
													<MinusCircleOutlined />
													<span> Remove Address</span>
												</span>
											</div>

											<br />

											<Form.Item
												{...field}
												key='AddressType'
												label='Address Type'
												name={[field.name, 'AddressType']}
												fieldKey={[field.fieldKey, 'AddressType']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='AddressLine1'
												label='Address Line 1'
												name={[field.name, 'AddressLine1']}
												fieldKey={[field.fieldKey, 'AddressLine1']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='AddressLine2'
												label='Address Line 2'
												name={[field.name, 'AddressLine2']}
												fieldKey={[field.fieldKey, 'AddressLine2']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='City'
												label='City'
												name={[field.name, 'City']}
												fieldKey={[field.fieldKey, 'City']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='StateProvinceRegion'
												label='State/Province/Region'
												name={[field.name, 'StateProvinceRegion']}
												fieldKey={[field.fieldKey, 'StateProvinceRegion']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='PostZipCode'
												label='PostCode'
												name={[field.name, 'PostZipCode']}
												fieldKey={[field.fieldKey, 'PostZipCode']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Country'
												label='Country'
												name={[field.name, 'Country']}
												fieldKey={[field.fieldKey, 'Country']}
												rules={[
													{
														required: true,
													},
												]}>
												<Select placeholder='Select a Country' allowClear>
													<Select.Option />
													{countryList.map((country) => {
														return (
															<Select.Option value={country} key={country}>
																{country}
															</Select.Option>
														);
													})}
												</Select>
											</Form.Item>

											<Form.Item
												{...field}
												key='Email'
												label='Email'
												name={[field.name, 'Email']}
												fieldKey={[field.fieldKey, 'Email']}
												rules={[
													{
														type: 'email',
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Phone'
												label='Phone Number'
												name={[field.name, 'Phone']}
												fieldKey={[field.fieldKey, 'Phone']}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Fax'
												label='Fax Number'
												name={[field.name, 'Fax']}
												fieldKey={[field.fieldKey, 'Fax']}>
												<Input />
											</Form.Item>
										</div>
									);
								})}

								<Form.Item>
									<Button
										type='dashed'
										onClick={() => add()}
										block
										icon={<PlusOutlined />}>
										Add Address
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Update
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	}
}
