import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import LoginIndex from "./com/login/LoginIndex"
import WebIndex from "./com/web/WebIndex"
import SystemIndex from "./com/system/SystemIndex"
import ReportIndex from "./com/report/ReportIndex"
import DashboardIndex from "./com/content/dashboard/Index"
import Authanitication from "./com/mod/Authanitication";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={LoginIndex}></Route>
          <Route path="/report" component={ReportIndex}></Route>
          <Route path="/dashboard" component={DashboardIndex}></Route>
          <Route path="/system/:orgId" component={SystemIndex}></Route>
          <Route path="/*" component={()=>{
            return <Redirect to='/dashboard'/>
          }}></Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
