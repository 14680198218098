import { reqStatusTypes } from '../../../helpers/constants';
import { getUserOrgDetailsListWithExtendedData } from './dashboardSliceExtraActions';

const extraReducers = {
	// ******************************* getUserOrgDetailsListWithExtendedData() *******************************
	[getUserOrgDetailsListWithExtendedData.pending]: (state, action) => {
		state.getUserOrgDetailsListWithExtendedDataReqStatus =
			reqStatusTypes.pending;
		state.getUserOrgDetailsListWithExtendedDataReqError = '';
	},

	[getUserOrgDetailsListWithExtendedData.fulfilled]: (state, action) => {
		state.getUserOrgDetailsListWithExtendedDataReqStatus =
			reqStatusTypes.succeeded;
		state.userOrgDetailsList = action.payload;
		state.currentlySelectedOrgIndex = 0;
	},

	[getUserOrgDetailsListWithExtendedData.rejected]: (state, action) => {
		state.getUserOrgDetailsListWithExtendedDataReqStatus =
			reqStatusTypes.failed;
		state.getUserOrgDetailsListWithExtendedDataReqError = action.error.message;
		state.userOrgDetailsList = [];
	},
};

export default extraReducers;
