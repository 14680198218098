import React, { Component } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import Header from "./parts/Header";
import Body from "./parts/Body";
import Footer from "./parts/Footer";

export default class SystemIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Header />
                <Body />
                <Footer />
            </div>
        );
    };
}
