import { reqStatusTypes } from '../../helpers/constants';

const sliceConstants = {
	name: 'user',
	initialState: {
		// ******************************* dummyFn() *******************************
		dummyFnReqStatus: reqStatusTypes.idle,
		dummyFnReqError: '',
		dummyValue: '',

		// ******************************* getCurrentlyLoggedUserDetails() *******************************
		getCurrentlyLoggedUserDetailsReqStatus: reqStatusTypes.idle,
		getCurrentlyLoggedUserDetailsReqError: '',
		userDetails: {},
	},
};

export default sliceConstants;
