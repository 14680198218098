import { Button, Result, Spin } from 'antd';
import React, { Component } from 'react';
import { getAllAppTypeDetailsOnPlatform } from '../../../../../../data/api/appRegistryServerRequests';
import {
	getAppsOfOrgByOrganizationId,
	getCompaniesOfOrgByOrganizationId,
} from '../../../../../../data/api/orgRegistryServerRequests';
import './OrganizationApps.css';

export default class OrganizationApps extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOrganizationAppsAndLocationDataFetching: false,
			isOrganizationAppsAndLocationDataFetchingError: '',
			currentOrgAppListByCompanyWithExtendedData: {},
		};
	}

	async getOrganizationAppsAndLocationDetails() {
		try {
			this.setState({
				isOrganizationAppsAndLocationDataFetching: true,
				isOrganizationAppsAndLocationDataFetchingError: '',
			});

			const currentlySelectedOrgId = sessionStorage.getItem('orgID');

			// Fetch all app list purchased for current organization (For any company in org). Later from this list we can determine which apps are specifically purchased for certain company.
			const currentOrgPurchasedAppList = await getAppsOfOrgByOrganizationId({
				OrganizationID: currentlySelectedOrgId,
			});

			// Fetch all company details list for currently selected organization.
			// This is needed because currently "currentOrgPurchasedAppList()" result don't contain Company names, only ids. But from this API we can get name details.
			const currentOrgCompanyList = await getCompaniesOfOrgByOrganizationId({
				OrganizationID: currentlySelectedOrgId,
			});

			// Fetch default details of all the apps available in our platform. Needed because currently "currentOrgPurchasedAppList()" result don't contain Apps Image URL.
			const allAppTypeDetails = await getAllAppTypeDetailsOnPlatform();

			// Categorizing each purchased app into its specific company and appending more 'App & Company Details'.
			const currentOrgPurchasedAppListByCompanyName = currentOrgPurchasedAppList.reduce(
				(acc, currentApp) => {
					// Getting current iteration app's Company Name.
					const companyId = currentApp.CompanyID;
					const companyDetails =
						currentOrgCompanyList.find((com) => com.CompanyID === companyId) ||
						{};
					const companyName = companyDetails.Name;

					//  Getting current iteration app's more generic app details.
					const genericAppDetails = allAppTypeDetails.find(
						(app) => app.AppLicenseID === currentApp.AppLicenseID,
					);

					// NOTE : As Details coming from backend for some TEST users are not stable. (Like some of required keys are missing or empty). So below fix is used to avoid errors from those kinds of things.
					if (!companyName || !genericAppDetails) {
						return acc;
					}

					// Getting current iteration's app related company section in accumulator if available.
					const companyAppList = acc[companyName] || [];

					companyAppList.push({
						...currentApp,
						CompanyDetails: companyDetails, // Appending "Company Details" to App Details.
						GenericAppDetails: genericAppDetails, // Appending more "Generic App Details" to App Details. (Include things like App Image URL.)
					});

					// Making apps grouped by category name.
					acc[companyName] = companyAppList.sort((a, b) =>
						a.AppName.localeCompare(b.AppName),
					);

					return acc;
				},
				{},
			);

			this.setState({
				isOrganizationAppsAndLocationDataFetching: false,
				currentOrgAppListByCompanyWithExtendedData: currentOrgPurchasedAppListByCompanyName,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Some Error Occurred While Fetching Data.';

			this.setState({
				isOrganizationAppsAndLocationDataFetching: false,
				isOrganizationAppsAndLocationDataFetchingError: errMsg,
			});
		}
	}

	async componentDidMount() {
		this.getOrganizationAppsAndLocationDetails();
	}

	render() {
		const {
			isOrganizationAppsAndLocationDataFetching,
			isOrganizationAppsAndLocationDataFetchingError,
			currentOrgAppListByCompanyWithExtendedData,
		} = this.state;

		const { orgManagementSectionSliceActions } = this.props;

		const {
			setOrgManagementSectionCurrentTabId: setOrgManagementSectionCurrentTabIdAction,
		} = orgManagementSectionSliceActions;

		const isAnyMainActionsRunning = isOrganizationAppsAndLocationDataFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning &&
			isOrganizationAppsAndLocationDataFetchingError;

		if (isAnyMainActionsRunning) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Spin />
				</div>
			);
		}

		if (isAnyMainActionsError) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.getOrganizationAppsAndLocationDetails()}>
								Try Again
							</Button>,
						]}
					/>
				</div>
			);
		}

		return (
			<div className='OrganizationApps'>
				<div className='OrganizationApps__byCompaniesMajorContainer'>
					{Object.entries(currentOrgAppListByCompanyWithExtendedData)
						.sort((a, b) => {
							const [companyNameA, appListA] = a;
							const [companyNameB, appListB] = b;

							return companyNameA.localeCompare(companyNameB);
						})
						.map((entry) => {
							const [companyName, appList] = entry;

							return (
								<div className='OrganizationApps__byCompany'>
									<div className='OrganizationApps__byCompany__header'>
										<b> {companyName}</b>
									</div>

									<div className='OrganizationApps__byCompany__appList'>
										{appList.map((app) => (
											<img
												src={app.GenericAppDetails.AppImage}
												alt=''
												title={app.AppName}
												onClick={() => {
													setOrgManagementSectionCurrentTabIdAction('3');
												}}
											/>
										))}
									</div>

									<br />
								</div>
							);
						})}
				</div>
			</div>
		);
	}
}
