/* eslint-disable no-template-curly-in-string */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { addCompanyToOrganization } from '../../../../../../data/api/orgRegistryServerRequests';
import { countryList } from '../../../../../../lib/utilities/helperValues';
import { generateLocationCode } from '../../../../../../lib/utilities/mix/generateLocationCode';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
};

const validateMessages = {
	required: '${label} is required.',
	types: {
		email: '${label} is not a valid email.',
	},
};

export default class AddCompanyForm extends Component {
	formRef = React.createRef();

	async onFinish(values) {
		try {
			if (values.sights === undefined) {
				Modal.error({
					title: 'Address Required',
					content: 'Please add at least one address for the company.',
					onOk: () => {},
				});
				return;
			}

			const reqBody = {
				Name: values.user.companyname,
				OrganisationID: sessionStorage.getItem('orgID'),
				CreatedBy: sessionStorage.getItem('userID'),
				VATNumber: values.user.vat,
				Logo: '',
				Addresses: values.sights,
				LocationName: values.user.location, // This automatically create Location for this company.
				LocationCode: values.user.locationCode,
			};

			const hideMsg = message.loading({ content: 'Adding...' });

			// Creating New Company. (Location also created automatically.)
			await addCompanyToOrganization(reqBody);

			hideMsg();
			Modal.success({
				title: 'Company Added',
				content: 'Company details has been successfully added.',
				onOk() {},
			});
		} catch (error) {
			Modal.error({
				title: 'Company Not Updated',
				content: 'Sorry, Some error occurred while adding the company.',
				onOk() {},
			});
		}

		// This passed from parent and used to close this modal and re-fetch details so UI will be updated.
		this.props.onDone();
	}

	onChangeGenerateLocationCode(data = {}) {
		let { companyName, locationName } = data;
		const { existingLocationCodesInOrg } = this.props;

		companyName =
			companyName ||
			this.formRef.current.getFieldValue(['user', 'companyname']);

		locationName =
			locationName || this.formRef.current.getFieldValue(['user', 'location']);

		const locationCode = generateLocationCode({
			locationName: locationName,
			companyName: companyName,
			existingLocationCodes: existingLocationCodesInOrg,
		});

		// Updating 'Location Code' Field.
		this.formRef.current.setFieldsValue({
			user: {
				locationCode: locationCode,
			},
		});
	}

	componentDidUpdate(prevProps) {
		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				this.formRef.current && this.formRef.current.resetFields();
			}
		}
	}

	render() {
		const { existingLocationCodesInOrg } = this.props;

		return (
			<div>
				<Form
					{...layout}
					name='nest-messages'
					onFinish={(formValues) => this.onFinish(formValues)}
					validateMessages={validateMessages}
					ref={this.formRef}>
					<Form.Item
						name={['user', 'companyname']}
						label='Company Name'
						rules={[{ required: true }]}>
						<Input
							onChange={(e) => {
								this.onChangeGenerateLocationCode();
							}}
						/>
					</Form.Item>

					<Form.Item
						name={['user', 'location']}
						rules={[{ required: true }]}
						label='Location'>
						<Input
							onChange={(e) => {
								this.onChangeGenerateLocationCode();
							}}
						/>
					</Form.Item>

					<Form.Item
						name={['user', 'locationCode']}
						label='Location Code'
						rules={[
							{ required: true },
							{
								len: 3,
								message: 'Location Code must be only 3 characters long.',
							},
							{
								whitespace: true,
								message: 'Cannot have whitespace in Location Code.',
							},
							{
								pattern: new RegExp(/^[A-Z]+$/i),
								message: 'Only alphabetic letters are valid for Location Code.',
							},
							({ getFieldValue }) => {
								return {
									validator: (_, value) => {
										const isLocationCodeAlreadyExist = existingLocationCodesInOrg.some(
											(existingLocCode) =>
												existingLocCode.toUpperCase() === value.toUpperCase(),
										);

										if (!value || !isLocationCodeAlreadyExist) {
											return Promise.resolve();
										}

										return Promise.reject('This Location Code already exist.');
									},
								};
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name={['user', 'vat']}
						rules={[{ required: false }]}
						label='VAT Number'>
						<Input />
					</Form.Item>

					<Form.List name='sights'>
						{(fields, { add, remove }) => (
							<>
								{fields.map((field) => (
									<div key={field.key}>
										<span>
											<b>Address {field.key + 1}</b>
										</span>
										<Form.Item
											{...field}
											label='Address Type'
											name={[field.name, 'AddressType']}
											fieldKey={[field.fieldKey, 'AddressType']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Address Line 1'
											name={[field.name, 'AddressLine1']}
											fieldKey={[field.fieldKey, 'AddressLine1']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Address Line 2'
											name={[field.name, 'AddressLine2']}
											fieldKey={[field.fieldKey, 'AddressLine2']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='City'
											name={[field.name, 'City']}
											fieldKey={[field.fieldKey, 'City']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='State/Province/Region'
											name={[field.name, 'StateProvinceRegion']}
											fieldKey={[field.fieldKey, 'StateProvinceRegion']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='PostCode'
											name={[field.name, 'PostZipCode']}
											fieldKey={[field.fieldKey, 'PostZipCode']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Country'
											name={[field.name, 'Country']}
											fieldKey={[field.fieldKey, 'Country']}
											rules={[
												{
													required: true,
												},
											]}>
											<Select placeholder='Select a country' allowClear>
												<option />
												{countryList.map((countryName, index) => {
													return (
														<option value={countryName} key={index}>
															{countryName}
														</option>
													);
												})}
											</Select>
										</Form.Item>

										<Form.Item
											{...field}
											label='Email'
											name={[field.name, 'Email']}
											fieldKey={[field.fieldKey, 'Email']}
											rules={[
												{
													type: 'email',
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Phone Number'
											name={[field.name, 'Phone']}
											fieldKey={[field.fieldKey, 'Phone']}
											rules={[
												{
													required: true,
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											{...field}
											label='Fax Number'
											name={[field.name, 'Fax']}
											fieldKey={[field.fieldKey, 'Fax']}>
											<Input />
										</Form.Item>

										<span className='float-right'>
											{' '}
											<MinusCircleOutlined
												onClick={() => remove(field.name)}
											/>{' '}
											Remove address
										</span>
									</div>
								))}

								<Form.Item>
									<Button
										type='dashed'
										onClick={() => add()}
										block
										icon={<PlusOutlined />}>
										Add More Address
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Add
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	}
}
