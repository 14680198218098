import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";

import { orgRequest } from "../../data/api/OrgApi"
import { getJWT, decrypt } from "./../../lib/global/helpers";
import Variables from "../../lib/global/Variables";

const jwt = require("jsonwebtoken");

const styles = () => ({
    page: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

class Authanitication extends Component {
    state = {
        token: undefined,
        userID: undefined,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const jwtoken = getJWT();
        if (jwtoken.token == null) {
            this.clearTokens();
        } else {
            try {
                var accessDec = jwt.decode(jwtoken.token);
                this.setState({ userID: accessDec.userid })
            }
            catch (err) {
                console.log(err);
                this.clearTokens();
            }
        }
    }

    clearTokens = () => {
        Cookies.remove("infinity");
        Cookies.remove("embose");
        this.props.history.push("/login/auth");
    };

    render() {
        const { classes } = this.props;
        if (this.state.userID === undefined) {
            return (
                <div className={classes.page}>
                    <CircularProgress />
                </div>
            );
        }

        return <div>{this.props.children}</div>;
    }
}

export default withRouter(withStyles(styles)(Authanitication));
