import apiEndpointPaths from '../../lib/api/apiEndpointPaths';
import { APIError, errorLogger } from '../../lib/utilities/errorHandlers';

import axiosAppRegistryServerInstance from '../../lib/api/axiosInstances/axiosAppRegistryServerInstance';

export async function getAllAppTypeDetailsOnPlatform(
	reqBody = {},
	options = {},
) {
	try {
		const apiReqBody = {};

		const resObj = await axiosAppRegistryServerInstance({
			url: apiEndpointPaths.appRegistry.getAllAppTypeDetailsOnPlatform,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
