import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import { Config } from "../../lib/global/Config";
import { generateOrgRegJwt, generateAppRegJwt, generateEmailRegJwt } from "../../lib/global/helpers";

const jwt = require("jsonwebtoken");

const styles = () => ({
    page: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});


class JWToken extends Component {

    state = { OrgToken: undefined, AppToken: undefined, EmailToken: undefined };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.GenerateOrgRegJwt();
        this.GenerateAppRegJwt();
        this.GenerateEmailRegJwt();
    }

    GenerateOrgRegJwt() {
        const jwtoken = generateOrgRegJwt();
        this.setState({ OrgToken: jwtoken });
        Cookies.set("OrgToken", jwtoken.token, { expires: new Date(jwtoken.exp) });
    }

    GenerateAppRegJwt() {
        const jwtoken = generateAppRegJwt();
        this.setState({ AppToken: jwtoken });
        Cookies.set("AppToken", jwtoken.token, { expires: new Date(jwtoken.exp) });
    }

    GenerateEmailRegJwt() {
        const jwtoken = generateEmailRegJwt();
        this.setState({ EmailToken: jwtoken });
        Cookies.set("EmailToken", jwtoken.token, { expires: new Date(jwtoken.exp) });
    }

    render() {
        const { classes } = this.props;
        if (this.state.OrgToken === undefined || this.state.AppToken == undefined) {
            return (
                <div className={classes.page}>
                    <CircularProgress />
                </div>
            );
        }

        return <div>{this.props.children}</div>;
    }
}

export default withRouter(withStyles(styles)(JWToken));
