import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});
class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (<div></div>);
    }
}

export default withStyles(styles)(Header);
