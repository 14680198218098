import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton} from "@material-ui/core";
import { Row, Col, Form} from "bootstrap-4-react";
import Cookies from "js-cookie";
import { Settings as SettingsIcon, Delete as DeleteIcon, DragHandle as DragHandleIcon } from "@material-ui/icons";

const styles = (theme) => ({});
let appjwt = Cookies.get("AppToken");

class Body extends Component {
    state = {
        apps: [] 
    };
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const requestOptions2 = {
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer `+ appjwt,
            }
          };

          fetch(process.env.REACT_APP_AppRegistryApi+'v1/myapp', requestOptions2)
          .then(response => response.json())
          .then(data => this.onSetData(data))
          //.catch(window.location.href="/login/logout");
    }
    onSetData = (result) => {
        // console.log('xxx')
         this.setState({ apps: result.Result })
     }
     handleAppSelect = (value) => {
        //this.props.history.push("/dashboard/" + value);
        window.location="/dashboard/" + value
        this.setState({ appLoaded: true, appid: value });
    };

    render() {
        const { classes } = this.props;
        return (
           
            <Grid>
            <Grid className="all-wrapper menu-side with-pattern">
                <Grid className="auth-box-w wider">

                  
                        <Grid>
                            
                            <h4 className="auth-header">Select any other Products</h4>
                          
                            <Grid className="p-4">
                                  {
                                    <>
                                                      
                                <List>
                                {this.state.apps.map((e, i) => (
                                        <ListItem key={i}>
                                            <ListItemAvatar>
                                                <SettingsIcon />
                                            </ListItemAvatar>
                                            <ListItemText primary={e.Name} secondary={<small>{e.AboutApp}</small>} />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={this.handleAppSelect.bind(null, e.ApplicationID)}>
                                                    <DragHandleIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))} 
                                    
                                </List>
                                </>
                                }
                            </Grid>
                            
                        </Grid>
                    
                         
                    
                </Grid>
            </Grid>

        </Grid >
        );
    }
}

export default withStyles(styles)(Body);