/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import {
	AccountBox as AccountBoxIcon,
	Apps as AppsIcon,
	Assessment as AssessmentIcon,
	Business as BusinessIcon,
	Dashboard as DashboardIcon,
	ExitToApp as ExitToAppIcon,
	Menu as MenuIcon,
	People as PeopleIcon,
	Store as StoreIcon,
} from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Avatar, Button, PageHeader, Result, Select, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { reqStatusTypes } from '../../../lib/redux/helpers/constants';
import { sectionName as dashboardSectionName } from '../../../lib/redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../lib/redux/slices/dashboard/dashboardSlice/dashboardSlice';
import { actions as manageUsersSectionSliceActions } from '../../../lib/redux/slices/dashboard/manageUsersSectionSlice/manageUsersSectionSlice';
import { actions as orgManagementSectionSliceActions } from '../../../lib/redux/slices/dashboard/orgManagementSectionSlice/orgManagementSectionSlice';
import { actions as userSliceActions } from '../../../lib/redux/slices/userSlice/userSlice';
import { RenderIf } from '../../../lib/utilities/reactHelpers';
import AccountDetailsSection from '../dashboard/AccountDetailsSection/AccountDetailsSection';
import OrgManagementSection from '../dashboard/OrgManagementSection/OrgManagementSection';
import PurchaseAppsSection from '../dashboard/PurchaseAppsSection/PurchaseAppsSection';
import ReportsSection from '../dashboard/ReportsSection/ReportsSection';
import YourAppsSection from '../dashboard/YourAppsSection/YourAppsSection';
import DashboardHomeSection from './DashboardHomeSection/DashboardHomeSection';
import './Index.css';
import ManageUsersSection from './ManageUsersSection/ManageUsersSection';

const { Option } = Select;

// This sub page list will be used to render "Sidebar Menu Nav Links" and 'Display Appropriate Page' according to selected page.
export const dashboardSubPageList = [
	{
		pageId: 'DashboardHome',
		pageName: 'Dashboard Home',
		pageIcon: <DashboardIcon />,
		pageComponent: (customProps) => <DashboardHomeSection {...customProps} />,
	},
	{
		pageId: 'AccountDetails',
		pageName: 'Account Details',
		pageIcon: <AccountBoxIcon />,
		pageComponent: (customProps) => <AccountDetailsSection {...customProps} />,
	},
	{
		isDivider: true,
	},
	{
		pageId: 'YourApps',
		pageName: 'Your Apps',
		pageIcon: <AppsIcon />,
		pageComponent: (customProps) => <YourAppsSection {...customProps} />,
	},
	{
		pageId: 'PurchaseApps',
		pageName: 'Purchase Apps',
		pageIcon: <StoreIcon />,
		pageComponent: (customProps) => <PurchaseAppsSection {...customProps} />,
		isOrganizationAdminOnly: true,
	},
	{
		pageId: 'OrganizationManagement',
		pageName: 'Organization Management',
		pageIcon: <BusinessIcon />,
		pageComponent: (customProps) => <OrgManagementSection {...customProps} />,
		isOrganizationAdminOnly: true,
	},
	{
		pageId: 'ManageUsers',
		pageName: 'Manage Users',
		pageIcon: <PeopleIcon />,
		pageComponent: (customProps) => <ManageUsersSection {...customProps} />,
		isOrganizationAdminOnly: true,
	},
	{
		pageId: 'Reports',
		pageName: 'Reports',
		pageIcon: <AssessmentIcon />,
		pageComponent: (customProps) => <ReportsSection {...customProps} />,
	},
	{
		isDivider: true,
	},
	{
		pageId: 'LogOut',
		pageName: 'Log Out',
		pageIcon: <ExitToAppIcon />,
		pageComponent: (customProps) => (
			<Redirect to='/login/auth' {...customProps} />
		),
	},
];

class DashboardIndex extends Component {
	constructor(props) {
		super(props);

		const isUserLoggedIn = sessionStorage.getItem('loggedin') === 'true';

		this.state = {
			isUserLoggedIn: isUserLoggedIn,
			isMobileMenuOpen: false, // This is used to toggle Mobile Menu in Smaller (<lg) resolutions.
		};
	}

	toggleMobileMenu(options = {}) {
		const { openState } = options;
		const { isMobileMenuOpen } = this.state;

		this.setState({
			isMobileMenuOpen: openState !== undefined ? openState : !isMobileMenuOpen,
		});
	}

	// When user click on a Dashboard's sub page section to see it.
	handleSubPageNavClick = (subPageId) => {
		const { dashboardSliceActions } = this.props;
		const {
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;

		setCurrentDashboardPageIdAction(subPageId);
	};

	// When user change the Organization form DropDown box.
	handleOrganizationSelectChange(selectedOrganization, selectedOrgIndex) {
		const { dashboardSliceActions } = this.props;
		const {
			setCurrentlySelectedOrgIndex: setCurrentlySelectedOrgIndexAction,
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;

		setCurrentDashboardPageIdAction(dashboardSubPageList[0].pageId); // Selecting HomePage.
		setCurrentlySelectedOrgIndexAction(selectedOrgIndex);

		// NOTE: This session values seems to be used by most of existing components to get currently selected organization. So we leave it be.
		sessionStorage.setItem('orgID', selectedOrganization.OrganisationID);
		sessionStorage.setItem('OrgDetails', JSON.stringify(selectedOrganization));
	}

	// Return appropriate page component according to 'currentDashboardPageId' value in redux state.
	getAppropriateSubPageComp() {
		const {
			dashboardSliceState,
			dashboardSliceActions,
			orgManagementSectionSliceState,
			orgManagementSectionSliceActions,
			manageUsersSectionSliceState,
			manageUsersSectionSliceActions,
		} = this.props;

		const {
			userOrgDetailsList,
			currentlySelectedOrgIndex,
			currentDashboardPageId,
		} = dashboardSliceState;

		// Getting UserOrgDetails of SPECIFICALLY currently selected Organization.
		const currentlySelectedUserOrgDetails =
			userOrgDetailsList[currentlySelectedOrgIndex] || {};

		const currentlySelectedSubPageDetails =
			dashboardSubPageList.find(
				(subPage) => subPage.pageId === currentDashboardPageId,
			) || {};

		// Props that we need to pass for every Sub Page Component.
		const propsForSubPageComponents = {
			dashboardSliceState,
			dashboardSliceActions,
			orgManagementSectionSliceState,
			orgManagementSectionSliceActions,
			manageUsersSectionSliceState,
			manageUsersSectionSliceActions,
			isCurrentlySelectedOrganizationAdmin: Boolean(
				currentlySelectedUserOrgDetails.IsOrganizationAdmin,
			),
		};

		if (!currentlySelectedSubPageDetails.pageComponent) {
			return <div>NO PAGE FOUND</div>;
		}

		const CurrentSubPage = currentlySelectedSubPageDetails.pageComponent;

		if (currentlySelectedSubPageDetails.isOrganizationAdminOnly) {
			if (currentlySelectedUserOrgDetails.IsOrganizationAdmin) {
				return <CurrentSubPage {...propsForSubPageComponents} />;
			} else {
				return (
					<div className='GC-UTL-flexSuperCenter'>
						<Result
							status='403'
							title='NOT AUTHORIZED'
							subTitle='Sorry, You are not authorized to access this page.'
							extra={null}
						/>
					</div>
				);
			}
		} else {
			return <CurrentSubPage {...propsForSubPageComponents} />;
		}
	}

	// Generate what should be displayed on Dashboard Avatar Circle.
	generateAvatarValue() {
		const { userSliceState } = this.props;
		const { userDetails: userAccountDetails } = userSliceState;

		const { FirstName = '', LastName = '' } = userAccountDetails;

		return String(FirstName[0] + LastName[0]).toUpperCase();
	}

	async fetchNecessaryInitialPageData() {
		const { userSliceActions, dashboardSliceActions } = this.props;

		const {
			getCurrentlyLoggedUserDetails: getCurrentlyLoggedUserDetailsAction,
		} = userSliceActions;

		const {
			getUserOrgDetailsListWithExtendedData: getUserOrgDetailsListWithExtendedDataAction,
		} = dashboardSliceActions;

		getCurrentlyLoggedUserDetailsAction(); // Getting Currently Logged User's Basic Details. (Ex. Name, Email, Address, ...)
		await getUserOrgDetailsListWithExtendedDataAction(); // Getting Currently Logged User's Organizations, Companies, Apps, Etc.. he is part of.
	}

	componentDidMount() {
		const { isUserLoggedIn } = this.state;
		const { history: routerHistory } = this.props;

		if (!isUserLoggedIn) {
			return routerHistory.push('/login/auth');
		}

		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { isUserLoggedIn, isMobileMenuOpen } = this.state;
		const { userSliceState, dashboardSliceState } = this.props;

		const {
			getCurrentlyLoggedUserDetailsReqStatus,
			getCurrentlyLoggedUserDetailsReqError,
			userDetails: userAccountDetails,
		} = userSliceState;

		const {
			getUserOrgDetailsListWithExtendedDataReqStatus,
			getUserOrgDetailsListWithExtendedDataReqError,
			userOrgDetailsList,
			currentlySelectedOrgIndex,
			currentDashboardPageId,
		} = dashboardSliceState;

		const isAnyMainActionsRunning =
			getCurrentlyLoggedUserDetailsReqStatus === reqStatusTypes.pending ||
			getUserOrgDetailsListWithExtendedDataReqStatus === reqStatusTypes.pending;

		const isAllMainActionsSucceeded =
			getCurrentlyLoggedUserDetailsReqStatus === reqStatusTypes.succeeded ||
			getUserOrgDetailsListWithExtendedDataReqStatus ===
				reqStatusTypes.succeeded;

		const isAnyMainActionsError =
			!isAnyMainActionsRunning &&
			(getCurrentlyLoggedUserDetailsReqError ||
				getUserOrgDetailsListWithExtendedDataReqError);

		if (!isUserLoggedIn) {
			return <Redirect to='/login/logout' />;
		}

		return (
			<div
				className='Dashboard'
				style={{
					height: '100%',
					minHeight: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<PageHeader
					className='Dashboard__mainDashboardAppHeader'
					ghost={false}
					avatar={{ src: '/inc/img/witmeg.png' }}
					title=''
					subTitle='All-In-One Dashboard'
				/>

				{/* When any main async actions are running.(Like fetching in componentDidMount).  */}
				{isAnyMainActionsRunning && (
					<Grid
						container
						style={{ flex: '1' }}
						justify='center'
						alignItems='center'>
						<Spin />
					</Grid>
				)}

				{/* When error occurred */}
				{isAnyMainActionsError && (
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.fetchNecessaryInitialPageData()}>
								Try Again
							</Button>,
						]}
					/>
				)}

				{/* All below are only displayed when all necessary details are done fetching and no errors occurred  */}
				<RenderIf
					condition={
						!isAnyMainActionsRunning &&
						!isAnyMainActionsError &&
						isAllMainActionsSucceeded
					}>
					<div className='Dashboard__contentWrapper'>
						<div className='Dashboard__header'>
							<div className='Dashboard__header__mobileMenuToggle'>
								<MenuIcon
									onClick={() => {
										this.toggleMobileMenu();
									}}
								/>
							</div>

							<div className='Dashboard__header__organizationSelector'>
								<span title='Selected Organization'>
									<BusinessIcon />
									&nbsp;
								</span>

								<Select
									placeholder='Select Organization'
									value={
										userOrgDetailsList.length > 0
											? currentlySelectedOrgIndex
											: undefined
									}
									style={{ minWidth: '200px' }}
									size='large'
									onChange={(selectedUserOrgIndex) => {
										this.handleOrganizationSelectChange(
											userOrgDetailsList[selectedUserOrgIndex],
											selectedUserOrgIndex,
										);
									}}>
									{userOrgDetailsList.map((organization, index) => (
										<Option
											value={index}
											style={{
												height: '40px',
												display: 'flex',
												alignItems: 'center',
											}}>
											{organization.OrgDetails.Name}
										</Option>
									))}
								</Select>
							</div>

							<div className='Dashboard__header__title'>
								<h4>WELCOME TO WITMEG</h4>
							</div>

							<div className='Dashboard__header__userActions'>
								<Avatar
									style={{
										fontWeight: 'bold',
										backgroundColor: '#383e56',
									}}>
									{this.generateAvatarValue()}
								</Avatar>

								<span className='welcometitle'>
									{userAccountDetails.FirstName} {userAccountDetails.LastName}
								</span>

								<Button href='/login/logout' size='medium'>
									<ExitToAppIcon />
									<span>&nbsp; Log Out</span>
								</Button>
							</div>
						</div>

						<div className='Dashboard__body'>
							<div
								className={`Dashboard__body__mainMenu ${
									isMobileMenuOpen
										? 'Dashboard__body__mainMenu--mobile---Open'
										: 'Dashboard__body__mainMenu--mobile---Close'
								}`}
								onClick={() => {
									this.toggleMobileMenu({ openState: false });
								}}>
								<List>
									{dashboardSubPageList.map((subPage, index) => {
										if (subPage.isDivider) {
											return <Divider key={index} />;
										}

										const isCurrentlySelectedItem =
											currentDashboardPageId === subPage.pageId;

										const listItemStyles = {
											borderLeft: isCurrentlySelectedItem
												? '2px solid gray'
												: 'none',
											marginBottom: '10px',
										};

										return (
											<ListItem
												button
												key={subPage.pageName}
												style={listItemStyles}
												onClick={() =>
													this.handleSubPageNavClick(subPage.pageId)
												}>
												<ListItemIcon>{subPage.pageIcon}</ListItemIcon>
												<ListItemText primary={subPage.pageName} />
											</ListItem>
										);
									})}
								</List>
							</div>
							<div className='Dashboard__body__subPage'>
								{this.getAppropriateSubPageComp()}
							</div>
						</div>
					</div>
				</RenderIf>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSliceState: state.user,
		dashboardSliceState: state[dashboardSectionName].dashboard,
		orgManagementSectionSliceState:
			state[dashboardSectionName].orgManagementSection,
		manageUsersSectionSliceState:
			state[dashboardSectionName].manageUsersSection,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundUserSliceActions = bindActionCreators(userSliceActions, dispatch);
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);
	const boundOrgManagementPageSliceActions = bindActionCreators(
		orgManagementSectionSliceActions,
		dispatch,
	);
	const boundManageUsersSectionSliceActions = bindActionCreators(
		manageUsersSectionSliceActions,
		dispatch,
	);

	return {
		userSliceActions: boundUserSliceActions,
		dashboardSliceActions: boundDashboardSliceActions,
		orgManagementSectionSliceActions: boundOrgManagementPageSliceActions,
		manageUsersSectionSliceActions: boundManageUsersSectionSliceActions,
	};
};

const DashboardIndexWithOtherHOC = withRouter(DashboardIndex);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DashboardIndexWithOtherHOC);
