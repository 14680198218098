/* eslint-disable no-param-reassign */
import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

const standardReducers = {
	// Eventually determine which organization is currently selected.
	setCurrentlySelectedOrgIndex: (state, action) => {
		state.currentlySelectedOrgIndex = action.payload;
	},

	// Determine which page/section will be shown on dashboard page.
	setCurrentDashboardPageId: (state, action) => {
		state.currentDashboardPageId = action.payload;
	},
};

export default standardReducers;
