import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Modal } from 'antd';
import { Col, Form, Row } from 'bootstrap-4-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { getAllAppTypeDetailsOnPlatform } from '../../../../../../data/api/appRegistryServerRequests';
import { addOrganization } from '../../../../../../data/api/orgRegistryServerRequests';
import {
	renderHidden,
	renderTextBox,
} from '../../../../../../lib/global/helpers';
import { countryList } from '../../../../../../lib/utilities/helperValues';
import { generateLocationCode } from '../../../../../../lib/utilities/mix/generateLocationCode';

// MUI Styles Used with withStyles HOC.
const muiStyles = (theme) => ({
	form: { padding: '20px 10px !important' },
});

const formName = 'AddOrganizationForm';

const formInitialValues = {
	SelectedApp: '',
	Name: '',
	FirstSiteName: '', // Location Name
	LocationCode: '',
	VATNumber: '',
	Addresses: [
		{ AddressType: 'Billing' }, // Currently AddressType Field is hidden. So In here we strictly set a value for it.
	],
};

const formFieldsValidator = (values) => {
	const errors = {};

	// ************* 'LocationCode' Field Related Validations ************
	if (!values.LocationCode) {
		errors.LocationCode = 'Required.';
	} else if (values.LocationCode.length !== 3) {
		errors.LocationCode = 'Location Code must be only 3 characters long.';
	} else if (!/^[A-Z]+$/i.test(values.LocationCode)) {
		errors.LocationCode =
			'Only alphabetic letters are valid for Location Code.';
	} else {
		errors.LocationCode = '';
	}

	return errors;
};

class AddOrganizationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saveButtonState: 'Add Organization',
			saveButtonActive: true,
			platformAppList: [],
		};

		this.handleAddOrganization = this.handleAddOrganization.bind(this);
	}

	handleAddOrganization = async (values) => {
		const { onDone = () => {} } = this.props;

		try {
			this.setState({
				saveButtonActive: false,
				saveButtonState: 'Adding Organization...',
			});

			const selectedApp = JSON.parse(values.SelectedApp);

			const reqBody = {
				Name: values.Name, // Organization Name. Also automatically company will be created for this same name.
				UserID: sessionStorage.getItem('userID'),
				ApplicationID: selectedApp.ApplicationID,
				AppLicenseID: selectedApp.AppLicenseID, // App's Universal Key. (Ex. 1, 2)
				Addresses: values.Addresses,
				VATNumber: values.VATNumber, // This is used in automatically created Company.
				FirstSiteName: values.FirstSiteName, // This is the name that will be used for automatically created Location.
				LocationCode: values.LocationCode, // Location code for above Location.
			};

			await addOrganization(reqBody);

			this.setState({ saveButtonState: 'Organization Added.' });

			Modal.success({
				title: 'Organization Registration Successful',
				content:
					'Organization has been successfully created and added to your account.',
				onOk: () => {},
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Sorry. Some error occurred while creating the organization. Please try again. If you still seeing this error please contact our support team.';

			this.setState({
				saveButtonActive: true,
				saveButtonState: 'Try Again',
			});

			Modal.error({
				title: 'Error Occurred',
				content: errMsg,
				onOk: () => {},
			});
		}

		// This passed from parent and used to close this modal and re-fetch details so UI will be updated.
		onDone();
	};

	onChangeGenerateLocationCode(data = {}) {
		let { companyName, locationName } = data;
		const {
			// Props from ReduxForm.
			formValues = {},
			change: changeFieldValue,
		} = this.props;

		companyName = companyName || formValues.Name; // When creating organization the company is automatically created and its name same as Organization Name.
		locationName = locationName || formValues.FirstSiteName; // When creating organization the location is also automatically created.

		const locationCode = generateLocationCode({
			locationName: locationName,
			companyName: companyName,
			existingLocationCodes: [], // Since we are just creating the organization, there won't be any existing LocationCodes.
		});

		// Updating 'Location Code' Field.
		changeFieldValue('LocationCode', locationCode);
	}

	async fetchNecessaryInitialData() {
		const platformAppTypeDetails = await getAllAppTypeDetailsOnPlatform();
		this.setState({
			platformAppList: platformAppTypeDetails,
		});
	}

	componentDidMount() {
		this.fetchNecessaryInitialData();
	}

	componentDidUpdate(prevProps) {
		const { reset } = this.props;

		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				reset(); // Resetting ReduxForm Values.

				// Resetting Internal State.
				this.setState({
					saveButtonState: 'Add Organization',
					saveButtonActive: true,
				});
			}
		}
	}

	render() {
		const { platformAppList } = this.state;
		const { classes: muiClasses, handleSubmit } = this.props;

		return (
			<div className='AddOrganizationForm'>
				<Form
					onSubmit={handleSubmit(this.handleAddOrganization)}
					className={muiClasses.form}>
					<Row>
						<Col col='sm-12'>
							<label for='txtApp'>App</label>

							<Field
								className='form-control'
								name='SelectedApp'
								required='required'
								id='txtApp'
								label='App'
								smalltext='App'
								component='select'>
								<option />
								{platformAppList.map((app, i) => (
									<option key={i} value={JSON.stringify(app)}>
										{app.Name} - {app.AboutApp}
									</option>
								))}
							</Field>

							<small class='form-text text-muted'>
								Select an app to register
							</small>
							<br />
						</Col>

						<Col col='sm-12'>
							<Field
								name='Name'
								type='text'
								required='required'
								id='txtOrgName'
								label='Organization Name'
								smalltext='Enter the name of the organization'
								component={renderTextBox}
								onChange={(e, value) => {
									this.onChangeGenerateLocationCode({
										companyName: value,
									});
								}}
							/>
						</Col>

						<Col col='sm-6'>
							<Field
								name='FirstSiteName'
								type='text'
								required='required'
								id='txtLocation'
								label='Location'
								smalltext='Enter the name of the Location'
								component={renderTextBox}
								onChange={(e, value) => {
									this.onChangeGenerateLocationCode({
										locationName: value,
									});
								}}
							/>
						</Col>

						<Col col='sm-6'>
							<Field
								name='LocationCode'
								type='text'
								required='required'
								id='txtLocationCode'
								label='Location Code'
								smalltext='Enter the LocationCode'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12'>
							<Field
								name='VATNumber'
								type='text'
								required=''
								id='txtVATNumber'
								label='VAT Number'
								smalltext='Enter the VAT Number'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12' style={{ marginTop: '20px' }}>
							<Typography component='p' varient='h4'>
								<b>Address Details</b>
							</Typography>
						</Col>

						<Col col='sm-12'>
							<Field
								name='Addresses[0].AddressType'
								type='hidden'
								required='required'
								id='txtAddressType'
								component={renderHidden}
							/>
						</Col>

						<Col col='sm-12 md-6'>
							<Field
								name='Addresses[0].AddressLine1'
								type='text'
								required='required'
								id='txtAddressLine1'
								label='Line 1'
								smalltext='Enter Address Line 1'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12 md-6'>
							<Field
								name='Addresses[0].AddressLine2'
								type='text'
								id='txtAddressLine2'
								label='Line 2'
								smalltext='Enter address line 2'
								component={renderTextBox}
							/>
						</Col>
					</Row>

					<Row>
						<Col col='sm-12 md-6'>
							<Field
								name='Addresses[0].City'
								type='text'
								id='txtCity'
								label='City'
								smalltext='Enter City'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12 md-6'>
							<Field
								name='Addresses[0].StateProvinceRegion'
								type='text'
								required='required'
								id='txtStateProvinceRegion'
								label='State/Province/Region'
								smalltext='Enter State/Province/Region'
								component={renderTextBox}
							/>
						</Col>
					</Row>

					<Row>
						<Col col='sm-12 md-6'>
							<Field
								name='Addresses[0].PostZipCode'
								type='text'
								required='required'
								id='txtPostZipCode'
								label='PostCode'
								smalltext='Enter Postcode'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12 md-6'>
							<label for='txtPostZipCode'>Country</label>
							<Field
								className='form-control'
								name='Addresses[0].Country'
								required='required'
								id='txtCountry'
								label='Country'
								smalltext='Select Country'
								component='select'>
								<option />
								{countryList.map((country) => {
									return (
										<option value={country} key={country}>
											{country}
										</option>
									);
								})}
							</Field>
							<small xs='12' md='6' class='form-text text-muted'>
								Enter Country
							</small>
						</Col>
					</Row>

					<Row>
						<Col col='sm-12'>
							<Field
								name='Addresses[0].Email'
								type='email'
								required='required'
								id='txtAEmail'
								label='Email'
								smalltext='Enter Email'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12'>
							<Field
								name='Addresses[0].Phone'
								type='tel'
								required='required'
								id='txtPhone'
								label='Phone Number'
								smalltext='Enter Phone'
								component={renderTextBox}
							/>
						</Col>

						<Col col='sm-12'>
							<Field
								name='Addresses[0].Fax'
								type='tel'
								id='txtFax'
								label='Fax Number'
								smalltext='Enter Fax'
								component={renderTextBox}
							/>
						</Col>

						<Button
							disabled={!this.state.saveButtonActive}
							variant='contained'
							color='primary'
							type='submit'
							className='w-100'>
							{this.state.saveButtonState}
						</Button>
					</Row>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	formValues: getFormValues(formName)(state), // Getting This form's ReduxForm values.
});

const mapDispatchToProps = (dispatch) => ({});

const AddOrganizationForm__WithStyleHOC = withStyles(muiStyles)(
	AddOrganizationForm,
);

const AddOrganizationForm__WithRedux = connect(
	mapStateToProps,
	mapDispatchToProps,
)(AddOrganizationForm__WithStyleHOC);

// Finally HOCing with ReduxForm.
export default reduxForm({
	initialValues: formInitialValues,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	form: formName,
	validate: formFieldsValidator,
})(AddOrganizationForm__WithRedux);
