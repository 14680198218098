import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box, Tab, Tabs, Button } from "@material-ui/core";
import { Row, Col, Form } from "bootstrap-4-react";
import SwipeableViews from 'react-swipeable-views'
import { green } from '@material-ui/core/colors';
import { renderEmail } from 'postonents';
import WelcomeEmail from '../mod/emails/templates/WelcomeEmail';
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { VerifiedUser as VerifiedUserIcon, Home as HomeIcon, Business as BusinessIcon, Check as CheckIcon } from '@material-ui/icons';
import { renderTextBox, renderHidden, decrypt } from "../../lib/global/helpers";
import addNotification from "react-push-notification";
import { Modal , PageHeader} from 'antd';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

import { orgRequest } from "../../data/api/OrgApi"
import { appRequest } from "../../data/api/AppApi"

import User from "../../data/structure/User";
import PostOrganization from "../../data/structure/post/PostOrganization";
import { generateLocationCode } from '../../lib/utilities/mix/generateLocationCode';


const jwt = require("jsonwebtoken");

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const formName = "VerifyForm";
const formFieldsValidator = (values) => {
	const errors = {};

	// ************* 'LocationCode' Field Related Validations ************
	if (!values.LocationCode) {
		errors.LocationCode = 'Required.';
	} else if (values.LocationCode.length !== 3) {
		errors.LocationCode = 'Location Code must be only 3 characters long.';
	} else if (!/^[A-Z]+$/i.test(values.LocationCode)) {
		errors.LocationCode =
			'Only alphabetic letters are valid for Location Code.';
	} else {
		errors.LocationCode = '';
	}

	return errors;
};

const styles = theme => ({
    form: { padding: "20px 10px !important" },
    tabPanel: { padding: "0 30px !important" },
    topContainer: { paddingTop: "25px", textAlign: "center" },
    topIcon: { fill: green[500], fontSize: "49px" }
});
class Verify extends Component {
    state = {
        value: 0,
        isValid: false,
        applicationName: "",
        saveButtonState: "Finish and Save",
        saveButtonActive: true
    }

    constructor(props) {
        super(props);

        this.initValues = decrypt(decodeURIComponent(sessionStorage.getItem('verificationtoken')));
        this.initValues = JSON.parse(this.initValues);
    }

    onChangeGenerateLocationCode(data = {}) {
		let { companyName, locationName } = data;
		const {
			// Props from ReduxForm.
			formValues = {},
			change: changeFieldValue,
		} = this.props;

		companyName = companyName || formValues.Name; // When creating organization the company is automatically created and its name same as Organization Name.
		locationName = locationName || formValues.FirstSiteName; // When creating organization the location is also automatically created.

		const locationCode = generateLocationCode({
			locationName: locationName,
			companyName: companyName,
			existingLocationCodes: [], // Since we are just creating the organization, there won't be any existing LocationCodes.
		});

		// Updating 'Location Code' Field.
		changeFieldValue('LocationCode', locationCode);
	}

    componentDidMount() {
        if (sessionStorage.getItem('regeligible')) {
            this.setState({ isValid: true });
        }
     //   console.log(sessionStorage.getItem('regeligible'))

        this.props.dispatch(change(formName, "FirstName", this.initValues.FirstName));
        this.props.dispatch(change(formName, "LastName", this.initValues.LastName));
        this.props.dispatch(change(formName, "Email", this.initValues.Email));
        this.props.dispatch(change(formName, "Password", this.initValues.Password));
        this.props.dispatch(change(formName, "Addresses[0].AddressType", "Billing"));


        sessionStorage.setItem('fullName', this.initValues.FirstName + ' '+ this.initValues.LastName);

        appRequest("retrieve", { id: this.initValues.AppID })
            .then(response => {
                let data = response.data.Result;
               // console.log(data)
                this.setState({ applicationName: data.Name })
                this.props.dispatch(change(formName, "ApplicationID", data.ApplicationID));
                this.props.dispatch(change(formName, "AppLicenseID", data.AppLicenseID));
            })
            .catch(error => console.log(error));
    }

    handleChange = (event, newValue) => { this.setState({ value: newValue }); };
    handleChangeIndex = (index) => { this.setState({ value: index }); };
    handleNextButton = (newValue) => { this.setState({ value: newValue }); };

    handleSelectApp = (type) => {
        if (type === 'login') {
            this.props.history.push('/login/auth/?genericLogin=true');
            sessionStorage.setItem('appName', '');
        } else if (type == 'register') {
            Modal.error({
                title: 'Please select an application',
                content: 'Please select an application to ' + type,
                onOk: () => {
                    this.props.history.push(
                        '/login/auth?registrationSelect=true'
                    );
                    sessionStorage.setItem('appName', '');
                },
            });
        }
    }

    onSubmit = (values) => {
        this.setState({ saveButtonActive: false, saveButtonState: "Saving User..." });
        let user = new User(values);
       // user.Addresses = [];
        user.UserName = user.Email;

        let postOrg = new PostOrganization(values);

      //  console.log(postOrg)
        let userID = user
        let appID = this.initValues.AppID;
        let properties = this.props;
        let userResponse = undefined;
        orgRequest("adduser", user)
            .then((response) => {

               // console.log(response.data)

                if (response.data.Status) {
                    this.setState({ saveButtonState: "User Saved" });
                    userResponse = response.data.Result;
                }
                else {
                    Modal.error({
                        title: 'Sorry something went wrong',
                        content: 'Please make sure to register your company name once. if you still seeing this error please contact our support team',
                        onOk() { },
                    });
                    throw new Error(response.data.Result);
                }
            })
            .then(() => {
                try {
                    var accessDec = jwt.decode(userResponse.AccessToken);
                    var refreshDec = jwt.decode(userResponse.RefreshToken);
                    userID = accessDec.userid;
                    Cookies.remove("infinity");
                    Cookies.remove("embose");
                    Cookies.set('infinity', userResponse.AccessToken, { expires: new Date(accessDec.exp * 1000) });
                    Cookies.set('embose', userResponse.RefreshToken, { expires: new Date(refreshDec.exp * 1000) });
                } catch (err) {
                    console.log(err);
                }

            })
            .then(() => {
                this.setState({ saveButtonState: "Saving Organization..." });
                postOrg.UserID = userID;
                postOrg.LocationCode = values.LocationCode;
            //    console.log(postOrg)
                orgRequest("addorg", postOrg)
                    .then((response) => {
                        if (response.data.Status) {
                            this.setState({ saveButtonState: "Completed" });



                            const timestamp = Math.floor(Date.now() / 1000) + 1000;
                        var payload = {
                            "aud": process.env.REACT_APP_Mail_Audience,
                            "exp": timestamp,
                            "iss": process.env.REACT_APP_Mail_Issuer
                        };

                        var signOptions = {
                            "algorithm": "RS256"
                        };

                        var privateKey = process.env.REACT_APP_Mail_PrivateKey.replace(/\\n/g, '\n');
                        var token = jwt.sign(payload, privateKey, signOptions);
                      //  console.log(token)

                        const data = {
                            email: values.Email,
                            fullName:sessionStorage.getItem('fullName')
                        }

                        const emailBody = renderEmail(WelcomeEmail, { lang: 'en', data });

                        const ecoEmail = {
                            "Channel": "Email",
                            "ServiceOrigin": "Org Registration",
                            "EmailType": "Standard",
                            "TemplateName": "",
                            "ToName": sessionStorage.getItem('fullName'),
                            "To": values.Email,
                            "FromName": process.env.REACT_APP_Mail_SenderName,
                            "From": process.env.REACT_APP_Mail_SenderMail,
                            "Subject": "Welcome to the WITMEG",
                            "PlainTextContent": "Hello "+sessionStorage.getItem('fullName')+", We're excited to welcome to the WITMEG community.",
                            "HTMLContent": emailBody,
                            "MailResponseCode": "0",
                            "MessageReceivedAt": new Date()
                        };

                        const response =  fetch(process.env.REACT_APP_Mail_API + 'v1/email/add', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ` + token,
                            },
                            body: JSON.stringify(ecoEmail)
                        })
                        .then(response => response.json())
                        .then(data => {

                      //   console.log(data)
                            if (data.Status) {

                            setTimeout(function () {
                                sessionStorage.setItem('appid', appID);
                                sessionStorage.setItem('loggedin', true);
                                sessionStorage.setItem('userID', userID);

                                Modal.success({
                                    title: 'Account registration successful',
                                    content: 'Your registration has been successfully completed, you will be redirected to your dashboard now. ',
                                    onOk() {  window.location.href="/dashboard" + sessionStorage.getItem('appid'); },
                                });


                            }, 1000);
                            }
                        });

                        }
                        else {
                            Modal.error({
                                title: 'Sorry something went wrong',
                                content: 'Please make sure to register your company name once. if you still seeing this error please contact our support team',
                                onOk() { },
                            });
                           // throw new Error(response.data.Result);
                        }
                    })
                    .catch((error) => {
                        throw error;
                    });
            })
            .catch((error) => {
                addNotification({
                    title: "Error",
                    message: error,
                    native: true,
                });
                this.setState({ saveButtonActive: true, saveButtonState: "Try Again" });
                console.log(error);
            })
    };

    render() {
        const { classes, handleSubmit, pristine, submitting, invalid } = this.props;
        return (
            <Grid>
 <div className="site-page-header-ghost-wrapper">
    <PageHeader
      ghost={false}
      avatar={{ src: '/inc/img/witmeg.png' }}
      title=""
      href=""
      subTitle="All-In-One Dashboard"
      extra={[
        <Button key="3" onClick={this.handleSelectApp.bind(null, 'login')}>Login</Button>,
        <Button key="2" onClick={this.handleSelectApp.bind(null, 'register')}>Register</Button>,

      ]}
    >

    </PageHeader>
  </div>

                {this.state.isValid &&
                    <Grid className="all-wrapper menu-side with-pattern">
                        <Grid className="auth-box-w wider">
                            <Grid className={classes.topContainer}>
                                <CheckIcon className={classes.topIcon} />
                                <Typography>
                                    Verified successfully<br />
                                for <strong>{this.state.applicationName}</strong><br />
                                One more step to complete your profile
                                </Typography>
                            </Grid>
                            <Tabs value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered>
                                <Tab label={<small>User Info</small>} icon={<VerifiedUserIcon />} />
                                <Tab label={<small>Company Info</small>} icon={<BusinessIcon />} />
                            </Tabs>

                            <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className={classes.form}>
                                <SwipeableViews
                                    className={classes.tabPanel}
                                    index={this.state.value}
                                    onChangeIndex={this.handleChangeIndex}>
                                    <TabPanel value={this.state.value} index={0}>
                                        <Field
                                            name="Password"
                                            type="hidden"
                                            id="txtPassword"
                                            component={renderHidden}
                                        />
                                        <Field
                                            name="ApplicationID"
                                            type="hidden"
                                            id="txtApplicationID"
                                            component={renderHidden}
                                        />
                                        <Field
                                            name="AppLicenseID"
                                            type="hidden"
                                            id="txtAppLicenseID"
                                            component={renderHidden}
                                        />
                                        <Row>
                                            <Col col="sm-12">
                                                <Field
                                                    name="FirstName"
                                                    readonly="readonly"
                                                    type="text"
                                                    required="required"
                                                    id="txtFirstName"
                                                    label="First Name"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12">
                                                <Field
                                                    name="LastName"
                                                    readonly="readonly"
                                                    type="text"
                                                    required="required"
                                                    id="txtLastName"
                                                    label="Last Name"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12">
                                                <Field
                                                    name="Email"
                                                    readonly="readonly"
                                                    type="email"
                                                    required="required"
                                                    id="txtEmail"
                                                    label="Email Address"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12">
                                                <Button className="w-100"
                                                    variant="contained"
                                                    color="primary"
                                                    mt={2} target="1"
                                                    type="submit"
                                                    onClick={this.handleNextButton.bind(null, 1)}>
                                                    Continue
                                            </Button>
                                            </Col>
                                        </Row>

                                    </TabPanel>

                                    <TabPanel value={this.state.value} index={1}>
                                        <Field
                                            name="Addresses[0].AddressType"
                                            type="hidden"
                                            required="required"
                                            id="txtAddressType"
                                            component={renderHidden}
                                        />
                                        <Row>
                                            <Col col="sm-12">
                                                <Field
                                                    name="Name"
                                                    type="text"
                                                    required="required"
                                                    id="txtOrgName"
                                                    label="Organization Name"
                                                    smalltext="Enter the name of the organization"
                                                    component={renderTextBox}
                                                    onChange={(e, value) => {
                                                        this.onChangeGenerateLocationCode({
                                                            companyName: value, // Since company name will be same as Organization Name.
                                                        });
                                                    }}
                                                />
                                            </Col>


                                            <Col col="sm-6">
                                                <Field
                                                    name="FirstSiteName"
                                                    type="text"
                                                    required="required"
                                                    id="txtLocation"
                                                    label="Location"
                                                    smalltext="Enter the name of the Location"
                                                    component={renderTextBox}
                                                    onChange={(e, value) => {
                                                        this.onChangeGenerateLocationCode({
                                                            locationName: value,
                                                        });
                                                    }}
                                                />
                                            </Col>

                                            <Col col="sm-6">
                                                <Field
                                                    name="LocationCode"
                                                    type="text"
                                                    required="required"
                                                    id="txtLocationCode"
                                                    label="Location Code"
                                                    smalltext="Enter the Location Code"
                                                    component={renderTextBox}
                                                />
                                            </Col>

                                            <Col col="sm-12">
                                                <Field
                                                    name="VATNumber"
                                                    type="text"
                                                    id="txtVATNumber"
                                                    label="VAT Number"
                                                    smalltext="Enter the VAT Number"
                                                    component={renderTextBox}
                                                />
                                            </Col>



                                            <Col col="sm-12"><Typography component="p" varient="h4">Address Details</Typography></Col>
                                            <Col col="sm-12 md-6">
                                                <Field
                                                    name="Addresses[0].AddressLine1"
                                                    type="text"
                                                    required="required"
                                                    id="txtAddressLine1"
                                                    label="Line 1"
                                                    smalltext="Enter Address Line 1"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                            <Col col="sm-12 md-6">
                                                <Field
                                                    name="Addresses[0].AddressLine2"
                                                    type="text"
                                                    id="txtAddressLine2"
                                                    label="Line 2"
                                                    smalltext="Enter address line 2"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12 md-6">
                                                <Field
                                                    name="Addresses[0].City"
                                                    type="text"
                                                    id="txtCity"
                                                    label="City"
                                                    smalltext="Enter City"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                            <Col col="sm-12 md-6">
                                                <Field
                                                    name="Addresses[0].StateProvinceRegion"
                                                    type="text"
                                                    required="required"
                                                    id="txtStateProvinceRegion"
                                                    label="State/Province/Region"
                                                    smalltext="Enter State/Province/Region"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col col="sm-12 md-6">
                                                <Field
                                                    name="Addresses[0].PostZipCode"
                                                    type="text"
                                                    required="required"
                                                    id="txtPostZipCode"
                                                    label="PostCode"
                                                    smalltext="Enter Postcode"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                            <Col col="sm-12 md-6">
                                            <label for="txtPostZipCode">Country</label>
                                                <Field className="form-control"
                                                    name="Addresses[0].Country"
                                                    required="required"
                                                    id="txtCountry"
                                                    label="Country"
                                                    smalltext="Select Country"
                                                    component="select">
                                                    <option />
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Åland Islands">Åland Islands</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antarctica">Antarctica</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guernsey">Guernsey</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jersey">Jersey</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macao">Macao</option>
                                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russian Federation">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Helena">Saint Helena</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Timor-leste">Timor-leste</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Viet Nam">Viet Nam</option>
                                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </Field>
                                                <small xs="12" md="6" class="form-text text-muted">Enter Country</small>


                                            </Col>
                                        </Row>
                                        <Row>

                                        <Col col="sm-12">
                                                <Field
                                                    name="Addresses[0].Email"
                                                    type="email"
                                                    required="required"
                                                    id="txtAEmail"
                                                    label="Email"
                                                    smalltext="Enter Email"
                                                    component={renderTextBox}
                                                />
                                            </Col>

                                            <Col col="sm-12">
                                                <Field
                                                    name="Addresses[0].Phone"
                                                    type="tel"
                                                    required="required"
                                                    id="txtPhone"
                                                    label="Phone Number"
                                                    smalltext="Enter Phone"
                                                    component={renderTextBox}
                                                />
                                            </Col>

                                            <Col col="sm-12">
                                                <Field
                                                    name="Addresses[0].Fax"
                                                    type="tel"
                                                    id="txtFax"
                                                    label="Fax Number"
                                                    smalltext="Enter Fax"
                                                    component={renderTextBox}
                                                />
                                            </Col>
                                            <Button disabled={submitting || pristine || invalid || !this.state.saveButtonActive}
                                                variant="contained"
                                                color="primary"
                                                type="submit" className="w-100">
                                                {this.state.saveButtonState}
                                            </Button>
                                        </Row>
                                    </TabPanel>

                                </SwipeableViews>

                            </Form>
                        </Grid>
                    </Grid>
                }
            </Grid >
        );
    }
}

const mapStateToProps = (state) => ({
	formValues: getFormValues(formName)(state), // Getting This form's ReduxForm values.
});

const mapDispatchToProps = (dispatch) => ({});

const Verify__WithStyleHOC = withStyles(styles)(Verify);

const Verify__WithRedux = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Verify__WithStyleHOC);

// Finally HOCing with ReduxForm.
export default reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    form: formName,
    validate: formFieldsValidator
})(Verify__WithRedux);
