import apiEndpointPaths from '../../lib/api/apiEndpointPaths';
import { APIError, errorLogger } from '../../lib/utilities/errorHandlers';

import axiosEmailRegistryServerInstance from '../../lib/api/axiosInstances/axiosEmailRegistryServerInstance';

export async function sendEmail(reqBody = {}, options = {}) {
	try {
		const {
			ToName,
			To,
			FromName = 'WitMeg',
			From = 'admin@witmeg.com',
			Subject,
			PlainTextContent,
			HTMLContent,
			Channel = 'Email',
			ServiceOrigin = 'Org Registration',
			EmailType = 'Standard',
			TemplateName = '',
			MailResponseCode = '0',
			MessageReceivedAt = new Date(),
		} = reqBody;

		const apiReqBody = {
			ToName,
			To,
			FromName,
			From,
			Subject,
			PlainTextContent,
			HTMLContent,
			Channel,
			ServiceOrigin,
			EmailType,
			TemplateName,
			MailResponseCode,
			MessageReceivedAt,
		};

		const resObj = await axiosEmailRegistryServerInstance({
			url: apiEndpointPaths.emailRegistry.sendEmail,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
