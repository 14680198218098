import {
	Column,
	Container,
	Email,
	Header,
	Link,
	PostonentsProvider,
	Row,
	Text,
} from 'postonents';
import React from 'react';

export default function CreatedOrgUserWelcomeEmail({ data }) {
	const {
		companyPageTitle = 'WITMEG',
		fullName,
		username,
		password,
		loginURL,
		orgAdminEmail,
		orgAdminName,
	} = data;

	return (
		<PostonentsProvider
			theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
			<Email
				headLinks={[
					{
						type: 'link',
						props: {
							rel: 'stylesheet',
							href:
								'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
						},
					},
				]}>
				<Container
					alignment='center'
					style={{ backgroundColor: '#FFFFFF', padding: '20px' }}>
					<Row>
						<Column small={12}>
							<Header
								style={{
									backgroundColor: '#FFFFFF',
									padding: 5,
								}}
								logo='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
								logoHeight={100}
							/>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>Hello {fullName},</Text>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>
								A WitMeg account for your email is created by&nbsp;
								{orgAdminName} ({orgAdminEmail}). We're excited
								to welcome you to the our product. Please use
								below credentials to log into your account. (Get
								Password from Organization Admin)
							</Text>
						</Column>

						<Column small={12}>
							<Text>
								<ul>
									<li>Username: {username}</li>
									<li>Password: XXXXXXXXXX</li>
								</ul>
							</Text>
						</Column>

						<Column small={12}>
							<Link href={loginURL} fullWidth type='hollow'>
								Login Link
							</Link>
						</Column>
					</Row>

					<Row>
						<Column small={12} style={{ marginBottom: 24 }}>
							<Text>
								Thanks,
								<br />
								{companyPageTitle}
							</Text>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>
								Any other questions? We are happy to help!
							</Text>
						</Column>

						<Column small={6}>
							<Link
								href='https://www.witmeg.com/contact-us'
								fullWidth
								type='hollow'>
								Help Center
							</Link>
						</Column>

						<Column small={6}>
							<Link
								href='mailto:info@ecologital.com'
								fullWidth
								type='hollow'>
								Email
							</Link>
						</Column>
					</Row>

					<Row>
						<Column
							small={12}
							style={{
								textAlign: 'center',
								fontSize: 12,
								lineHeight: '16px',
								fontWeight: 300,
							}}>
							Copyright © {' 2019-'} {new Date().getFullYear()}{' '}
							{companyPageTitle}, all rights reserved
						</Column>
					</Row>
				</Container>
			</Email>
		</PostonentsProvider>
	);
}
