import { validate } from "./../.property";
import Address from "./../Address";

class PostOrganization {
    constructor(params = null) {
        if (params == null) {
            this.Name = "";
            this.UserID = "";
            this.Password = "";
            this.ApplicationID = "";
            this.AppLicenseID = "";
            this.FirstSiteName = "";
            this.VATNumber = "";
            this.Addresses = new Address()[0];
        } else this.set(params);
    }

    set(params) {
        this.Password = validate(params, "Password", "");
        this.Name = validate(params, "Name", "");
        this.UserID = validate(params, "UserID", "");
        this.FirstSiteName =  validate(params, "FirstSiteName", "");
        this.VATNumber = validate(params, "VATNumber", "");
        this.ApplicationID = validate(params, "ApplicationID", "");
        this.AppLicenseID = validate(params, "AppLicenseID", "");
        this.Addresses = validate(params, "Addresses", [], "Address");
    }
}

export default PostOrganization;