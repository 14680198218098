/* eslint-disable no-param-reassign */
import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

const standardReducers = {
	// Determine which tab of 'Organize Management' section will be shown.
	setOrgManagementSectionCurrentTabId: (state, action) => {
		state.currentOrgManagementPageTabId = action.payload;
	},
};

export default standardReducers;
