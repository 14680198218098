import {
	Column,
	Container,
	Email,
	Header,
	Link,
	PostonentsProvider,
	Row,
	Text,
} from 'postonents';
import React from 'react';

export default function PasswordResetLinkEmail({ data }) {
	const { companyPageTitle = 'WITMEG', userEmail, resetLink } = data;

	return (
		<PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
			<Email
				headLinks={[
					{
						type: 'link',
						props: {
							rel: 'stylesheet',
							href:
								'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
						},
					},
				]}>
				<Container
					alignment='center'
					style={{ backgroundColor: '#FFFFFF', padding: '20px' }}>
					<Row>
						<Column small={12}>
							<Header
								style={{
									backgroundColor: '#FFFFFF',
									padding: 5,
								}}
								logo='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
								logoHeight={100}
							/>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>Hi,</Text>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>
								You recently requested to reset your password for your WitMeg
								Account. ({userEmail}) Please click below button to reset it.
								This password reset is only valid for short period.
							</Text>
						</Column>

						<Column small={12}>
							<Link href={resetLink} fullWidth type='hollow'>
								RESET MY PASSWORD
							</Link>
						</Column>

						<Column small={12}>
							<Text>
								If you did not request a password reset, Please ignore this
								email.
							</Text>
						</Column>
					</Row>

					<Row>
						<Column small={12} style={{ marginBottom: 24 }}>
							<Text>
								Thanks,
								<br />
								{companyPageTitle}
							</Text>
						</Column>
					</Row>

					<Row>
						<Column small={12}>
							<Text>Any other questions? We are happy to help!</Text>
						</Column>

						<Column small={6}>
							<Link
								href='https://www.witmeg.com/contact-us'
								fullWidth
								type='hollow'>
								Help Center
							</Link>
						</Column>

						<Column small={6}>
							<Link href='mailto:info@ecologital.com' fullWidth type='hollow'>
								Email
							</Link>
						</Column>
					</Row>

					<Row>
						<Column
							small={12}
							style={{
								textAlign: 'center',
								fontSize: 12,
								lineHeight: '16px',
								fontWeight: 300,
							}}>
							Copyright © {' 2019-'} {new Date().getFullYear()}{' '}
							{companyPageTitle}, all rights reserved
						</Column>
					</Row>
				</Container>
			</Email>
		</PostonentsProvider>
	);
}
