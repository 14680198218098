import Chance from 'chance';
import { Permutation } from 'js-combinatorics';

const chance = new Chance();

// Helper Utility function that used in multiple components to generate AutomaticLocationCode for given CompanyName and LocationName.
export function generateLocationCode(data = {}) {
	let {
		companyName = '',
		locationName = '',
		existingLocationCodes = [],
	} = data;

	// Tracking LocationCode Parts,
	let locationCode = '';
	let comLocCodePart = '';
	let locLocCodePart = '';

	// Helper constants to make rules creation easy.
	const locName = locationName.trim();
	const comName = companyName.trim();
	const comNameLength = comName.length;
	const comNameAsWordArray = comName.split(' ');
	const comNameNoOfWords = comNameAsWordArray.length;

	// If CompanyName OR LocationName NOT provided or empty we just return empty string.
	if (!comName || !locationName) {
		return '';
	}

	// **************** Helper Functions (That used internally in this function) ****************
	function getLocationCode() {
		const fullLocCode = comLocCodePart + locLocCodePart;
		return fullLocCode.toUpperCase();
	}

	function checkIsUniqueLocationCode() {
		const currentLocationCode = getLocationCode();

		const isLocationCodeAlreadyExist = existingLocationCodes.some(
			(existingLocCode) =>
				existingLocCode.toUpperCase() ===
				currentLocationCode.toUpperCase(),
		);

		if (isLocationCodeAlreadyExist) {
			return false;
		} else {
			return true;
		}
	}

	// **************** GenRuleFunctions - These determine how "LocationCode" is generated for specific cases ****************
	function genRule1() {
		// If company has one word, Taking two characters from the Company name and first character from the location name.
		if (comNameNoOfWords === 1 && comName.length >= 2) {
			comLocCodePart = comName.slice(0, 2);
			locLocCodePart = locName.slice(0, 1);

			return checkIsUniqueLocationCode();
		} else {
			return false;
		}
	}

	function genRule2() {
		// If the company name has multiple words, Taking the first character from each of the first two words to make up the two letters and first character from the location name.
		if (comNameNoOfWords >= 2) {
			comLocCodePart = comNameAsWordArray
				.map((word) => word.slice(0, 1))
				.join('')
				.slice(0, 2);
			locLocCodePart = locName.slice(0, 1);

			return checkIsUniqueLocationCode();
		} else {
			return false;
		}
	}

	function genRule3() {
		// Taking the first two characters of the of the first word, and first character from the location name.
		if (comNameNoOfWords >= 1 && comNameLength >= 2) {
			comLocCodePart = comNameAsWordArray[0].slice(0, 2);
			locLocCodePart = locName.slice(0, 1);

			return checkIsUniqueLocationCode();
		} else {
			return false;
		}
	}

	function genRule4() {
		// Take the first and last character of the first word and first character from the location name.
		if (comNameNoOfWords >= 1 && comNameLength >= 2) {
			comLocCodePart =
				comNameAsWordArray[0].slice(0, 1) +
				comNameAsWordArray[0].slice(-1);
			locLocCodePart = locName.slice(0, 1);

			return checkIsUniqueLocationCode();
		} else {
			return false;
		}
	}

	function genRule5() {
		// When above specific cases couldn't create unique LocationCode, We create the LocationCode by taking any two character of company name and first character of the location name.
		if (comNameLength >= 2) {
			const comLocCodePartPossibilities = new Permutation(comName, 2);

			// Looping through all of possibilities.
			for (const comPart of comLocCodePartPossibilities) {
				comLocCodePart = comPart.join('');
				locLocCodePart = locName.slice(0, 1);

				const isUnique = checkIsUniqueLocationCode();

				if (isUnique) {
					break;
				}
			}

			return checkIsUniqueLocationCode();
		} else {
			return false;
		}
	}

	function genRule6() {
		// When none of the above rule couldn't create unique LocationCode, We generate random unique LocationCode as fallback.

		// Looping through until unique one found.
		while (!checkIsUniqueLocationCode()) {
			comLocCodePart = chance.string({
				length: 2,
				casing: 'upper',
				alpha: true,
				numeric: false,
			});

			locLocCodePart = chance.string({
				length: 1,
				casing: 'upper',
				alpha: true,
				numeric: false,
			});
		}

		return checkIsUniqueLocationCode();
	}

	const generationRuleFns = [
		genRule1,
		genRule2,
		genRule3,
		genRule4,
		genRule5,
		genRule6,
	];

	// Looping through the RulesFns until unique code is generated.
	for (const ruleFn of generationRuleFns) {
		const isUnique = ruleFn();

		if (isUnique) {
			break;
		}
	}

	// Came to here mean Unique Location Code is generated by one of rules. So we will return it.
	locationCode = getLocationCode();
	return locationCode;
}
