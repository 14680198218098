export const Config = {
    HostedPath: isDev() ? process.env.REACT_APP_HostedPath : process.env.REACT_APP_HostedPath,
    OrgRegistryApi: isDev() ? process.env.REACT_APP_OrgRegistryApi : process.env.REACT_APP_OrgRegistryApi,
    AppRegistryApi: isDev() ? process.env.REACT_APP_AppRegistryApi : process.env.REACT_APP_AppRegistryApi,
    EmailRegistryApi: isDev() ? process.env.REACT_APP_EmailRegistryApi : process.env.REACT_APP_EmailRegistryApi,


    CryptKey: process.env.REACT_APP_CryptKey,

    /******Org JWT variables *******/
    OrgjwtPrivate: process.env.REACT_APP_OrgjwtPrivate.replace(/\\n/g, '\n'),
    OrgjwtAud: process.env.REACT_APP_OrgjwtAud,
    OrgjwtExp: process.env.REACT_APP_OrgjwtExp,
    OrgjwtIss: process.env.REACT_APP_OrgjwtIss,

    /******App JWT variables *******/
    AppjwtPrivate: process.env.REACT_APP_AppjwtPrivate.replace(/\\n/g, '\n'),
    AppjwtAud: process.env.REACT_APP_AppjwtAud,
    AppjwtExp: process.env.REACT_APP_AppjwtExp,
    AppjwtIss: process.env.REACT_APP_AppjwtIss,

    /******Email JWT variables *******/
    EmailjwtPrivate: process.env.REACT_APP_EmailjwtPrivate.replace(/\\n/g, '\n'),
    EmailjwtAud: process.env.REACT_APP_EmailjwtAud,
    EmailjwtExp: process.env.REACT_APP_EmailjwtExp,
    EmailjwtIss: process.env.REACT_APP_EmailjwtIss,
}

export default function isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}
