import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Cookies from 'js-cookie';

const styles = (theme) => ({});
class Logout extends Component {
    constructor(props) {
        super(props);
    }
componentDidMount (){
    sessionStorage.setItem('appName', '');
    sessionStorage.setItem('appid', '');
    sessionStorage.setItem('loggedin', '');
    sessionStorage.setItem('userID', '');
    Cookies.remove("infinity");
    Cookies.remove("embose");
    Cookies.remove("AppToken");
     


    window.location.href="/login/auth"
}
    render() {
        const { classes } = this.props;
        return (
            <div>Please wait</div>
        );
    }
}

export default withStyles(styles)(Logout);