import {
	combineReducers,
	configureStore,
	createImmutableStateInvariantMiddleware,
	getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { reducer as reduxFormReducer } from 'redux-form';
import { createLogger } from 'redux-logger';
import isDev from '../global/Config';
import { sectionName as dashboardSectionName } from './slices/dashboard/dashboardConstants';
import dashboardSliceReducer, {
	name as dashboardSliceReducerName,
} from './slices/dashboard/dashboardSlice/dashboardSlice';
import manageUsersSectionSliceReducer, {
	name as manageUsersSectionSliceReducerName,
} from './slices/dashboard/manageUsersSectionSlice/manageUsersSectionSlice';
import orgManagementSectionSliceReducer, {
	name as orgManagementSectionSliceReducerName,
} from './slices/dashboard/orgManagementSectionSlice/orgManagementSectionSlice';
import userSliceReducer, {
	name as userSliceReducerName,
} from './slices/userSlice/userSlice';

/**
 * NOTE : We are using "redux-toolkit" helpers to setup and use Redux in our React App.
 */

const isDevelopment = isDev();

const reduxLogger = createLogger({
	collapsed: true,
});

// NOTE : We are manually creating instance of 'immutableStateInvariant', Because previously available code seems to mutate some of state. (Ex. reduxForm 'form' path states)
//        Until those mutations resolved, in here we are temporally ignoring those paths to avoid runtime errors.
const customImmutableInvariantMiddleware = createImmutableStateInvariantMiddleware(
	{
		ignoredPaths: ['form'],
	},
);

const middleware = [
	...getDefaultMiddleware({
		//  PreConfigured to return "thunk, immutableStateInvariant, serializableStateInvariant"
		immutableCheck: false, // Temporally disabling this because we are passing custom "customImmutableInvariantMiddleware".
	}),
	customImmutableInvariantMiddleware,
];

if (isDevelopment) {
	middleware.push(reduxLogger); // This must be the last middleware in chain.
}

//  NOTE : This store is pre configured with ReduxDevTools.
export const reduxStore = configureStore({
	reducer: combineReducers({
		[dashboardSectionName]: combineReducers({
			[dashboardSliceReducerName]: dashboardSliceReducer,
			[orgManagementSectionSliceReducerName]: orgManagementSectionSliceReducer,
			[manageUsersSectionSliceReducerName]: manageUsersSectionSliceReducer,
		}),
		[userSliceReducerName]: userSliceReducer,
		form: reduxFormReducer, // Specifically used by 'redux-form'
	}),
	middleware: [...middleware],
	devTools: !!isDevelopment,
});

export default reduxStore;
