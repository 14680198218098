import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'orgManagementSection';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* setOrgManagementSectionCurrentTabId() *******************************
		currentOrgManagementPageTabId: '1',
	},
};

export default sliceConstants;
