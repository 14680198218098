import { reqStatusTypes } from '../../../helpers/constants';
import { sectionName } from '../dashboardConstants';

const sliceName = 'dashboard';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* getUserOrgDetailsListWithExtendedData() *******************************
		getUserOrgDetailsListWithExtendedDataReqStatus: reqStatusTypes.idle,
		getUserOrgDetailsListWithExtendedDataReqError: '',
		userOrgDetailsList: [],

		// ******************************* getUserOrgDetailsListWithExtendedData()  && setCurrentlySelectedOrgIndex() *******************************
		currentlySelectedOrgIndex: 0, // This track which index of above 'userOrgDetailsList' key, currently selected.

		// ******************************* setCurrentDashboardPageId() *******************************
		currentDashboardPageId: 'DashboardHome', // Setting HomePage as default.
	},
};

export default sliceConstants;
