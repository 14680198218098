import { validate } from "./.property";
import Address from "./Address";

class User {
    constructor(params = null) {
        if (params == null) {
            this.UserID = "";
            this.FirstName = "";
            this.LastName = "";
            this.UserName = "";
            this.Password = "";
            this.Email = "";
            this.Avatar = "";
            this.RegisteredDate = new Date();
            this.LastModifiedDate = new Date();
            this.IsActive = true;
            this.IsVerified = false;
            this.Addresses = [];
        } else this.set(params);
    }

    set(params) {
        this.UserID = validate(params, "UserID", "");
        this.FirstName = validate(params, "FirstName", "");
        this.LastName = validate(params, "LastName", "");
        this.UserName = validate(params, "UserName", "");
        this.Password = validate(params, "Password", "");
        this.Email = validate(params, "Email", "");
        this.Avatar = validate(params, "Avatar", "");
        this.RegisteredDate = validate(params, "RegisteredDate", new Date());
        this.LastModifiedDate = validate(params, "LastModifiedDate", new Date());
        this.IsActive = validate(params, "IsActive", true);
        this.IsVerified = validate(params, "IsVerified", false);
        this.Addresses = validate(params, "Addresses", [], "Address");
    }
}

export default User;