/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppstoreAddOutlined } from '@ant-design/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button, Collapse, Form, Modal, Result, Spin, Tag } from 'antd';
import React from 'react';
import {
	getCompaniesOfOrgByOrganizationId,
	getLocationsOfOrgByOrganizationId,
} from '../../../../../../data/api/orgRegistryServerRequests';
import AddCompanyForm from '../../forms/AddCompanyForm/AddCompanyForm';
import AddLocationForm from '../../forms/AddLocationForm/AddLocationForm';

const { Panel } = Collapse;

export default class ManageCompanies extends React.Component {
	constructor(props) {
		super(props);
		const currentOrganizationId = sessionStorage.getItem('orgID');

		this.state = {
			isCompanyAndLocationDataFetching: false,
			isCompanyAndLocationDataFetchingError: '',
			isAddCompanyModalVisible: false,
			isAddLocationModalVisible: false,

			currentOrgCompanies: [],
			clickedItemDetails: {}, // This is get updated when some specific company action is clicked. For ex. when clicked on 'Add New Location' in specific company that company details will be set here. So we can pass it into model and so on.
			currentOrgFullLocationCodeList: [],

			currentOrganizationId: currentOrganizationId,
		};
	}

	// Helper utility to parse and mix location details with it's appropriate company.
	parseCurrentOrgCompaniesWithLocationDetails(
		currentOrgCompanies,
		currentOrgLocations,
	) {
		const currentOrgLocationsByCompany = currentOrgCompanies.reduce(
			(acc, currentCom) => {
				const { remainingLocationList } = acc;

				// Getting all locations that belong to this iteration's company.
				const currentComLocations = remainingLocationList.filter((loc) => {
					return loc.CompanyID === currentCom.CompanyID;
				});

				// Removing already extracted above locations so next iterations will have less items to go through.
				const remainingLocations = remainingLocationList.filter((loc) => {
					return loc.CompanyID !== currentCom.CompanyID;
				});

				// Setting up current iteration's extracted/formatted data into certain pattern.
				const result = {
					CompanyDetails: currentCom,
					LocationDetails: currentComLocations,
				};

				acc.remainingLocationList = remainingLocations;
				acc.finalResult.push(result);

				return acc;
			},
			{ remainingLocationList: [...currentOrgLocations], finalResult: [] },
		).finalResult;

		return currentOrgLocationsByCompany;
	}

	async getCompanyAndLocationDetails() {
		try {
			const { currentOrganizationId } = this.state;

			this.setState({
				isCompanyAndLocationDataFetching: true,
				isCompanyAndLocationDataFetchingError: '',
			});

			// Getting Companies of Currently Selected Organization on dashboard-top.
			const currentOrgCompanyList = await getCompaniesOfOrgByOrganizationId({
				OrganizationID: currentOrganizationId,
			});

			// Getting Locations of Currently Selected Organization on dashboard-top.
			const currentOrgLocationList = await getLocationsOfOrgByOrganizationId({
				OrganizationID: currentOrganizationId,
			});

			// Adding location details.to relevant Company Data and outputting in specific pattern.
			const currentOrgLocationsByCompany = this.parseCurrentOrgCompaniesWithLocationDetails(
				currentOrgCompanyList,
				currentOrgLocationList,
			);

			// Extracting All "LocationCode" used in current organization. This is used when <AddNewLocation> LocationCode generation functionality..
			const currentOrgFullLocationCodeList = currentOrgLocationList.map(
				(loc) => loc.LocationCode,
			);

			this.setState({
				currentOrgCompanies: currentOrgLocationsByCompany,
				currentOrgFullLocationCodeList: [
					...new Set(currentOrgFullLocationCodeList),
				],
				isCompanyAndLocationDataFetching: false,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			this.setState({
				isCompanyAndLocationDataFetching: false,
				isCompanyAndLocationDataFetchingError: errMsg,
			});
		}
	}

	showLocationModal = (CompanyID) => {
		sessionStorage.setItem('CompanyID', CompanyID);
		this.setState({ isAddLocationModalVisible: true });
	};

	async componentDidMount() {
		this.getCompanyAndLocationDetails();
	}

	render() {
		const {
			isAddCompanyModalVisible,
			isAddLocationModalVisible,
			isCompanyAndLocationDataFetching,
			isCompanyAndLocationDataFetchingError,

			currentOrgCompanies,
			currentOrgFullLocationCodeList,
			clickedItemDetails,
		} = this.state;

		const { orgManagementSectionSliceActions } = this.props;

		const {
			setOrgManagementSectionCurrentTabId: setOrgManagementSectionCurrentTabIdAction,
		} = orgManagementSectionSliceActions;

		const isAnyMainActionsRunning = isCompanyAndLocationDataFetching;
		const isAnyMainActionsError = isCompanyAndLocationDataFetchingError;

		if (isAnyMainActionsError) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.getCompanyAndLocationDetails()}>
								Try Again
							</Button>,
						]}
					/>
				</div>
			);
		}

		return (
			<div className='ManageCompanies GC-UTL-fullFlexHeight'>
				<Modal
					title='Add New Company'
					width={800}
					footer={null}
					visible={isAddCompanyModalVisible}
					onCancel={() => {
						this.setState({ isAddCompanyModalVisible: false });
					}}>
					<AddCompanyForm
						shouldResetForm={!isAddCompanyModalVisible} // Used to clear form values when modal closing.
						shouldPrefillForm={false} // Used to pre-fill Form with existing values, if applicable.
						formMode={'ADD'}
						existingLocationCodesInOrg={currentOrgFullLocationCodeList}
						onDone={async () => {
							this.setState({ isAddCompanyModalVisible: false });

							// After adding a company we are re-fetching company details to show updated details on UI.
							await this.getCompanyAndLocationDetails();
						}}
					/>
				</Modal>

				<Modal
					title='Add New Location'
					width={800}
					footer={null}
					visible={isAddLocationModalVisible}
					onCancel={() => this.setState({ isAddLocationModalVisible: false })}>
					<AddLocationForm
						shouldResetForm={!isAddLocationModalVisible} // Used to clear form values when modal closing.
						shouldPrefillForm={false} // Used to pre-fill Form with existing values, if applicable.
						formMode={'ADD'}
						companyData={clickedItemDetails}
						existingLocationCodesInOrg={currentOrgFullLocationCodeList}
						onDone={async () => {
							this.setState({ isAddLocationModalVisible: false });

							// After adding a location we are re-fetching company details to show updated details on UI.
							await this.getCompanyAndLocationDetails();
						}}
					/>
				</Modal>

				<Button
					type='primary'
					icon={<AppstoreAddOutlined style={{ fontSize: '20px' }} />}
					onClick={() =>
						this.setState({
							isAddCompanyModalVisible: true,
						})
					}
					style={{ maxWidth: '200px', marginLeft: 'auto' }}>
					&nbsp; Add New Company
				</Button>

				<br />

				{isAnyMainActionsRunning && (
					<div className='GC-UTL-flexSuperCenter'>
						<Spin />
					</div>
				)}

				{!isAnyMainActionsRunning && (
					<Collapse defaultActiveKey={['0']}>
						{currentOrgCompanies.map((company, index) => (
							<Panel header={company.CompanyDetails.Name} key={index}>
								<Form
									name='register'
									scrollToFirstError
									className='SCC-customizedAntdForm'>
									<Form.Item label='Company Name'>
										{company.CompanyDetails.Name}
									</Form.Item>

									<Form.Item label='VAT Number'>
										{company.CompanyDetails.VATNumber}
									</Form.Item>

									<Form.Item label='Locations'>
										<div>
											<div style={{ margin: '10px 0' }}>
												{company.LocationDetails.length > 0
													? company.LocationDetails.sort((a, b) =>
															a.Name.localeCompare(b.Name),
													  ).map((location, locIndex) => (
															<Tag
																key={locIndex}
																color='geekblue'
																title={location.LocationCode}
																style={{ margin: '3px', cursor: 'pointer' }}
																onClick={() => {
																	setOrgManagementSectionCurrentTabIdAction(
																		'4',
																	);
																}}>
																{location.Name}
															</Tag>
													  ))
													: 'Sorry, No Location Available.'}
											</div>

											<div className='additem'>
												<a
													style={{ whiteSpace: 'nowrap' }}
													onClick={() => {
														this.setState({
															clickedItemDetails: company,
														});

														this.showLocationModal(
															company.CompanyDetails.CompanyID,
														);
													}}>
													<AddCircleOutlineIcon
														color='primary'
														style={{ color: '#1976d2' }}
													/>
													&nbsp; Add New Location
												</a>
											</div>
										</div>
									</Form.Item>

									<Form.Item label='Addresses'>
										{company.CompanyDetails.Addresses ? (
											<Collapse ghost>
												{company.CompanyDetails.Addresses.map(
													(address, index) => (
														<Panel
															header={
																address.AddressType || `Address ${index + 1}`
															}
															key={index}>
															<Form.Item label='Address Line 1'>
																{address.AddressLine1}
															</Form.Item>
															<Form.Item label='Address Line 2'>
																{address.AddressLine2}
															</Form.Item>
															<Form.Item label='City'>{address.City}</Form.Item>
															<Form.Item label='Post/Zip Code'>
																{address.PostZipCode}
															</Form.Item>
															<Form.Item label='Country'>
																{address.Country}
															</Form.Item>
															<Form.Item label='Email'>
																{address.Email}
															</Form.Item>
															<Form.Item label='Phone'>
																{address.Phone}
															</Form.Item>
															<Form.Item label='Fax'>{address.Fax}</Form.Item>
														</Panel>
													),
												)}
											</Collapse>
										) : (
											'No Address Available'
										)}
									</Form.Item>
								</Form>
							</Panel>
						))}
					</Collapse>
				)}
			</div>
		);
	}
}
