import React, { useRef, useCallback, useState } from "react";
import PrettySlider from "./PrettySlider";
import { makeStyles } from '@material-ui/core/styles';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { useEffect } from "react";
import "./style.css";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ReplayIcon from '@material-ui/icons/Replay';
import RefreshIcon from '@material-ui/icons/Refresh';
import BarChartIcon from '@material-ui/icons/BarChart';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import IconFullscreen from '@material-ui/icons/Fullscreen';
import Menu from '@material-ui/core/Menu';
import Switch from '@material-ui/core/Switch';
import { FullScreen, useFullScreenHandle } from "react-full-screen";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        width: 500,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const firstOlympicsYear = 1;
const lastOlympicsYear = 1000;
const timelineInterval = 8000; //ms
async function login(username, password) {
    const rawResponse = await fetch("http://localhost:5000/user/login", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ username: username, password: password })
    });
    const content = await rawResponse.json();

    return content.bearerToken;
}
const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-production-platform-ggsxi", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
    getUserToken: async function () {
        return await login('admin', 'password');
    }
});

const barChart = sdk.createChart({
    chartId: "6b6ecd5d-1805-4501-bafd-b4dda3953082", // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
});

const geoChart = sdk.createChart({
    chartId: "acfe61f2-c22c-43cb-87a8-1f6b361ff2c0", // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InvoiceReport() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [state, setState] = React.useState({
        checkedA: false
      });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleThemeChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setPageTheme(event.target.checked)
      };
    const refBarChart = useRef(null);
    const refGeoChart = useRef(null);
    const [year, setYear] = useState(lastOlympicsYear);
    const [playing, setPlaying] = useState(false);

    const yearRef = React.useRef(year);
    yearRef.current = year;

    const timerIdRef = React.useRef();
    const replayRef = React.useRef(false);

    const renderBarChart = useCallback(async (ref) => {
        try {
            await barChart.render(ref);
        } catch (e) {
            console.error(e);
        }
    }, []);

    const renderGeoChart = useCallback(async (ref) => {
        try {
            await geoChart.render(ref);
        } catch (e) {
            console.error(e);
        }
    }, []);

    const handleBannerChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 'refresh') {
            setAnchorEl(event.currentTarget);
            setPlaying(false);
        } else if (newValue == 'autorun') {
            actionTimeline();
        } else if (newValue == 'fullscreen') {
            handle.enter();  
        } else if (newValue == 'reload') {   
            window.location="/report/invoice/"+sessionStorage.getItem('appid')
        } else if (newValue == 'barchart') {
            handleClickOpen();
            setPlaying(false);
        }else{
            setPlaying(false); 
            handle.exit();  
        }
    };
    const handleRefreshClose = (_event) => {
        gethandleInterval(_event.target.value);
        
    };


    const setRefBarChart = useCallback(
        (ref) => {
            if (ref) {
                renderBarChart(ref);
            }
            // Save a reference to the node
            refBarChart.current = ref;
        },
        [renderBarChart]
    );

    const setRefGeoChart = useCallback(
        (ref) => {
            if (ref) {
                renderGeoChart(ref);
            }
            // Save a reference to the node
            refGeoChart.current = ref;
        },
        [renderGeoChart]
    );
    const handleRefreshClick = (event) => {
        setAnchorEl(event.currentTarget);
        handleRefreshClose(event)
    };
    const handleChange = (_event, newValue) => {
        getDataFromAllPreviousYears(newValue);
        setYear(newValue);
    };
    const handleChangeStatus = (_event) => {
        getProProformaSales(_event.target.value);
    };
    const handleInterval = (_event) => {
        gethandleInterval(_event.target.value);
        setAnchorEl(null);
    };
    const handleChangeCust = (_event) => {
        getcustName(_event.target.value);
    };

       const gethandleInterval = (refreshIntervalVal) => {
            let refreshInterval = Number(refreshIntervalVal);

        return Promise.all([
            geoChart.setRefreshInterval(refreshInterval),
            barChart.setRefreshInterval(refreshInterval),
        ]);
    };
    const getcustName = (cusname) => {
         let filter = {
            $and: [{
                "CollProformaSalesCustomers.Customer.Name": { '$regex': cusname, $options: '-i' }
            },{
            $and: [
                { "ProformaSales.InvoiceStatus": document.getElementById("status-select").nextElementSibling.value            },
                { "ProformaSales.OrgID": "5f32109b761a5a4312aa9706" },
            ]
        }
    ]
    };
   // console.log(filter)
        return Promise.all([
            geoChart.setFilter(filter),
            barChart.setFilter(filter),
        ]);
    };

    const setPageTheme = (status) => {
    if (status) {
        geoChart.setTheme("dark");
        barChart.setTheme("dark");
      } else {
        geoChart.setTheme("light");
        barChart.setTheme("light");
      }
    };

    const getDataFromAllPreviousYears = (endYear) => {
          let filter = {
            $and: [
                { "ProformaSales.SubTotal": { $gte: firstOlympicsYear } },
                { "ProformaSales.SubTotal": { $lte: endYear } },
                { "ProformaSales.OrgID": "5f32109b761a5a4312aa9706" },
            ],
        };

        return Promise.all([
            geoChart.setFilter(filter),
            barChart.setFilter(filter),
        ]);
    };

    const getProProformaSales = (InvoiceStatus) => {

        let filter = {
                $and: [{
                    "CollProformaSalesCustomers.Customer.Name": { '$regex': document.getElementById("cust-name").value, $options: '-i' }
                },{
                $and: [
                    { "ProformaSales.InvoiceStatus": InvoiceStatus },
                    { "ProformaSales.OrgID": "5f32109b761a5a4312aa9706" },
                ]
            }
        ]
        };


        return Promise.all([
            geoChart.setFilter(filter),
            barChart.setFilter(filter)
        ]);
    };

    const play = () => {
        let currentYear = yearRef.current + 99;

        if (currentYear > lastOlympicsYear) {
            if (replayRef.current) {
                currentYear = firstOlympicsYear;
                replayRef.current = false;
            } else {
                clearInterval(timerIdRef.current);
                setPlaying(false);
                return;
            }
        }
        getDataFromAllPreviousYears(currentYear);
        setYear(currentYear);
    };

    const setTimelineInterval = () => {
        if (playing) {
            play();
            timerIdRef.current = setInterval(play, timelineInterval);
        } else {
            clearInterval(timerIdRef.current);
        }
    };

    const actionTimeline = () => {
        if (yearRef.current === lastOlympicsYear) {
            replayRef.current = true;
        }

        setPlaying(!playing);
    };
    const refreshInterval = () => {
        if (yearRef.current === lastOlympicsYear) {
            replayRef.current = true;
        }

        setPlaying(!playing);
    };
    useEffect(() => {
        setTimelineInterval();
    }, [playing]);

    const buttonClass = `action-button ${
        playing ? "pause-button" : "play-button"
        }`;

        const handle = useFullScreenHandle();

    return (
        <>
        
      <FullScreen handle={handle}>
          <div style={{background: "#fafafa"}}>
        
            <header className="header">
                <h1>Sales Report</h1>

            </header>

            <div className="actions" >


                <BottomNavigation value={value} onChange={handleBannerChange} className={classes.root}>
                    <BottomNavigationAction label="Refresh" value="refresh" icon={<RefreshIcon />} />
                    <BottomNavigationAction label="Auto Run" value="autorun" icon={<DirectionsRunIcon />} />
                    <BottomNavigationAction label="Bar Chart" value="barchart" icon={<BarChartIcon />} />
                    <BottomNavigationAction label="Reload Page" value="reload" icon={<ReplayIcon />} />
                    <BottomNavigationAction label="Full Screen" value="fullscreen" icon={<IconFullscreen />} />
                </BottomNavigation>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={setAnchorEl}
                >
                    <MenuItem value="10" onClick={handleInterval}>10 Seconds</MenuItem>
                    <MenuItem value="60" onClick={handleInterval}>1 Minute</MenuItem>
                    <MenuItem value="900" onClick={handleInterval}>15 Minutes</MenuItem>
                    <MenuItem value="3600" onClick={handleInterval}>1 Hour</MenuItem>
                    
                </Menu>
               
                <Box textAlign="right" m={1}>
                    <Switch
        checked={state.checkedA}
        textAlign="center"
        onChange={handleThemeChange}
        color="primary"
        name="checkedA"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      </Box>
              <div className="slider">
                
                    
                    <Grid container spacing={3}>
                        
                        <Grid item xs={6}>
                            Invoice Status
        </Grid>
                        <Grid item xs={6}>
                            <FormControl  >

                                <Select

                                    valueLabelDisplay="on"
                                    labelId="status-label"
                                    id="status-select"
                                    min={firstOlympicsYear}
                                    max={lastOlympicsYear}
                                    onChangeCommitted={handleChangeStatus}
                                    onChange={handleChangeStatus}
                                    defaultValue="0"
                                >
                                    <MenuItem value="0">-- Select --</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                    <MenuItem value="NotCompleted">Not Completed</MenuItem>
                                    <MenuItem value="Cancel">Cancel</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            Customer Name
        </Grid>
                        <Grid item xs={6}>
                            <FormControl  >

                                <TextField id="cust-name" onChange={handleChangeCust} />

                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            Total Invoice Value
        </Grid>
                        <Grid item xs={6}>
                            <PrettySlider
                                valueLabelDisplay="on"
                                aria-label="pretto slider"
                                min={firstOlympicsYear}
                                max={lastOlympicsYear}
                                step={4}
                                onChangeCommitted={handleChange}
                                onChange={handleChange}
                                value={year}
                                defaultValue={lastOlympicsYear}
                            />
                        </Grid>
                    </Grid>


                </div>

            </div>
           
            <div className="charts">

                <div id="barChart" ref={setRefBarChart} >Please wait</div>
                <div id="geoChart" ref={setRefGeoChart}>Please wait</div> 
                <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Bar Chart
            </Typography>

                        </Toolbar>
                    </AppBar>
                   
                </Dialog>
            </div>
            </div>
            </FullScreen>
        </>
    );
}

