import { Skeleton, Button } from 'antd';
import React, { Component } from 'react';

export default class UsersList extends Component {
	render() {
		const { loaded, users, manageUsersSectionSliceActions } = this.props;
		const {
			setManageUsersSectionCurrentTabId,
		} = manageUsersSectionSliceActions;

		return (
			<div>
				<table width='100%'>
					<tbody>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Is Organization Admin</th>
							<th>Manage</th>
						</tr>

						{loaded ? (
							users.map((e, i) => (
								<tr key={i}>
									<td>{e.UserDetails.FirstName}</td>
									<td>{e.UserDetails.LastName}</td>
									<td>{e.UserDetails.Email}</td>
									<td>{e.IsOrganizationAdmin ? 'Yes' : 'No'}</td>
									<td>
										<Button
											onClick={() => setManageUsersSectionCurrentTabId('2')}>
											Assign Apps
										</Button>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={5}>
									<Skeleton />
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}
