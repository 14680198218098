/* eslint-disable jsx-a11y/anchor-is-valid */
import { HomeOutlined } from '@ant-design/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Breadcrumb, Button, Col, Modal, PageHeader, Row, Tabs } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { sectionName as dashboardSectionName } from '../../../../lib/redux/slices/dashboard/dashboardConstants';
import { actions as dashboardSliceActions } from '../../../../lib/redux/slices/dashboard/dashboardSlice/dashboardSlice';
import AddOrganizationForm from './forms/AddOrganizationForm/AddOrganizationForm';
import './OrgManagementSection.css';
import ManageCompanies from './subSections/ManageCompanies/ManageCompanies';
import ManageLocations from './subSections/ManageLocations/ManageLocations';
import OrganizationApps from './subSections/OrganizationApps/OrganizationApps';
import OrganizationDetails from './subSections/OrganizationDetails/OrganizationDetails';

const { TabPane } = Tabs;

class ManageOrganizationSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isAddOrganizationModalVisible: false,
		};
	}

	changeOrgManagementCurrentTab = (tabKey) => {
		const { orgManagementSectionSliceActions } = this.props;
		const {
			setOrgManagementSectionCurrentTabId: setOrgManagementSectionCurrentTabIdAction,
		} = orgManagementSectionSliceActions;

		setOrgManagementSectionCurrentTabIdAction(tabKey);
	};

	render() {
		const { isAddOrganizationModalVisible } = this.state;

		const {
			dashboardSliceActions,
			orgManagementSectionSliceState,
			orgManagementSectionSliceActions,
		} = this.props;
		const {
			getUserOrgDetailsListWithExtendedData: getUserOrgDetailsListWithExtendedDataAction,
		} = dashboardSliceActions;
		const { currentOrgManagementPageTabId } = orgManagementSectionSliceState;

		return (
			<div className='ManageOrganizationSection GC-UTL-fullFlexHeight'>
				<Breadcrumb>
					<Breadcrumb.Item href=''>
						<HomeOutlined />
					</Breadcrumb.Item>
					<Breadcrumb.Item>Organization Management</Breadcrumb.Item>
				</Breadcrumb>

				<Modal
					title='Add New Organization'
					width={800}
					footer={null}
					visible={isAddOrganizationModalVisible}
					onCancel={() => {
						this.setState({ isAddOrganizationModalVisible: false });
					}}>
					<AddOrganizationForm
						shouldResetForm={!isAddOrganizationModalVisible} // Used to clear form values when modal closing.
						onDone={async () => {
							this.setState({
								isAddOrganizationModalVisible: false,
							});

							// This redux action update org details on redux root level. So newly added organization will be updated on UI.
							getUserOrgDetailsListWithExtendedDataAction();
						}}
					/>
				</Modal>

				<div className='ManageOrganizationSection__sectionHeader'>
					<Row align='middle'>
						<Col sm={24} md={18}>
							<PageHeader
								className='site-page-header'
								title='Organization Management'
								subTitle='Manage Your Organization Here.'
							/>
						</Col>

						<Col sm={24} md={6}>
							<div
								style={{
									color: '#1976d2',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									cursor: 'pointer',
									width: '100%',
									marginBottom: '10px',
								}}
								onClick={() => {
									this.setState({
										isAddOrganizationModalVisible: true,
									});
								}}>
								<Button
									type='primary'
									icon={<AddCircleOutlineIcon />}
									style={{ background: '#264653', borderColor: '#264653' }}>
									&nbsp;Add New Organization
								</Button>
							</div>
						</Col>
					</Row>
				</div>

				<div className='ManageOrganizationSection__tabSet'>
					<Row>
						<Col sm={24}>
							<Tabs
								// NOTE : As a temporary solution, Currently 'destroyInactiveTabPane' is used because some tabs has functionality that add/remove things from backend and Some of those
								//        related details are shown in multiple tabs. So to always show updated details, as a temporary solution we destroy tabs
								// 				not currently active, which effectively forcing re-mounting/re-fetching on tab select.
								destroyInactiveTabPane={true}
								activeKey={currentOrgManagementPageTabId}
								onChange={this.changeOrgManagementCurrentTab}>
								<TabPane tab='Organization Details' key='1'>
									<OrganizationDetails
										dashboardSliceActions={dashboardSliceActions}
									/>
								</TabPane>

								<TabPane tab='Organization Apps' key='2'>
									<OrganizationApps
										orgManagementSectionSliceActions={
											orgManagementSectionSliceActions
										}
									/>
								</TabPane>

								<TabPane tab='Manage Companies' key='3'>
									<ManageCompanies
										orgManagementSectionSliceActions={
											orgManagementSectionSliceActions
										}
									/>
								</TabPane>

								<TabPane tab='Manage Locations' key='4'>
									<ManageLocations />
								</TabPane>
							</Tabs>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dashboardSliceState: state[dashboardSectionName].dashboard,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundDashboardSliceActions = bindActionCreators(
		dashboardSliceActions,
		dispatch,
	);

	return {
		dashboardSliceActions: boundDashboardSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ManageOrganizationSection);
