import { SelectOutlined as SelectOutlinedIcon } from '@ant-design/icons';
import {
	Button,
	Card,
	Col,
	Descriptions,
	Row,
	Space,
	Statistic,
	Tag,
} from 'antd';
import React, { Component } from 'react';

export default class OrganizationSummary extends Component {
	// Go to Organization Management Section of Dashboard.
	gotoOrganizationManagementSection(orgManagementTab = '1') {
		const {
			dashboardSliceActions,
			orgManagementSectionSliceActions,
		} = this.props;

		const {
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;
		const {
			setOrgManagementSectionCurrentTabId: setOrgManagementSectionCurrentTabIdAction,
		} = orgManagementSectionSliceActions;

		setCurrentDashboardPageIdAction('OrganizationManagement'); // Moving to 'Organize Management' Section.
		setOrgManagementSectionCurrentTabIdAction(orgManagementTab); // Selecting specified tab of Organization Management section.
	}

	render() {
		const { currentOrgBasicDetails, currentOrgCompanyList } = this.props;

		return (
			<div className='OrganizationSummary'>
				<Card
					title='Organization Summary'
					bordered={false}
					actions={[
						<div>
							{' '}
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									this.gotoOrganizationManagementSection('1'); // Selecting "First" tab (Organization Details) of that section.
								}}>
								Organization Management
							</Button>
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									this.gotoOrganizationManagementSection('2'); // Selecting "Second" tab (Organization Apps) of that section.
								}}>
								Organization Apps
							</Button>
						</div>,
					]}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							<Space>
								<Statistic
									title='Organization Name'
									value={currentOrgBasicDetails.Name}
								/>
							</Space>
						</Col>

						<Col span={24}>
							<Space>
								<Statistic
									title='Active Companies'
									value={currentOrgCompanyList.length}
								/>
							</Space>
						</Col>

						<Col span={24}>
							<Descriptions column={1} size='small' layout='vertical' colon=''>
								<Descriptions.Item label='Companies'>
									{currentOrgCompanyList.map((company) => {
										return (
											<Tag
												style={{ marginBottom: '5px', cursor: 'pointer' }}
												color='geekblue'
												onClick={() => {
													this.gotoOrganizationManagementSection('3'); // Selecting "Third tab (Manage Companies) of that section.
												}}>
												{company.Name}
											</Tag>
										);
									})}
								</Descriptions.Item>
							</Descriptions>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}
