import React from 'react';
import { Config } from './../../../../lib/global/Config'
import { Email, Container, Row, Column, Header, FullWidth, Footer, Text, Link, PostonentsProvider } from 'postonents';

const VerificationEmail = ({ data }) => {
    const {  email, companyPageTitle='WITMEG', fullName } = data;

    return (
        <PostonentsProvider theme={{ typo: { fontFamily: 'Roboto, sans-serif' } }}>
            <Email
                title={`Verification email for ${email}`}
                headLinks={[
                    {
                        type: 'link',
                        props: {
                            rel: 'stylesheet',
                            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                        },
                    },
                ]}>

                <Container alignment="center" style={{ backgroundColor: '#FFFFFF', padding: "20px" }}>
                    <Row>
                        <Column small={12}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: 5 }}
                                logo="https://cdn.neurolage.com/ecologital/witmeg_logo.png"
                                logoHeight={100}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Hello {fullName},</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>We're excited to welcome to the {companyPageTitle} community. </Text>
                        </Column>
                    </Row>
                    <Row>
                        <Column small={12} style={{ marginBottom: 24 }}>
                            <Text>Thanks,<br/>{companyPageTitle}</Text>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12}>
                            <Text>Any other questions? We are happy to help!</Text>
                        </Column>
                        <Column small={6}>
                            <Link href="https://www.witmeg.com/contact-us" fullWidth type="hollow">Help Center</Link>
                        </Column>
                        <Column small={6}>
                            <Link href="mailto:info@ecologital.com" fullWidth type="hollow">Email</Link>
                        </Column>
                    </Row>

                    <Row>
                        <Column small={12} style={{ textAlign: 'center', fontSize: 12, lineHeight: '16px', fontWeight: 300 }}>
                            Copyright ©  {" 2019-"} {new Date().getFullYear()} {companyPageTitle}, all rights reserved
                            </Column>
                    </Row>
                </Container>
            </Email>
        </PostonentsProvider>
    );
};

export default VerificationEmail;
