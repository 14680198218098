import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import reduxStore from "./lib/redux/reduxStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import './styles/globalClasses/allGlobalClasses.css'
import './styles/specialItemsCustomizingClasses/index.css'

const themeObject = {
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    type: "light",
    primary: {
      main: '#0044ff',
    }
  },
};

const themeConfig = createMuiTheme(themeObject);

ReactDOM.render(
  <MuiThemeProvider theme={themeConfig}>
    <CssBaseline />
    <Provider store={reduxStore}>
      <Router>
        <App />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
