import { Avatar, Collapse, Form } from 'antd';
import { DateTime } from 'luxon';
import React from 'react';

const { Panel } = Collapse;

export default class AccountDetailsDisplay extends React.Component {
	// Generate what should be displayed in Avatar Circle. (In case currently received data don't have Avatar value.)
	generateAvatarValue(data = {}) {
		const { userDetails = {} } = this.props;
		let { FirstName = '', LastName = '' } = data;

		FirstName = userDetails.FirstName || FirstName;
		LastName = userDetails.LastName || LastName;

		return String(FirstName[0] + LastName[0]).toUpperCase();
	}

	render() {
		const { userDetails = {} } = this.props;

		return (
			<div className='AccountDetailsDisplay'>
				<Form
					name='register'
					scrollToFirstError
					className='SCC-customizedAntdForm'>
					<Form.Item label='Avatar'>
						<Avatar style={{ fontWeight: 'bold', backgroundColor: '#383e56' }}>
							{this.generateAvatarValue()}
						</Avatar>
					</Form.Item>

					<Form.Item label='Email'>{userDetails.Email}</Form.Item>
					<Form.Item label='First Name'>{userDetails.FirstName}</Form.Item>
					<Form.Item label='Last Name'>{userDetails.LastName}</Form.Item>

					<Form.Item label='Addresses'>
						{userDetails.Addresses ? (
							<Collapse ghost>
								{userDetails.Addresses.map((address, index) => (
									<Panel header={address.AddressType} key={index}>
										<Form.Item label='Address Line 1'>
											{address.AddressLine1}
										</Form.Item>
										<Form.Item label='Address Line 2'>
											{address.AddressLine2}
										</Form.Item>
										<Form.Item label='City'>{address.City}</Form.Item>
										<Form.Item label='Post/Zip Code'>
											{address.PostZipCode}
										</Form.Item>
										<Form.Item label='Country'>{address.Country}</Form.Item>
										<Form.Item label='Email'>{address.Email}</Form.Item>
										<Form.Item label='Phon'>{address.Phone}</Form.Item>
										<Form.Item label='Fax'>{address.Fax}</Form.Item>
									</Panel>
								))}
							</Collapse>
						) : (
							''
						)}
					</Form.Item>

					<Form.Item name='lname' label='Account Created'>
						{DateTime.fromISO(userDetails.RegisteredDate).toLocaleString(
							DateTime.DATE_HUGE,
						)}
					</Form.Item>
				</Form>
			</div>
		);
	}
}
