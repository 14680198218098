import apiEndpointPaths from '../../lib/api/apiEndpointPaths';
import { APIError, errorLogger } from '../../lib/utilities/errorHandlers';

import axiosOrgRegistryServerInstance from '../../lib/api/axiosInstances/axiosOrgRegistryServerInstance';

export async function getUserDetailsByUserId(reqBody = {}, options = {}) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getUserDetailsByUserId}/${UserID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function checkUserAlreadyExist(reqBody = {}, options = {}) {
	try {
		const { Email } = reqBody;

		const apiReqBody = {
			Email,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.checkUserAlreadyExist,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (Status === true && Result === 'User Exist') {
			return true;
		} else if (Status === false && Result === 'mongo: no documents in result') {
			return false;
		} else {
			throw new APIError(Result);
		}
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getUserIdByEmail(reqBody = {}, options = {}) {
	try {
		const { Email } = reqBody;

		const apiReqBody = {
			Email,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.getUserIdByEmail,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Deserializing FindUser'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addUser(reqBody = {}, options = {}) {
	try {
		const {
			FirstName,
			LastName,
			UserName,
			Password,
			Email,
			Avatar,
			Addresses,
		} = reqBody;

		const apiReqBody = {
			FirstName,
			LastName,
			UserName,
			Password,
			Email,
			Avatar,
			Addresses,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addUser,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('email_1 dup key'): {
					customErrMsg = 'User already exist for this email.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateUserDetails(reqBody = {}, options = {}) {
	try {
		const {
			UserID,
			UserName,
			Password,
			FirstName,
			LastName,
			Email,
			Avatar,
			Addresses,
			IsActive,
			IsVerified,
		} = reqBody;

		const apiReqBody = {
			UserID,
			UserName,
			Password,
			FirstName,
			LastName,
			Email,
			Avatar,
			Addresses,
			IsActive,
			IsVerified,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.updateUserDetails}/${UserID}`,
			method: 'PUT',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateUserPassword(reqBody = {}, options = {}) {
	try {
		const { UserEmail, Password } = reqBody;

		const apiReqBody = {
			UserEmail,
			Password,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.updateUserPassword,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function loginUser(reqBody = {}, options = {}) {
	try {
		const { UserName, Password } = reqBody;

		const apiReqBody = {
			UserName,
			Password,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.loginUser,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// When success we get AccessToken and RefreshToken.
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Password does not match'): {
					customErrMsg = 'Invalid Password.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function generatePasswordResetLink(reqBody = {}, options = {}) {
	try {
		const { UserEmail } = reqBody;

		const apiReqBody = {
			UserEmail,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.generatePasswordResetLink,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// When success we get AccessToken and RefreshToken.
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Record does not exist'): {
					customErrMsg = "User don't exist for this email.";
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getUserOrgDetailsByUserId(reqBody = {}, options = {}) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getUserOrgDetailsByUserId}/${UserID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getOrganizationUserOrgDetailsByOrgId(
	reqBody = {},
	options = {},
) {
	try {
		const { OrgID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getOrganizationUserOrgDetailsByOrgId}/${OrgID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrgID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'OrgID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getFullUserOrgDetailsByUserId(
	reqBody = {},
	options = {},
) {
	try {
		const { UserID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getFullUserOrgDetailsByUserId}/${UserID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateUserOrgDetailsByUserRegId(
	reqBody = {},
	options = {},
) {
	try {
		const {
			UserRegistrationID,
			UserID,
			OrganisationID,
			Companies,
			UserRoleIDs,
			AppLicenses,
			IsOrganizationAdmin,
		} = reqBody;

		// NOTE: This API's ReqBody pattern should exactly match with "getUserOrgDetailsByUserId() - /registereduser/{UserRegistrationID}" pattern.
		//				This can use to update any of registred user details.
		//        Also note that its not UserId used in URl. Its UserRegistrationID.
		//        Also remember body must be full object with all details. Don't only put updating details. In that case all other keys will be deleted.
		const apiReqBody = {
			UserRegistrationID,
			UserID,
			OrganisationID,
			Companies,
			UserRoleIDs,
			AppLicenses,
			IsOrganizationAdmin,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.updateUserOrgDetailsByUserRegId}/${UserRegistrationID}`,
			method: 'PUT',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading UserRegistrations'): {
					customErrMsg = 'Invalid user data structure provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addUserOrgDetailsSet(reqBody = {}, options = {}) {
	try {
		const {
			OrganisationID,
			UserID,
			UserRoleIDs,
			Companies,
			AppLicenses,
		} = reqBody;

		const apiReqBody = {
			OrganisationID,
			UserID,
			UserRoleIDs,
			Companies,
			AppLicenses,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addUserOrgDetailsSet,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addUserRole(reqBody = {}, options = {}) {
	try {
		const {
			OrganisationID,
			Name = 'Standard',
			OrgAppID,
			PermissionSetnValue,
		} = reqBody;

		const apiReqBody = {
			OrganisationID,
			Name,
			OrgAppID,
			PermissionSetnValue,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addUserRole,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// This Result is "UserRoleID".
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getOrganizationByOrgId(reqBody = {}, options = {}) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getOrganizationByOrgId}/${OrganizationID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addOrganization(reqBody = {}, options = {}) {
	/* NOTE : This endpoint automatically create a Company and Location along with Organization.
								- Company : Name will be same as OrganizationName (Name Key)
								- Location : Name will be the value of 'FirstSiteName' Key.
	*/

	try {
		const {
			Name,
			UserID,
			ApplicationID,
			AppLicenseID,
			Addresses,
			VATNumber, // This is used in automatically created Company.
			FirstSiteName, // This is used as the Location name which is created automatically.
			LocationCode = '', // This will added to above automatically created Location .
		} = reqBody;

		const apiReqBody = {
			Name,
			UserID,
			ApplicationID,
			AppLicenseID,
			Addresses,
			VATNumber,
			FirstSiteName,
			LocationCode: LocationCode.toUpperCase(),
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addOrganization,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				case errMsg.includes(
					'duplicate key error collection: mytest.organisations index: name_1',
				): {
					customErrMsg = 'Organization with this name already exist.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateOrganizationDetails(reqBody = {}, options = {}) {
	try {
		const { OrganisationID, Name, Addresses } = reqBody;

		const apiReqBody = {
			OrganisationID,
			Name,
			Addresses,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.updateOrganizationDetails}/${OrganisationID}`,
			method: 'PUT',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getCompanyByCompanyId(reqBody = {}, options = {}) {
	try {
		const { CompanyID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getCompanyByCompanyId}/${CompanyID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid CompanyID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getCompaniesOfOrgByOrganizationId(
	reqBody = {},
	options = {},
) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getCompaniesOfOrgByOrganizationId}/${OrganizationID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addCompanyToOrganization(reqBody = {}, options = {}) {
	try {
		const {
			Name,
			OrganisationID,
			CreatedBy, // Created UserID.
			VATNumber,
			Logo,
			Addresses,
			LocationName,
			LocationCode = '',
		} = reqBody;

		const apiReqBody = {
			Name,
			OrganisationID,
			CreatedBy,
			VATNumber,
			Logo,
			Addresses,
			LocationName, // This automatically create Location for this company.
			LocationCode: LocationCode.toUpperCase(),
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addCompanyToOrganization,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// When success, Return created company's CompanyID.
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLocationByLocationId(reqBody = {}, options = {}) {
	try {
		const { LocationID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getLocationByLocationId}/${LocationID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLocationsOfComByCompanyId(reqBody = {}, options = {}) {
	try {
		const { CompanyID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getLocationsOfComByCompanyId}/${CompanyID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLocationsOfOrgByOrganizationId(
	reqBody = {},
	options = {},
) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getLocationsOfOrgByOrganizationId}/${OrganizationID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid OrganizationID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addLocationToCompany(reqBody = {}, options = {}) {
	try {
		const {
			Name,
			LocationCode = '',
			OrganisationID,
			Logo,
			CompanyID,
			InvoiceSequence,
			InvoicePrefix,
			IsNegativeStockEnabled,
			BinLocations,
			CreatedBy,
			ModifiedBy,
			Addresses,
		} = reqBody;

		const apiReqBody = {
			Name,
			LocationCode: LocationCode.toUpperCase(),
			OrganisationID,
			Logo,
			CompanyID,
			InvoiceSequence,
			InvoicePrefix,
			IsNegativeStockEnabled,
			BinLocations,
			CreatedBy,
			ModifiedBy,
			Addresses,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addLocationToCompany,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateLocationOfCompany(reqBody = {}, options = {}) {
	try {
		const {
			LocationID,
			CompanyID,
			OrganisationID,
			Name,
			LocationCode = '',
			Logo,
			Status,
			InvoiceSequence,
			InvoicePrefix,
			ProformaPrefix,
			IsNegativeStockEnabled,
			BinLocations,
			CreatedBy,
			ModifiedBy,
			Addresses,
			LoyaltyCustomerRegistrationURL,
			LoyaltySchemeSettingID,
		} = reqBody;

		const apiReqBody = {
			LocationID,
			CompanyID,
			OrganisationID,
			Name,
			LocationCode: LocationCode.toUpperCase(),
			Logo,
			Status,
			InvoiceSequence,
			InvoicePrefix,
			ProformaPrefix,
			IsNegativeStockEnabled,
			BinLocations,
			CreatedBy,
			ModifiedBy,
			Addresses,
			LoyaltyCustomerRegistrationURL,
			LoyaltySchemeSettingID,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.updateLocationOfCompany}/${LocationID}`,
			method: 'PUT',
			data: apiReqBody,
			...options,
		});

		const { Status: resStatus, Result } = resObj.data;

		if (!resStatus) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error reading'): {
					customErrMsg = 'Invalid Body Values.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getAppsOfOrgByOrganizationId(reqBody = {}, options = {}) {
	try {
		const { OrganizationID } = reqBody;

		const apiReqBody = {};

		const resObj = await axiosOrgRegistryServerInstance({
			url: `${apiEndpointPaths.orgRegistry.getAppsOfOrgByOrganizationId}/${OrganizationID}`,
			method: 'GET',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: invalid byte'):
				case errMsg.includes('the provided hex string is not a valid ObjectID'):
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addAppToOrganization(reqBody = {}, options = {}) {
	try {
		const {
			OrganizationID,
			InstalledUser,
			ApplicationID,
			CompanyID,
			LocationID,
		} = reqBody;

		const apiReqBody = {
			OrganisationID: OrganizationID,
			InstalledUser,
			ApplicationID,
			CompanyID,
			LocationID,
		};

		const resObj = await axiosOrgRegistryServerInstance({
			url: apiEndpointPaths.orgRegistry.addAppToOrganization,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
