/* eslint-disable no-template-curly-in-string */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { addUser } from '../../../../../data/api/orgRegistryServerRequests';
import { countryList } from '../../../../../lib/utilities/helperValues';

const formLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 24 },
};

const validateMessages = {
	required: '${label} is required.',
	types: {
		email: '${label} is not a valid email.',
	},
};

export default class AddNewUser extends Component {
	formRef = React.createRef();

	componentDidUpdate(prevProps) {
		// Resetting Form Values. (Used in situations like modal is closing.)
		if (prevProps.shouldResetForm !== this.props.shouldResetForm) {
			if (this.props.shouldResetForm) {
				this.formRef.current && this.formRef.current.resetFields();
			}
		}
	}

	async onAddUser(values) {
		const {
			onDoneFn = () => {}, // If parent component passes this, parent component can use this to do something after user being added.
		} = this.props;

		try {
			if (values.addresses === undefined) {
				Modal.error({
					title: 'Address Required',
					content: 'Please add at least one address for the user.',
					onOk: () => {},
				});
				return;
			}

			const reqBody = {
				FirstName: values.user.firstname,
				LastName: values.user.lastname,
				UserName: values.user.username,
				Password: values.user.password,
				Email: values.user.email,
				Avatar: '',
				Addresses: values.addresses,
			};

			const hideMsg = message.loading({ content: 'Adding User...' }, 0);

			await addUser(reqBody);

			hideMsg();
			onDoneFn({ Email: values.user.email, isSuccess: true, formValues:values.user });

			Modal.success({
				title: 'User Added',
				content: 'User details has been successfully added.',
				onOk() {},
			});
			this.setState({ isModalVisible: false });
		} catch (error) {
			const errMsg =
				error.customErrMsg ||
				'Sorry, Some error occurred while creating the User.';

			onDoneFn({
				Email: values.user.email,
				isSuccess: false,
				formValues: values.user
			});

			Modal.error({
				title: 'User Not Added',
				content: errMsg,
				onOk: () => {},
			});

			this.setState({ isModalVisible: false });
		}
	}

	render() {
		const { formsPreFillData = {} } = this.props;

		const formInitialValues = {
			user: {
				email: formsPreFillData.email || '',
			},
		};

		return (
			<div className='AddNewUserForm'>
				<Form
					{...formLayout}
					name='AddNewUser'
					validateMessages={validateMessages}
					initialValues={formInitialValues}
					ref={this.formRef}
					onFinish={(formValues) => this.onAddUser(formValues)}>
					<Form.Item
						name={['user', 'firstname']}
						label='First Name'
						rules={[{ required: true }]}>
						<Input />
					</Form.Item>

					<Form.Item
						name={['user', 'lastname']}
						label='Last Name'
						rules={[{ required: true }]}>
						<Input />
					</Form.Item>

					<Form.Item
						name={['user', 'username']}
						label='User Name'
						rules={[{ required: true }]}>
						<Input />
					</Form.Item>

					<Form.Item
						name={['user', 'password']}
						label='Password'
						rules={[
							{
								required: true,
							},
						]}
						hasFeedback>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name={['user', 'confirmpassword']}
						label='Confirm Password'
						dependencies={['user', 'password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'Please confirm your password.',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value ||
										getFieldValue(['user', 'password']) ===
											value
									) {
										return Promise.resolve();
									}
									return Promise.reject(
										'Passwords do not match.',
									);
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name={['user', 'email']}
						label='Email'
						rules={[
							{
								type: 'email',
								required: true,
							},
						]}>
						<Input />
					</Form.Item>

					<Form.List name='addresses'>
						{(fields, { add, remove }) => (
							<>
								{fields.map((field, index) => {
									return (
										<div key={field.key + index}>
											<div>
												<span>
													<b>
														Address {field.key + 1}
													</b>
												</span>

												<span
													className='float-right'
													onClick={() =>
														remove(field.name)
													}
													style={{
														cursor: 'pointer',
													}}>
													<MinusCircleOutlined />
													<span> Remove Address</span>
												</span>
											</div>

											<br />

											<Form.Item
												{...field}
												key='AddressType'
												label='Address Type'
												name={[
													field.name,
													'AddressType',
												]}
												fieldKey={[
													field.fieldKey,
													'AddressType',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='AddressLine1'
												label='Address Line 1'
												name={[
													field.name,
													'AddressLine1',
												]}
												fieldKey={[
													field.fieldKey,
													'AddressLine1',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='AddressLine2'
												label='Address Line 2'
												name={[
													field.name,
													'AddressLine2',
												]}
												fieldKey={[
													field.fieldKey,
													'AddressLine2',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='City'
												label='City'
												name={[field.name, 'City']}
												fieldKey={[
													field.fieldKey,
													'City',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='StateProvinceRegion'
												label='State/Province/Region'
												name={[
													field.name,
													'StateProvinceRegion',
												]}
												fieldKey={[
													field.fieldKey,
													'StateProvinceRegion',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='PostZipCode'
												label='PostCode'
												name={[
													field.name,
													'PostZipCode',
												]}
												fieldKey={[
													field.fieldKey,
													'PostZipCode',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Country'
												label='Country'
												name={[field.name, 'Country']}
												fieldKey={[
													field.fieldKey,
													'Country',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Select
													placeholder='Select a Country'
													allowClear>
													<Select.Option />
													{countryList.map(
														(country) => {
															return (
																<Select.Option
																	value={
																		country
																	}
																	key={
																		country
																	}>
																	{country}
																</Select.Option>
															);
														},
													)}
												</Select>
											</Form.Item>

											<Form.Item
												{...field}
												key='Email'
												label='Email'
												name={[field.name, 'Email']}
												fieldKey={[
													field.fieldKey,
													'Email',
												]}
												rules={[
													{
														type: 'email',
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Phone'
												label='Phone Number'
												name={[field.name, 'Phone']}
												fieldKey={[
													field.fieldKey,
													'Phone',
												]}
												rules={[
													{
														required: true,
													},
												]}>
												<Input />
											</Form.Item>

											<Form.Item
												{...field}
												key='Fax'
												label='Fax Number'
												name={[field.name, 'Fax']}
												fieldKey={[
													field.fieldKey,
													'Fax',
												]}>
												<Input />
											</Form.Item>
										</div>
									);
								})}

								<Form.Item>
									<Button
										type='dashed'
										onClick={() => add()}
										block
										icon={<PlusOutlined />}>
										Add Address
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>

					<Form.Item>
						<Button type='primary' htmlType='submit'>
							ADD USER
						</Button>
					</Form.Item>
				</Form>
			</div>
		);
	}
}
