import { HomeOutlined } from '@ant-design/icons';
import { Grid, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { Breadcrumb, Button, PageHeader, Result, Spin } from 'antd';
import React from 'react';
import { getAllAppTypeDetailsOnPlatform } from '../../../../data/api/appRegistryServerRequests';
import { getUserOrgDetailsByUserId } from '../../../../data/api/orgRegistryServerRequests';

export default class YourAppsSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isYourAppDetailsFetching: false,
			isYourAppDetailsFetchingError: '',

			currentOrgYourApps: [],
		};
	}

	async getCurrentOrgYourAppDetails() {
		try {
			this.setState({
				isYourAppDetailsFetching: true,
				isYourAppDetailsFetchingError: '',
			});

			const currentlyLoggedUserId = sessionStorage.getItem('userID');
			const currentOrganizationId = sessionStorage.getItem('orgID');

			// Get all UserOrgDetails available for this user.
			const allUserOrgDetails = await getUserOrgDetailsByUserId({
				UserID: currentlyLoggedUserId,
			});

			// Extracting only currently selected Organization's UserOrgDetails.
			const currentOrg__userOrgDetails =
				allUserOrgDetails.find((orgData) => {
					return orgData.OrganisationID === currentOrganizationId;
				}) || {};

			// Getting all App Licencees available (Could be purchased or assigned) for this user in current organization.
			const currentOrgAppLicenseIdList = currentOrg__userOrgDetails.AppLicenses;

			// Getting all app's details on platform and mapping it with current user app licencees. So we have more details about each licence.
			const allAppTypeDetailsOnPlatform = await getAllAppTypeDetailsOnPlatform();
			const currentOrgYourAppDetails = currentOrgAppLicenseIdList.map(
				(appLicence) => {
					return (
						allAppTypeDetailsOnPlatform.find(
							(appDetails) =>
								appDetails.AppLicenseID === appLicence.AppLicenseID,
						) || {}
					);
				},
			);

			const sortedCurrentOrgYourAppDetails = currentOrgYourAppDetails.sort(
				(a, b) => a.Name.localeCompare(b.Name),
			);

			this.setState({
				isYourAppDetailsFetching: false,
				currentOrgYourApps: sortedCurrentOrgYourAppDetails,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || 'Sorry. Some Error Occurred.';

			this.setState({
				isYourAppDetailsFetching: false,
				isYourAppDetailsFetchingError: errMsg,
			});
		}
	}

	componentDidMount() {
		this.getCurrentOrgYourAppDetails();
	}

	render() {
		const {
			isYourAppDetailsFetching,
			isYourAppDetailsFetchingError,
			currentOrgYourApps,
		} = this.state;

		const isAnyMainActionsRunning = isYourAppDetailsFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning && isYourAppDetailsFetchingError;

		return (
			<div className='YourAppsSection GC-UTL-fullFlexHeight'>
				<Breadcrumb>
					<Breadcrumb.Item href=''>
						<HomeOutlined />
					</Breadcrumb.Item>
					<Breadcrumb.Item>Your Apps</Breadcrumb.Item>
				</Breadcrumb>

				<PageHeader
					className='site-page-header'
					title='Your Apps'
					subTitle='See The List Of Apps You Already Purchased With Us.'
				/>

				{/* Used to indicate main actions like fetching in componentDidMount.  */}
				{isAnyMainActionsRunning && (
					<div className='GC-UTL-flexSuperCenter'>
						<Spin />
					</div>
				)}

				{/* When error occurred */}
				{isAnyMainActionsError && (
					<div className='GC-UTL-flexSuperCenter'>
						<Result
							status='error'
							subTitle={isAnyMainActionsError}
							extra={[
								<Button
									variant='contained'
									onClick={() => this.getCurrentOrgYourAppDetails()}>
									Try Again
								</Button>,
							]}
						/>
					</div>
				)}

				{/* Below are only displayed when all necessary details are done fetching and no errors occurred  */}
				{!isAnyMainActionsRunning && !isAnyMainActionsError && (
					<Grid container spacing={3}>
						{currentOrgYourApps.map((app, i) => (
							<Grid item xs={12} sm={6} lg={4} key={i}>
								<ListItem key={i}>
									<IconButton disabled>
										<img
											alt=''
											src={
												app.AppImage !== ''
													? app.AppImage
													: '/inc/img/notfound.png'
											}
										/>
									</IconButton>

									<ListItemText
										primary={app.Name}
										secondary={<small>{app.AboutApp}</small>}
									/>
								</ListItem>
							</Grid>
						))}
					</Grid>
				)}
			</div>
		);
	}
}
