import { EditFilled as EditFilledIcon } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Modal, Result, Row, Spin } from 'antd';
import React, { Component } from 'react';
import { getOrganizationByOrgId } from '../../../../../../data/api/orgRegistryServerRequests';
import EditOrganizationDetailsForm from '../../forms/EditOrganizationDetailsForm/EditOrganizationDetailsForm';

const { Panel } = Collapse;

export default class OrganizationDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			isCurrentlySelectedOrgDetailsFetching: false,
			isCurrentlySelectedOrgDetailsFetchingError: '',
			currentlySelectedOrgDetails: {},

			isEditOrganizationDetailsModalVisible: false,
		};
	}

	toggleEditOrganizationDetailsModal() {
		const { isEditOrganizationDetailsModalVisible } = this.state;

		this.setState({
			isEditOrganizationDetailsModalVisible: !isEditOrganizationDetailsModalVisible,
		});
	}

	async getCurrentlySelectedOrgDetails() {
		try {
			this.setState({
				isCurrentlySelectedOrgDetailsFetching: true,
				isCurrentlySelectedOrgDetailsFetchingError: '',
			});

			const currentlySelectedOrgId = sessionStorage.getItem('orgID');

			const orgDetails = await getOrganizationByOrgId({
				OrganizationID: currentlySelectedOrgId,
			});

			this.setState({
				isCurrentlySelectedOrgDetailsFetching: false,
				currentlySelectedOrgDetails: orgDetails,
			});
		} catch (error) {
			const errMsg =
				error.customErrMsg || 'Error Occurred While Fetching Org Details';

			this.setState({
				isCurrentlySelectedOrgDetailsFetching: false,
				isCurrentlySelectedOrgDetailsFetchingError: errMsg,
			});
		}
	}

	componentDidMount() {
		this.getCurrentlySelectedOrgDetails();
	}

	render() {
		const {
			isCurrentlySelectedOrgDetailsFetching,
			isCurrentlySelectedOrgDetailsFetchingError,
			currentlySelectedOrgDetails,
			isEditOrganizationDetailsModalVisible,
		} = this.state;

		const { dashboardSliceActions } = this.props;

		const {
			getUserOrgDetailsListWithExtendedData: getUserOrgDetailsListWithExtendedDataAction,
		} = dashboardSliceActions;

		const isAnyMainActionsRunning = isCurrentlySelectedOrgDetailsFetching;
		const isAnyMainActionsError =
			!isAnyMainActionsRunning && isCurrentlySelectedOrgDetailsFetchingError;

		if (isAnyMainActionsRunning) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Spin />
				</div>
			);
		}

		if (isAnyMainActionsError) {
			return (
				<div className='GC-UTL-flexSuperCenter'>
					<Result
						status='error'
						subTitle={isAnyMainActionsError}
						extra={[
							<Button
								variant='contained'
								onClick={() => this.getCurrentlySelectedOrgDetails()}>
								Try Again
							</Button>,
						]}
					/>
					<div></div>
				</div>
			);
		}

		return (
			<div className='OrganizationDetails'>
				<Modal
					title='Edit Organization Details'
					width={800}
					footer={null}
					visible={isEditOrganizationDetailsModalVisible}
					onCancel={() => {
						this.toggleEditOrganizationDetailsModal();
					}}>
					<EditOrganizationDetailsForm
						shouldResetForm={!isEditOrganizationDetailsModalVisible} // Used to clear form values when modal closing.
						onDoneFn={async (values = {}) => {
							const { isSuccess } = values;

							this.toggleEditOrganizationDetailsModal();

							if (isSuccess) {
								// This redux action update org details on redux root level. So newly added organization will be updated on UI.
								getUserOrgDetailsListWithExtendedDataAction();
							}
						}}
						currentlySelectedOrgDetails={currentlySelectedOrgDetails}
					/>
				</Modal>

				<div>
					<Row justify='end'>
						<Col>
							<Button
								type='primary'
								icon={<EditFilledIcon />}
								onClick={() => {
									this.setState({
										isEditOrganizationDetailsModalVisible: true,
									});
								}}>
								Edit Details
							</Button>
						</Col>
					</Row>

					<Form
						name='register'
						scrollToFirstError
						className='SCC-customizedAntdForm'>
						<Form.Item label='Organization Name'>
							{currentlySelectedOrgDetails.Name}
						</Form.Item>

						<Form.Item label='Addresses'>
							{currentlySelectedOrgDetails.Addresses ? (
								<Collapse ghost>
									{currentlySelectedOrgDetails.Addresses.map(
										(address, index) => (
											<Panel
												header={address.AddressType || `Address ${index + 1}`}
												key={index}>
												<Form.Item label='Address Line 1'>
													{address.AddressLine1}
												</Form.Item>
												<Form.Item label='Address Line 2'>
													{address.AddressLine2}
												</Form.Item>
												<Form.Item label='City'>{address.City}</Form.Item>
												<Form.Item label='Post/Zip Code'>
													{address.PostZipCode}
												</Form.Item>
												<Form.Item label='Country'>{address.Country}</Form.Item>
												<Form.Item label='Email'>{address.Email}</Form.Item>
												<Form.Item label='Phone'>{address.Phone}</Form.Item>
												<Form.Item label='Fax'>{address.Fax}</Form.Item>
											</Panel>
										),
									)}
								</Collapse>
							) : (
								''
							)}
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}
