/* eslint-disable no-param-reassign */
import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

const standardReducers = {
	// Determine which tab of 'Manage Users' section will be shown.
	setManageUsersSectionCurrentTabId: (state, action) => {
		state.currentManageUsersSectionTabId = action.payload;
	},
};

export default standardReducers;
