import { SelectOutlined as SelectOutlinedIcon } from '@ant-design/icons';
import { Button, Card, Col, Row, Statistic, Tag } from 'antd';
import React, { Component } from 'react';

export default class UsersSummary extends Component {
	// Go to "Manage Users" Section of Dashboard.
	gotoManageUsersSection(manageUsersTab = '1') {
		const {
			dashboardSliceActions,
			manageUsersSectionSliceActions,
		} = this.props;

		const {
			setCurrentDashboardPageId: setCurrentDashboardPageIdAction,
		} = dashboardSliceActions;
		const {
			setManageUsersSectionCurrentTabId: setManageUsersSectionCurrentTabIdAction,
		} = manageUsersSectionSliceActions;

		setCurrentDashboardPageIdAction('ManageUsers'); // Moving to 'Manage Users' Section.
		setManageUsersSectionCurrentTabIdAction(manageUsersTab); // Selecting specified tab of Manage Users section.
	}

	render() {
		const { allUsersOfCurrentOrgWithExtendedData } = this.props;

		return (
			<div className='UsersSummary'>
				<Card
					title='Users Summary'
					bordered={false}
					actions={[
						<div>
							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									this.gotoManageUsersSection('1');
								}}>
								Users List
							</Button>

							<Button
								icon={<SelectOutlinedIcon />}
								size='small'
								block
								onClick={() => {
									this.gotoManageUsersSection('2');
								}}>
								Assign Apps
							</Button>
						</div>,
					]}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							<Statistic
								title='Active Users'
								value={allUsersOfCurrentOrgWithExtendedData.length}
							/>
						</Col>

						<Col span={24}>
							<div style={{ color: 'gray', marginBottom: '5px' }}>
								Users List
							</div>

							<div>
								{allUsersOfCurrentOrgWithExtendedData.map((user) => {
									return (
										<Tag style={{ marginBottom: '5px' }} color='geekblue'>
											{user.___moreUserDetails.FirstName}{' '}
											{user.___moreUserDetails.LastName}
										</Tag>
									);
								})}
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		);
	}
}
