import isDev from '../global/Config';

const IS_DEVELOPMENT = isDev();

export function APIError(
	customErrMsg = 'Error Occurred. Please try again.',
	orgError = '',
	otherDetails = {},
) {
	const error = new Error(orgError || customErrMsg);

	error.customErrType = 'APIERROR';
	error.customErrMsg = customErrMsg;

	return error;
}

// Helper utility to log errors to console. (To make it easy to track errors.)
// Logs only shown on developer mode.
export function errorLogger(error, options = {}) {
	if (IS_DEVELOPMENT){
		console.log(new Error(error), options);
	}
}
