import React from "react";

 
 
export default class AddRemoveLayout extends React.PureComponent {


    constructor(props) {
        super(props);
    }
 
    render() {
        return (


            <div>

<h1>Welcome</h1>
            </div>
        );
    }
}