import Address from "./Address";

export function validate(object, name, defaultValue, type = undefined) {
    if (name in object) {
        if (Array.isArray(defaultValue) && type != undefined) {
            let array = [];
            object[name].map(function (e) {
                let obj = null;
                switch (type) {
                    case "Address":
                        obj = new Address(e);
                }
                if (obj != null)
                    array.push(obj);
            })
            return array;
        }
        return object[name];
    }
    else return defaultValue;
}